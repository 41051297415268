<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        You are sending a Job Briefing to {{ profile.name }} for consideration
      </span></template
    >
    <template v-slot:actions-right>
      <b-button
        variant="link"
        :to="
          isSkilledWorker ? '/employees/skilled-workers' : '/employees/search'
        "
        class="mr-1 orange-clr"
        >Back to search</b-button
      >
      <b-button
        v-if="job"
        variant="success"
        @click="handleNext"
        class="rounded-lg"
        >Next</b-button
      >
    </template>
    <job-form
      ref="jobForm"
      :job="job"
      :currentView="currentView"
      :loading="jobLoading"
      :cancelButtonTo="backUrl"
      :briefing="true"
      @set-current-view="setCurrentView"
      :jobSeeker="profile"
    />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import JobForm from "@/components/Job/JobForm"

export default {
  name: "SearchResultProfileJobBriefing",
  components: {
    InnerLayoutActionBarWrapper,
    JobForm,
  },
  data() {
    const profileId = this.$router.currentRoute.params?.profileId
    const isSkilledWorker =
      this.$router.currentRoute.path.includes("skilled-workers")
    return {
      profileId,
      profile: {},
      isSkilledWorker,
      briefing: true,
      backUrl: "/employees/search",
      jobLoading: false,
      currentView: "edit",
      job: null,
    }
  },
  methods: {
    async fetchProfile() {
      const profileId = this.$router.currentRoute.params?.profileId
      try {
        const { data } = await this.$store.dispatch("api/getProfile", {
          profileId,
        })
        this.profile = data
      } catch (error) {
        this.showErrorMessage(
          error,
          "We couldn't retrieve the data for this profile."
        )
      }
    },
    handleNext() {
      console.log("TODO: Handle button click")
    },
    setCurrentView(currentView) {
      this.currentView = currentView
    },
  },
  mounted() {
    this.fetchProfile()
  },
}
</script>

<style scoped></style>
