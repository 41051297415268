<template>
  <div class="row section-header-wrapper">
    <div class="col section-title">
      <h2>{{ title }}</h2>
    </div>
    <div class="col-auto" v-if="isEditing">
      <edit-button
        class="section-edit-button"
        @handle-click="handleEditClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEditClick() {
      this.$emit("handle-edit-click")
    },
  },
}
</script>

<style lang="scss" scoped>
.section-header-wrapper {
  margin-top: 56px;
}
.section-title {
  display: flex;
  padding: 8px 0px 8px 16px;
  align-items: center;
  border-bottom: 1px solid var(--Gray-300, #b0b9c9);
  background: var(--Gray-100, #eceef2);
  margin-bottom: 16px;
}
.section-title h2 {
  color: var(--Gray-900, #343946);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 0;
  margin: 0;
}
</style>
