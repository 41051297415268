<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Search to quickly locate and view profiles of material vendors for your
        current or upcoming projects
      </span></template
    >
    <b-row class="my-4">
      <b-col cols="3">
        <base-input placeholder="ZIP Code" type="number" v-model="filter.zip">
        </base-input>
      </b-col>
      <b-col cols="5">
        <multiselect
          v-model="filter.categories"
          placeholder="Search or add a category"
          :options="categoryOptions"
          :multiple="true"
          :taggable="true"
          :close-on-select="false"
        >
        </multiselect>
      </b-col>
      <b-col sm md="2">
        <DefaultButton :labelText="'Search'" @click="filterPartners" />
      </b-col>
      <b-col sm md="2">
        <DefaultButton :labelText="'+ Filters'" @click="extraFilters = true" />
      </b-col>
    </b-row>
    <b-row v-if="extraFilters" class="my-4">
      <b-col cols="3">
        <multiselect
          v-model="filter.industry"
          placeholder="Industry"
          :options="industryOptions"
          :multiple="true"
          :taggable="true"
          :close-on-select="false"
        >
        </multiselect>
      </b-col>
      <b-col cols="3">
        <multiselect
          v-model="filter.qualifiedDesignations"
          placeholder="Business Certification"
          :options="designationOptions"
          :multiple="true"
          :taggable="true"
          :close-on-select="false"
        >
        </multiselect>
      </b-col>
    </b-row>
    <div class="row" v-if="tradePartnerSearch.length">
      <div v-for="c in tradePartnerSearch" :key="c._id" class="col-lg-4">
        <company-card :company="c" />
      </div>
    </div>
    <div class="row" v-else-if="!dataLoaded && tradePartnerSearch.length == 0">
      <div
        v-if="loadingData"
        class="text-center align-items-center"
        style="margin: auto"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <!--h3 class="col-lg-4">No Results</h3-->
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
//import { filterProjects } from "@/api/projects"
import CompanyCard from "@/components/Cards/CompanyCard"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import options from "@/utils/options"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    CompanyCard,
    DefaultButton,
  },
  data() {
    return {
      filter: {
        zip: this.$store.state.userProfile.zip,
        categories: [],
        industry: [],
        qualifiedDesignations: [],
      },
      categoryOptions: options.categoryOptions,
      industryOptions: options.industryOptions,
      designationOptions: options.designationsOptions,
      tradePartnerSearch: [],
      loadingData: false,
      extraFilters: false,
    }
  },
  methods: {
    async filterPartners() {
      try {
        this.loadingData = true
        this.tradePartnerSearch = []
        const { zip, categories, industry, qualifiedDesignations } = {
          ...this.filter,
        }
        const list = await this.$store.dispatch("api/searchCompaniesByParams", {
          filters: {
            zip,
            radius: 50,
          },
          mongoFilters: {
            ...(categories.length
              ? { selfPerformWorkCategories: categories }
              : {}),
            ...(qualifiedDesignations.length
              ? { qualifiedDesignations: qualifiedDesignations }
              : {}),
            ...(industry.length ? { industry: industry } : {}),
            type: "provider",
            "provider.isVendor": true,
            _id: { $ne: this.$store.state.userProfile._id },
          },
        })
        console.log(list)
        this.tradePartnerSearch = list.data
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }

      // TODO: assign list to data key and then render
    },
  },
  mounted() {
    this.filterPartners()
  },
}
</script>

<style scoped></style>
