<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left>
      <span class="mr-1">
        Find opportunities for paid apprenticeships to build your skills and
        advance your career
      </span></template
    >
    <b-row class="my-4">
      <b-col sm md="3">
        <base-input placeholder="ZIP Code" type="number" v-model="filter.zip">
        </base-input>
      </b-col>
      <b-col sm md="3">
        <multiselect
          v-model="filter.categories"
          placeholder="Trade Categories"
          :options="categoryOptions"
          :multiple="true"
          :close-on-select="false"
          style="margin-bottom: 20px"
        >
        </multiselect>
      </b-col>
      <b-col sm md="3">
        <multiselect
          v-model="filter.positions"
          placeholder="Position Type"
          :options="positionOptions"
          :multiple="true"
          :close-on-select="false"
          style="margin-bottom: 20px"
        >
        </multiselect>
      </b-col>
      <b-col sm md="2">
        <DefaultButton :labelText="'Search'" @click="searchJobs" />
      </b-col>
    </b-row>
    <div class="mt-5">
      <div class="row">
        <div
          v-for="job in jobList"
          :key="job._id"
          @click="goToJob(job._id)"
          class="col-lg-4"
        >
          <job-card :job="job" />
        </div>
      </div>
      <div class="row" v-if="jobList.length == 0">
        <div
          v-if="loadingData"
          class="text-center align-items-center"
          style="margin: auto"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import JobCard from "@/components/JobCard"
import options from "@/utils/options"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    JobCard,
    DefaultButton,
  },
  data() {
    return {
      loading: false,
      categoryOptions: options.categoryOptions,
      positionOptions: options.positionOptions,
      filter: {
        categories: [],
        positions: [],
        zip: this.$store.state.userProfile.zip,
      },
      jobList: [],
      loadingData: false,
    }
  },
  methods: {
    async searchJobs() {
      this.loadingData = true
      this.jobList = []
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          const data = await this.$store.dispatch("api/searchJobsByParams", {
            zip: this.filter.zip,
            tradeCategories: this.filter.categories,
            positionTypes: this.filter.positions,
            willingToProvidePaidTraining: true,
            limit: 100,
            page: 1,
          })
          this.jobList = data.data.result
          console.log("DATA", data)
          this.loadingData = false
          //if (data?.completed) this.completed = data.completed
        } catch (error) {
          this.loadingData = false
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    goToJob(id) {
      //this.$router.push("/jobs/" + id)
      this.$router.push({ name: "jobDetail", params: { jobId: id } })
    },
  },
  mounted() {
    this.searchJobs()
  },
}
</script>

<style scoped lang="scss">
.search-btn .btn {
  border-radius: 14px;
  height: 45px;
  line-height: 45px;
  min-width: 200px;
  padding: 0;
}
</style>
<style>
.multiselect__content-wrapper {
  position: unset;
}
</style>
