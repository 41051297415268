<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="icon"
    role="presentation"
  >
    <title :id="icon" lang="en">{{ icon }} icon</title>
    <g :fill="color">
      <path
        d="M33.5469 39.6795C35.3475 39.2115 36.6875 37.5868 36.6875 35.6406C36.6875 33.3312 34.8094 31.4531 32.5 31.4531C31.3453 31.4531 30.4063 30.5141 30.4063 29.3594C30.4063 28.2047 31.3453 27.2656 32.5 27.2656C33.6547 27.2656 34.5938 28.2047 34.5938 29.3594H36.6875C36.6875 27.4132 35.3475 25.7885 33.5469 25.3205V23.0781H31.4531V25.3205C29.6525 25.7885 28.3125 27.4132 28.3125 29.3594C28.3125 31.6688 30.1906 33.5469 32.5 33.5469C33.6547 33.5469 34.5938 34.4859 34.5938 35.6406C34.5938 36.7953 33.6547 37.7344 32.5 37.7344C31.3453 37.7344 30.4063 36.7953 30.4063 35.6406H28.3125C28.3125 37.5868 29.6525 39.2115 31.4531 39.6795V41.9219H33.5469V39.6795Z"
      />
      <path
        d="M32.5 18.8906C24.996 18.8906 18.8906 24.996 18.8906 32.5H20.9844C20.9844 26.1507 26.1507 20.9844 32.5 20.9844V18.8906Z"
      />
      <path
        d="M44.0156 32.5C44.0156 38.8493 38.8493 44.0156 32.5 44.0156V46.1094C40.004 46.1094 46.1094 40.004 46.1094 32.5H44.0156Z"
      />
      <path
        d="M21.6133 7.54688C18.3313 8.98005 15.4011 11.0204 12.9043 13.6104L14.4108 15.0645C16.717 12.6734 19.4221 10.789 22.4518 9.46475L21.6133 7.54688Z"
      />
      <path
        d="M25.9687 6.07031C25.2317 6.25142 24.4988 6.46499 23.7891 6.70472L24.4612 8.68855C25.1144 8.46766 25.7907 8.27085 26.4691 8.1023L25.9687 6.07031Z"
      />
      <path
        d="M30.324 5.36719C29.609 5.42372 28.8762 5.51166 28.1465 5.62786L28.4773 7.69544C29.1515 7.58656 29.8278 7.50595 30.4873 7.4557L30.324 5.36719Z"
      />
      <path
        d="M32.5 2.14062C40.6101 2.14062 48.2335 5.29905 53.9672 11.0328C54.3598 11.4254 55.0549 11.4254 55.4475 11.0328L57.625 8.85528V16.7969H49.6834L51.7468 14.7335C52.1561 14.3242 52.1561 13.6625 51.7468 13.2532C46.6056 8.112 39.7705 5.28125 32.5 5.28125V7.375C38.8608 7.375 44.8615 9.72314 49.5107 14.009L46.4161 17.1036C46.1167 17.403 46.0267 17.8532 46.1889 18.2447C46.3512 18.6362 46.7333 18.8906 47.1562 18.8906H58.6719C59.2497 18.8906 59.7188 18.4227 59.7188 17.8438V6.32812C59.7188 5.90519 59.4633 5.52203 59.0728 5.36081C58.6792 5.19645 58.2311 5.28753 57.9317 5.58798L54.6958 8.82387C48.6606 3.15609 40.8195 0.046875 32.5 0.046875C14.6058 0.046875 0.046875 14.6047 0.046875 32.5H2.14062C2.14062 15.7605 15.7594 2.14062 32.5 2.14062V2.14062Z"
      />
      <path
        d="M52.0983 51.3897L50.5918 49.9355C48.2856 52.3266 45.5804 54.211 42.5508 55.5353L43.3893 57.4532C46.6713 56.02 49.6004 53.9796 52.0983 51.3897V51.3897Z"
      />
      <path
        d="M39.0317 58.9288C39.7687 58.7477 40.5015 58.5341 41.2113 58.2944L40.5392 56.3105C39.8859 56.5314 39.2096 56.7283 38.5312 56.8968L39.0317 58.9288Z"
      />
      <path
        d="M34.675 59.6329C35.39 59.5764 36.1229 59.4885 36.8525 59.3723L36.5217 57.3047C35.8475 57.4136 35.1712 57.4942 34.5117 57.5444L34.675 59.6329Z"
      />
      <path
        d="M62.8594 32.5C62.8594 49.2395 49.2406 62.8594 32.5 62.8594C24.3899 62.8594 16.7665 59.701 11.0328 53.9672C10.6402 53.5746 9.94508 53.5746 9.5525 53.9672L7.375 56.1447V48.2031H15.3166L13.2532 50.2665C12.8439 50.6758 12.8439 51.3375 13.2532 51.7468C18.3944 56.888 25.2295 59.7188 32.5 59.7188V57.625C26.1392 57.625 20.1385 55.2769 15.4893 50.991L18.5839 47.8964C18.8833 47.597 18.9733 47.1468 18.8111 46.7553C18.6488 46.3638 18.2667 46.1094 17.8438 46.1094H6.32812C5.75025 46.1094 5.28125 46.5773 5.28125 47.1563V58.6719C5.28125 59.0948 5.53669 59.478 5.92717 59.6392C6.3187 59.8025 6.76886 59.7114 7.06827 59.412L10.3042 56.1761C16.3394 61.8439 24.1805 64.9531 32.5 64.9531C50.3942 64.9531 64.9531 50.3953 64.9531 32.5H62.8594Z"
      />
      <path
        d="M50.2969 32.5C50.2969 22.6866 42.3134 14.7031 32.5 14.7031C22.6866 14.7031 14.7031 22.6866 14.7031 32.5C14.7031 42.3134 22.6866 50.2969 32.5 50.2969C42.3134 50.2969 50.2969 42.3134 50.2969 32.5ZM32.5 48.2031C23.8413 48.2031 16.7969 41.1587 16.7969 32.5C16.7969 23.8413 23.8413 16.7969 32.5 16.7969C41.1587 16.7969 48.2031 23.8413 48.2031 32.5C48.2031 41.1587 41.1587 48.2031 32.5 48.2031Z"
      />
    </g>
  </svg>
</template>
<script>
import { iconProps } from "./iconProps"
export default {
  props: iconProps,
}
</script>
