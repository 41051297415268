<template>
  <b-container class="p-4">
    <b-row>
      <b-col cols="12" lg="8">
        <h3 class="mb-2">Billing details</h3>
        <form
          role="form"
          method="post"
          @submit.prevent="$emit('handle-submit')"
        >
          <div id="payment-element" class="payment-element">
            <!-- Stripe will mount elements here -->
          </div>
          <div v-if="error" class="stripe-elements-error text-danger">
            {{ error }}
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <base-button
              @click="$emit('handle-cancel')"
              nativeType="button"
              type="light"
              class="rounded-lg"
              >Cancel</base-button
            >
            <base-button
              nativeType="submit"
              type="success"
              class="rounded-lg"
              :loading="loading"
              >{{ buttonText }}</base-button
            >
          </div>
          <p class="mt-4 text-sm-left text-gray">
            *Your card will be saved in Toolbox to make future connections
            easier and can be edited in your Toolbox under Billing.
          </p>
        </form>
      </b-col>
      <b-col cols="12" lg="4"
        ><h3 class="mb-2 px-3">Your Purchase</h3>
        <div class="mb-2 gray-box-sm">
          {{ paymentIntent.metadata.product_name }}
        </div>
        <div class="d-flex justify-content-between align-items-center px-3">
          <span class="font-weight-bold">Total</span
          ><span>${{ paymentIntent.amount / 100 }}</span>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    paymentIntent: Object,
    buttonText: {
      type: String,
      default: "Submit",
    },
    error: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$emit("handle-wrapper-mounted")
  },
}
</script>

<style lang="scss" scoped>
.payment-element {
  // Compensating for Stripe's weird -4px margin on their iframe
  padding: 4px;
}
</style>
