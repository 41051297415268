<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-9 col-xl-7">
        <h1 class="mt-4 job-title">
          Congratulations! You have successfully applied to {{ job.name }}
        </h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <b-button @click="goToSearch()" variant="success" class="rounded-lg"
        >Back to Search</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      job: {},
    }
  },
  methods: {
    async getJobById() {
      let job = this.$store.state.jobs.list.find(
        ({ _id }) => _id === this.$route.params.jobId
      )
      if (!job) {
        // Job not in cache
        const profileId = this.$store.state.userProfile?._id
        if (profileId) {
          try {
            const data = await this.$store.dispatch(
              "jobs/dispatchFetchJobById",
              { id: this.$route.params.jobId }
            )
            this.job = data
          } catch (error) {
            this.logDevError(error)
          }
        }
      } else {
        this.job = job
      }
    },
    goToSearch() {
      this.$router.push("/jobs/search")
    },
  },
  mounted() {
    this.getJobById()
  },
}
</script>

<style scoped lang="scss"></style>
