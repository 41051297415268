<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        {{
          $store.state.userProfile.type === "provider"
            ? "View your past and current job and project posts here."
            : "View your pending job briefings here."
        }}
      </span></template
    >
    <b-row class="mb-4" v-if="$store.state.userProfile.type === 'provider'">
      <b-col>
        Sort by:
        <b-button-group>
          <b-button
            :class="{
              'btn-active': filter === 'jobs',
              'btn-default': filter !== 'jobs',
            }"
            variant="warning"
            @click="filter = 'jobs'"
            >Jobs</b-button
          >
          <b-button
            :class="{
              'btn-active': filter === 'projects',
              'btn-default': filter !== 'projects',
            }"
            variant="warning"
            @click="filter = 'projects'"
            >Projects</b-button
          >
          <b-button
            :class="{
              'btn-active': filter === 'briefings',
              'btn-default': filter !== 'briefings',
            }"
            variant="warning"
            @click="filter = 'briefings'"
            >Job Briefings</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>
    <v-client-table
      :data="
        filter == 'jobs'
          ? jobs
          : filter == 'briefings'
          ? $store.state.userProfile.type === 'provider'
            ? briefings
            : jobsWithMatches
          : projectsWithMatches
      "
      :columns="
        filter == 'jobs'
          ? columns
          : filter == 'briefings'
          ? $store.state.userProfile.type === 'provider'
            ? briefingColumns
            : seekerBriefingColumns
          : projectColumns
      "
      :options="
        filter == 'jobs' || filter == 'briefings' ? options : projectOptions
      "
    >
      <template slot="createdAt" slot-scope="props">
        {{ $dayjs(props.row.createdAt).format("MM-DD-YYYY") }}
      </template>
      <template slot="matches" slot-scope="props">
        {{ props.row.matches.length || 0 }}
      </template>
      <template slot="action" slot-scope="props" class="cursor-pointer">
        <!--b-button
          v-if="filter == 'jobs' || filter == 'projects'"
          variant="link"
          @click="viewMatches(filter, props.row.id)"
          >View Matches</b-button
        -->
        <div class="action-btns">
          <DefaultButton
            v-if="filter == 'jobs'"
            :labelText="'Edit'"
            @click="$router.push(`/employees/jobs/${props.row._id}/edit`)"
          />
          <DefaultButton
            v-if="filter == 'projects'"
            :labelText="'Edit'"
            @click="
              $router.push(`/trade-partners/projects/${props.row.id}/edit`)
            "
          />
          <DefaultButton
            v-if="filter == 'projects'"
            :labelText="'View'"
            @click="$router.push(`/trade-partners/projects/${props.row.id}`)"
          />
          <DefaultButton
            v-if="filter == 'jobs' || filter == 'briefings'"
            :labelText="'View'"
            @click="
              $router.push(
                $store.state.userProfile.type === 'provider'
                  ? `/employees/jobs/${props.row._id}`
                  : `/jobs/${props.row._id}`
              )
            "
          />
          <confirmation-modal
            v-if="filter == 'jobs'"
            buttonText="Delete"
            description="Are you sure you want to delete this job?"
            title="Delete Job"
            @onConfirm="handleRemovePost(props.row._id)"
          ></confirmation-modal>
          <confirmation-modal
            v-if="filter == 'projects'"
            buttonText="Delete"
            description="Are you sure you want to delete this project?"
            title="Delete Project"
            @onConfirm="handleRemovePost(props.row.id)"
          ></confirmation-modal>
        </div>
      </template>
      <template slot="recipient" slot-scope="props">
        <b-button variant="link" :to="`/profile/${props.row.recipient}`"
          >View Profile</b-button
        >
      </template>
      <template slot="contractorTradeCategories" slot-scope="props">
        <span>
          {{ props.row.contractorTradeCategories.join(", ") }}
        </span>
      </template>
    </v-client-table>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { mapState } from "vuex"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue"
import projects from "@/api/projects"
import jobs from "@/api/jobs"
import DefaultButton from "@/components/DefaultButton"

export default {
  name: "Posts",
  components: {
    InnerLayoutActionBarWrapper,
    ConfirmationModal,
    DefaultButton,
  },
  data() {
    return {
      filter:
        this.$store.state.userProfile.type === "provider"
          ? "jobs"
          : "briefings",
      columns: ["name", "type", "createdAt", "matches", "action"],
      options: {
        headings: {
          type: "Project type",
          createdAt: "Posted On",
        },
      },
      briefingColumns: [
        "name",
        "type",
        "createdAt",
        "recipient",
        "status",
        "action",
      ],
      seekerBriefingColumns: ["name", "type", "createdAt", "action"],
      loading: false,
      projectColumns: [
        "name",
        "contractorTradeCategories",
        "createdAt",
        "matches",
        "action",
      ],
      projectOptions: {
        headings: {
          contractorTradeCategories: "Trade Categories",
          createdAt: "Posted On",
          matches: "Matches",
          // add views
          // action: "",
        },
      },
      projectsWithMatches: [],
      jobsWithMatches: [],
    }
  },
  computed: {
    ...mapState({
      briefings: ({ jobs }) => jobs.briefings,
      jobs: ({ jobs }) => jobs.list,
      projects: ({ projects }) => projects.list,
    }),
  },
  created() {
    if (this.$store.state.userProfile.type === "provider") {
      this.getJobs()
      this.getProjects()
    } else {
      this.getJobBriefingMatches()
    }
  },
  methods: {
    async getJobBriefingMatches() {
      let requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        if (r.type == "job") {
          let job = await this.$store.dispatch("jobs/dispatchFetchJobById", {
            id: r.job,
          })
          //job.status = "Pending";
          this.jobsWithMatches.push(job)
        }
      }
    },
    async getJobs() {
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.loading = true
      await this.$store
        .dispatch("jobs/dispatchFetchJobs")
        .finally(() => (this.loading = false))
      this.getRecipients(this.briefings)
      this.getRecipients(this.jobs)
    },
    async getProjects() {
      this.loading = true
      await this.$store
        .dispatch("projects/dispatchFetchProjects")
        .finally(() => (this.loading = false))
      this.getMatches(this.projects)
    },
    getMatches(projects) {
      let requests = this.$store.state.userProfile.profilesGranted
      for (let r of requests) {
        for (let p of projects) {
          if (r.type == "project" && r.project == p._id) {
            if (p.matches) {
              p.matches++
            } else {
              p.matches = 1
            }
          }
        }
      }
      requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        for (let p of projects) {
          if (r.type == "project" && r.project == p._id) {
            if (p.matches) {
              p.matches++
            } else {
              p.matches = 1
            }
          }
        }
      }
      this.projectsWithMatches = projects
    },
    async viewMatches(type, id) {
      //this.loading = true
      if (this.filter == "projects") {
        this.$router.push("/trade-partners/" + type + "/" + id + "/matches")
      } else {
        this.$router.push("/" + type + "/" + id + "/matches")
      }
    },
    getRecipients(jobs) {
      let requests = this.$store.state.userProfile.profilesGranted
      for (let r of requests) {
        for (let j of jobs) {
          if (r.type == "job" && r.job == j._id) {
            j.recipient = r.profile
            j.status = "Accepted"
            if (j.matches) {
              j.matches++
            } else {
              j.matches = 1
            }
          }
        }
      }
      requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        for (let j of jobs) {
          if (r.type == "job" && r.job == j._id) {
            j.recipient = r.profile
            j.status = "Pending"
            if (j.matches) {
              j.matches++
            } else {
              j.matches = 1
            }
          }
        }
      }
      requests = this.$store.state.userProfile.profilesRejected
      for (let r of requests) {
        for (let j of jobs) {
          if (r.type == "job" && r.job == j._id) {
            j.recipient = r.profile
            j.status = "Rejected"
            if (j.matches) {
              j.matches++
            } else {
              j.matches = 1
            }
          }
        }
      }
      this.jobsWithMatches = jobs
    },
    async handleRemovePost(id) {
      console.log(id, this.filter)
      if (this.filter == "projects") {
        try {
          await projects.deleteProject(id)
          // toast success
          this.$toast.success("Project deleted successfully")
          window.location.reload()
        } catch (error) {
          console.error(error)
          // toast error
          this.$toast.error("Error deleting project")
        }
      }
      if (this.filter == "jobs") {
        try {
          await jobs.deleteJob(id)
          // toast success
          this.$toast.success("Job deleted successfully")
          window.location.reload()
        } catch (error) {
          console.error(error)
          // toast error
          this.$toast.error("Error deleting job")
        }
      }
    },
    setFilter(val) {
      this.filter = val
    },
  },
}
</script>

<style scoped lang="scss">
.btn-active {
  background: #f77f00;
  color: white;
}
.btn-default {
  background: rgba(123, 128, 157, 0.15);
  border-color: transparent;
  color: #555b79;
}

.action-btns {
  display: flex;
  justify-content: center;
}
</style>
