export const iconProps = {
  icon: {
    type: String,
    default: "box",
  },
  width: {
    type: [Number, String],
    default: 24,
  },
  height: {
    type: [Number, String],
    default: 24,
  },
  color: {
    type: String,
    default: "#fcbf49",
  },
}
