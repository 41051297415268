<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Search available job seekers or post an available job and attract
        multiple candidates to respond directly to your opportunity
      </span></template
    >
    <v-client-table
      :data="jobsWithMatches"
      :columns="briefingColumns"
      :options="options"
    >
      <template slot="createdAt" slot-scope="props">
        {{ $dayjs(props.row.createdAt).format("MM-DD-YYYY") }}
      </template>
      <template slot="action" slot-scope="props" class="cursor-pointer">
        <DefaultButton
          :labelText="'View'"
          @click="$router.push(`/employees/jobs/${props.row.id}`)"
        />
      </template>
      <template slot="recipient" slot-scope="props">
        <DefaultButton
          :labelText="'View Profile'"
          @click="$router.push(`/profile/${props.row.recipient}`)"
        />
      </template>
    </v-client-table>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { mapState } from "vuex"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"

export default {
  name: "JobBriefings",
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
  },
  data() {
    return {
      options: {
        headings: {
          type: "Project type",
          createdAt: "Posted On",
        },
      },
      briefingColumns: [
        "name",
        "type",
        "createdAt",
        "recipient",
        "status",
        "action",
      ],
      loading: false,
      jobsWithMatches: [],
    }
  },
  computed: {
    ...mapState({
      briefings: ({ jobs }) => jobs.briefings,
    }),
  },
  created() {
    this.getJobs()
  },
  methods: {
    async getJobs() {
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.loading = true
      await this.$store
        .dispatch("jobs/dispatchFetchJobs")
        .finally(() => (this.loading = false))
      await this.getRecipients(this.briefings)
    },
    getRecipients(jobs) {
      let requests = this.$store.state.userProfile.profilesGranted
      console.log("requests", requests)
      console.log("briefings", this.briefings)
      for (let r of requests) {
        for (let j of jobs) {
          if (r.type == "job" && r.job == j._id) {
            j.recipient = r.profile
            j.status = "Accepted"
            this.jobsWithMatches.push(j)
          }
        }
      }

      requests = this.$store.state.userProfile.profilesRejected
      for (let r of requests) {
        for (let j of jobs) {
          if (r.type == "job" && r.job == j._id) {
            j.recipient = r.profile
            j.status = "Rejected"
            this.jobsWithMatches.push(j)
          }
        }
      }
      // get jobs with no matches
      for (let j of jobs) {
        if (!j.recipient) {
          j.recipient = j.jobSeeker
          j.status = "Pending"
          this.jobsWithMatches.push(j)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn-active {
  background: #f77f00;
  color: white;
}
.btn-default {
  background: rgba(123, 128, 157, 0.15);
  border-color: transparent;
  color: #555b79;
}
</style>
