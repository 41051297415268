<template>
  <div class="row checked-list-section">
    <ul class="checked-list">
      <li v-for="(item, index) in items" :key="index">
        <PhCheckCircle
          color="#10C95A"
          weight="fill"
          :size="32"
          v-if="item.value"
        />
        <PhXCircle color="#B0B9C9" weight="fill" :size="32" v-else />
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import { PhXCircle, PhCheckCircle } from "phosphor-vue"
export default {
  name: "checked-list",
  components: {
    PhXCircle,
    PhCheckCircle,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    listItems() {
      console.log(this.$props.items)
      return [...new Set(this.$props.items)]
    },
  },
}
</script>

<style lang="scss" scoped>
.checked-list-section {
  margin-top: 0 !important;
}
.checked-list {
  list-style: none;
  padding: 0;
  margin-top: 5px;

  li {
    svg {
      margin-right: 16px;
    }
  }
}
</style>
