import Jobs from "@/api/jobs.js"

const state = {
  list: [],
  briefings: [],
}

const getters = {}

const mutations = {
  SET_JOBS: (state, jobs) => {
    state.list = jobs
  },
  SET_BRIEFINGS: (state, jobs) => {
    state.briefings = jobs
  },
  RESET: (state) => {
    state.list = null
  },
  DO_NOTHING: () => {
    console.log()
  },
}

const actions = {
  async dispatchFetchJobs({ commit }, params) {
    try {
      const { data } = await Jobs.getJobs(params)
      const briefings = data.filter((x) => x.briefing)
      const jobs = data.filter((x) => !x.briefing)
      commit("SET_JOBS", jobs)
      commit("SET_BRIEFINGS", briefings)
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async dispatchFetchJobsByLocation({ commit }) {
    try {
      const { data } = await Jobs.getJobsByLocation(46590)
      commit("SET_JOBS", data)
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async dispatchFetchJobById({ commit }, { id }) {
    try {
      const { data } = await Jobs.getJobDetails(id)
      commit("DO_NOTHING")
      return data
    } catch (error) {
      console.log(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
