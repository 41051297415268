<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Search available job seekers or post an available job and attract
        multiple candidates to respond directly to your opportunity
      </span></template
    >

    <b-row class="my-4">
      <b-col sm md="3">
        <base-input placeholder="ZIP Code" type="number" v-model="filter.zip">
        </base-input>
      </b-col>
      <b-col sm md="5">
        <multiselect
          v-model="filter.position"
          placeholder="Any Position Type"
          :options="positionOptions"
          :multiple="true"
          :taggable="true"
          :close-on-select="false"
          style="margin-bottom: 20px"
        >
        </multiselect>
      </b-col>
      <b-col sm md="2">
        <button
          style="width: 100%"
          class="btn btn-warning"
          @click="filterJobSeekers()"
          type="warning"
        >
          Search
        </button>
      </b-col>
      <b-col sm md="2">
        <button class="btn" @click="extraFilters = true" type="warning">
          + Filters
        </button>
      </b-col>
    </b-row>
    <b-row v-if="extraFilters" class="my-4">
      <b-col sm md="3">
        <multiselect
          v-model="filter.experience"
          placeholder="Experience"
          :options="extraFilterOptions"
          label="name"
          :multiple="false"
          :taggable="false"
          :close-on-select="true"
        >
        </multiselect>
      </b-col>
      <b-col sm md="3">
        <multiselect
          v-model="filter.training"
          placeholder="Training"
          :options="extraFilterOptions"
          label="name"
          :multiple="false"
          :taggable="false"
          :close-on-select="true"
        >
        </multiselect>
      </b-col>
      <b-col sm md="6">
        <multiselect
          v-model="filter.categories"
          placeholder="Search or add a category"
          :options="categoryOptions"
          :multiple="true"
          :close-on-select="false"
          :taggable="true"
        >
        </multiselect>
      </b-col>
    </b-row>
    <div class="row" v-if="jobSeekerSearch.length">
      <div v-for="seeker in jobSeekerSearch" :key="seeker._id" class="col-lg-4">
        <employee-card :seeker="seeker" />
      </div>
    </div>
    <div class="row" v-else-if="jobSeekerSearch.length == 0">
      <div
        v-if="loadingData"
        class="text-center align-items-center"
        style="margin: auto"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <h2 class="ml-3">No results. Check back next time!</h2>
      </div>
    </div>
    <!-- Result -->
    <!--b-row>
      <b-col>
        <EmployeeCard />
      </b-col>
      <b-col>
        <EmployeeCard />
      </b-col>
      <b-col>
        <EmployeeCard />
      </b-col>
    </b-row-->
  </inner-layout-action-bar-wrapper>
</template>

<script>
//import { filterJobs } from "@/api/jobs"
//import { searchUsersByParams } from "@/api/profile"
import EmployeeCard from "@/components/Cards/EmployeeCard"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import options from "@/utils/options"

export default {
  name: "Search",
  components: {
    InnerLayoutActionBarWrapper,
    EmployeeCard,
  },
  data() {
    return {
      filter: {
        zip: this.$store.state.userProfile.zip,
        position: [],
        categories: [],
        experience: null,
        training: null,
      },
      extraFilterOptions: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
      categoryOptions: options.categoryOptions,
      positionOptions: options.positionOptions,
      jobSeekerSearch: [],
      loadingData: false,
      extraFilters: false,
    }
  },
  methods: {
    async filterJobSeekers() {
      const profileId = this.$store.state.userProfile?._id
      this.$store.dispatch("api/updateProfile", {
        profileId,
        data: { lastActivity: new Date() },
      })
      this.loadingData = true
      this.jobSeekerSearch = []
      const { zip, position, experience, training, categories } = {
        ...this.filter,
      }
      const date = new Date()
      const daysAgo = new Date(date.getTime())
      daysAgo.setDate(date.getDate() - 3)
      //if (zip && position) {
      try {
        const list = await this.$store.dispatch("api/searchUsersByParams", {
          filters: {
            zip,
            radius: 50,
          },
          mongoFilters: {
            ...(position.length
              ? { "jobSeeker.positionSeeking": position }
              : {}),
            type: "jobSeeker",
            public: true,
            ...(categories.length
              ? { "jobSeeker.handsOnExperiences.category": categories }
              : {}),
            ...(experience == null && training == null
              ? {
                  $or: [
                    {
                      "jobSeeker.lastPriorityAccess": {
                        $lt: daysAgo.toISOString(),
                      },
                    },
                    {
                      $and: [
                        { "jobSeeker.hasHandsOnExperience": false },
                        { "jobSeeker.hasFormalTraining": false },
                      ],
                    },
                  ],
                }
              : experience?.value || training?.value
              ? {
                  $and: [
                    {
                      "jobSeeker.lastPriorityAccess": {
                        $lt: daysAgo.toISOString(),
                      },
                    },
                    {
                      $or: [
                        {
                          "jobSeeker.hasHandsOnExperience":
                            experience?.value || false,
                        },
                        {
                          "jobSeeker.hasFormalTraining":
                            training?.value || false,
                        },
                      ],
                    },
                  ],
                }
              : {
                  $and: [
                    {
                      "jobSeeker.hasHandsOnExperience":
                        experience?.value || false,
                    },
                    { "jobSeeker.hasFormalTraining": training?.value || false },
                  ],
                }),
            // "jobSeeker.hasHandsOnExperience": false,
          },
        })
        console.log(list)
        this.jobSeekerSearch = list.data
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
      //}

      // TODO: assign list to data key and then render
    },
  },
  mounted() {
    this.filterJobSeekers()
  },
}
</script>

<style scoped></style>
