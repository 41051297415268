var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('inner-layout-action-bar-wrapper',{scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$store.state.userProfile.type === "provider" ? "View your past and current job and project posts here." : "View your pending job briefings here.")+" ")])]},proxy:true}])},[(_vm.$store.state.userProfile.type === 'provider')?_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_vm._v(" Sort by: "),_c('b-button-group',[_c('b-button',{class:{
            'btn-active': _vm.filter === 'jobs',
            'btn-default': _vm.filter !== 'jobs',
          },attrs:{"variant":"warning"},on:{"click":function($event){_vm.filter = 'jobs'}}},[_vm._v("Jobs")]),_c('b-button',{class:{
            'btn-active': _vm.filter === 'projects',
            'btn-default': _vm.filter !== 'projects',
          },attrs:{"variant":"warning"},on:{"click":function($event){_vm.filter = 'projects'}}},[_vm._v("Projects")]),_c('b-button',{class:{
            'btn-active': _vm.filter === 'briefings',
            'btn-default': _vm.filter !== 'briefings',
          },attrs:{"variant":"warning"},on:{"click":function($event){_vm.filter = 'briefings'}}},[_vm._v("Job Briefings")])],1)],1)],1):_vm._e(),_c('v-client-table',{attrs:{"data":_vm.filter == 'jobs'
        ? _vm.jobs
        : _vm.filter == 'briefings'
        ? _vm.$store.state.userProfile.type === 'provider'
          ? _vm.briefings
          : _vm.jobsWithMatches
        : _vm.projectsWithMatches,"columns":_vm.filter == 'jobs'
        ? _vm.columns
        : _vm.filter == 'briefings'
        ? _vm.$store.state.userProfile.type === 'provider'
          ? _vm.briefingColumns
          : _vm.seekerBriefingColumns
        : _vm.projectColumns,"options":_vm.filter == 'jobs' || _vm.filter == 'briefings' ? _vm.options : _vm.projectOptions},scopedSlots:_vm._u([{key:"createdAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$dayjs(props.row.createdAt).format("MM-DD-YYYY"))+" ")]}},{key:"matches",fn:function(props){return [_vm._v(" "+_vm._s(props.row.matches.length || 0)+" ")]}},{key:"action",fn:function(props){return [_c('div',{staticClass:"action-btns"},[(_vm.filter == 'jobs')?_c('DefaultButton',{attrs:{"labelText":'Edit'},on:{"click":function($event){return _vm.$router.push(("/employees/jobs/" + (props.row._id) + "/edit"))}}}):_vm._e(),(_vm.filter == 'projects')?_c('DefaultButton',{attrs:{"labelText":'Edit'},on:{"click":function($event){return _vm.$router.push(("/trade-partners/projects/" + (props.row.id) + "/edit"))}}}):_vm._e(),(_vm.filter == 'projects')?_c('DefaultButton',{attrs:{"labelText":'View'},on:{"click":function($event){return _vm.$router.push(("/trade-partners/projects/" + (props.row.id)))}}}):_vm._e(),(_vm.filter == 'jobs' || _vm.filter == 'briefings')?_c('DefaultButton',{attrs:{"labelText":'View'},on:{"click":function($event){return _vm.$router.push(
              _vm.$store.state.userProfile.type === 'provider'
                ? ("/employees/jobs/" + (props.row._id))
                : ("/jobs/" + (props.row._id))
            )}}}):_vm._e(),(_vm.filter == 'jobs')?_c('confirmation-modal',{attrs:{"buttonText":"Delete","description":"Are you sure you want to delete this job?","title":"Delete Job"},on:{"onConfirm":function($event){return _vm.handleRemovePost(props.row._id)}}}):_vm._e(),(_vm.filter == 'projects')?_c('confirmation-modal',{attrs:{"buttonText":"Delete","description":"Are you sure you want to delete this project?","title":"Delete Project"},on:{"onConfirm":function($event){return _vm.handleRemovePost(props.row.id)}}}):_vm._e()],1)]}},{key:"recipient",fn:function(props){return [_c('b-button',{attrs:{"variant":"link","to":("/profile/" + (props.row.recipient))}},[_vm._v("View Profile")])]}},{key:"contractorTradeCategories",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.row.contractorTradeCategories.join(", "))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }