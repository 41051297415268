<template>
  <div class="card" v-if="seekerData" @click="goToUserProfile">
    <div class="card-header">
      <div class="left">
        <signed-image
          v-if="
            hasProfileViewAccess &&
            seekerData &&
            typeof seekerData.profilePhoto === 'object'
          "
          :file="seekerData.profilePhoto"
          :alt="seekerData.name"
        />
        <img v-else class="default-img" src="/img/icons/jobseeker.svg" alt="" />
        <div class="jobseeker-title title-wrapper">
          <span class="jobseeker-name heading">{{ seekerData.name }}</span>
        </div>
      </div>
      <!-- <div class="right">
        <PhBookmarkSimple size="24px" color="#B0B9C9" v-if="!save" />
        <PhBookmarkSimple weight="fill" size="24px" color="#4779c9" v-else />
      </div> -->
    </div>
    <div class="card-details">
      <div class="jobseeker-location">
        <PhMapPin weight="fill" size="32px" color="#E24646" /><span
          class="location"
          >{{ location || "" }}</span
        >
      </div>
    </div>
    <div class="description">
      {{
        seekerData.description
          ? seekerData.description
          : "No description provided."
      }}
    </div>
    <span class="posted"> Posted {{ postDate }} </span>
  </div>
</template>

<script>
import { PhMapPin } from "phosphor-vue"
import moment from "moment"

export default {
  props: {
    seeker: Object,
    seekerId: String,
    jobId: String,
    match: Boolean,
  },
  components: {
    PhMapPin,
  },
  data() {
    const isSkilledWorker =
      this.$router.currentRoute.path.includes("skilled-workers")
    return {
      seekerData: {},
      firstName: "",
      isSkilledWorker: isSkilledWorker,
      show: false,
      hasProfileViewAccess: false,
      location: "",
      postDate: "",
    }
  },
  methods: {
    goToUserProfile() {
      let toolbox = this.$router.currentRoute.path.includes("toolbox")
      if (this.match) {
        this.$router.push(
          "/jobs/" +
            this.jobId +
            "/matches/" +
            (this.seekerId || this.seeker._id)
        )
      } else if (toolbox) {
        this.$router.push(
          "/profile/" + this.seekerId || this.seekerData._id || this.seeker._id
        )
      } else if (this.isSkilledWorker) {
        this.$router.push(
          "/employees/skilled-workers/profile/" + this.seekerData._id
        )
      } else {
        this.$router.push(
          "/profile/" + this.seekerId || this.seekerData._id || this.seeker._id
        )
      }
    },
    async getEmployeeProfile() {
      try {
        const list = await this.$store.dispatch("api/getProfile", {
          profileId: this.seekerId || this.seekerData._id,
        })
        this.seekerData = list.data
        this.firstName = list.data.name
        this.location = this.seekerData.city
          ? this.seekerData.city + ", " + this.seekerData.state
          : this.seekerData.state
        this.postDate = moment(this.seekerData.createdAt).fromNow()
      } catch (error) {
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
    },
  },
  mounted() {
    if (this.seekerId) {
      this.getEmployeeProfile()
    } else {
      this.seekerData = this.seeker
      this.firstName = this.seekerData.name
      this.getEmployeeProfile()
    }

    let profileId = this.seekerId || this.seekerData._id,
      unlockedApplicants =
        this.$store.state.userProfile?.provider.unlockedApplicants || []

    let isUnlockedProfile = unlockedApplicants.find(
      (item) => item.profile === profileId
    )

    this.hasProfileViewAccess = !isUnlockedProfile ? false : true
  },
}
</script>

<style scoped>
.title-wrapper {
  flex-direction: unset !important;
}
</style>
