<template>
  <div class="row">
    <div class="col">
      <h2 class="font-22-900 dark-text mb-2">{{ title }}</h2>
      <p class="font-16-400 m-0">{{ content }}</p>
    </div>
    <div class="col-auto" v-if="isEditing">
      <edit-button
        class="paragraph-edit-button"
        @handle-click="handleEditClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "editable-paragraph",
  props: {
    isEditing: {
      type: Boolean,
      // Making this default true because it's intuitive for a component
      // called "Editable Paragraph"
      default: true,
    },
    title: { type: String, required: true },
    content: { type: String, required: true },
    dataKey: { type: String, required: true },
  },
  methods: {
    handleEditClick() {
      this.$emit("handle-click", this.dataKey)
    },
  },
}
</script>

<style lang="scss" scoped></style>
