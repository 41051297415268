<template>
  <div>
    <base-modal
      id="skilled-worker-payment-change-card-modal"
      size="sm"
      title="Change payment method"
      @ok="handlePaymentMethodChangeConfirm"
    >
      <form>
        <div
          role="radiogroup"
          aria-labelledby="payment-method-legend"
          id="select-payment-method"
        >
          <p id="payment-method-legend" class="font-14-700 px-2">
            Please select the payment method you would like to use for this
            purchase:
          </p>
          <payment-method-display
            v-for="(paymentMethod, index) in paymentMethods"
            :key="paymentMethod.id"
            role="radio"
            :aria-checked="selection.id === paymentMethod.id"
            tabindex="0"
            :class="[
              'payment-method-radio',
              {
                'cursor-pointer': selection.id !== paymentMethod.id,
              },
              ...(selection.id === paymentMethod.id
                ? ['selected', 'shadow-lg']
                : []),
              { 'mb-3': index !== paymentMethods.length - 1 },
            ]"
            @handle-click="selection = paymentMethod"
            :paymentMethod="paymentMethod"
          >
          </payment-method-display>
        </div>
      </form>
    </base-modal>
  </div>
</template>

<script>
import PaymentMethodDisplay from "./PaymentMethodDisplay.vue"
export default {
  components: {
    PaymentMethodDisplay,
  },
  props: {
    currentPaymentMethod: Object,
    paymentMethods: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selection: this.currentPaymentMethod,
    }
  },
  methods: {
    handlePaymentMethodChangeConfirm() {
      this.$emit("handle-payment-method-select", this.selection)
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-method-radio {
  border: 1px solid var(--new);
  &.selected {
    border-color: var(--green);
  }
  &:hover {
    border-color: var(--dark-bg);
  }
}
</style>
