<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1000 636"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,423.797,264)">
      <g transform="matrix(5.88889,0,0,5.88889,-372.551,-264)">
        <rect x="0" y="0" width="152.407" height="108" style="fill: none" />
      </g>
      <g transform="matrix(5.88889,0,0,5.88889,-372.551,-264)">
        <rect
          x="60.412"
          y="25.697"
          width="31.5"
          height="56.606"
          style="fill: rgb(255, 95, 0)"
        />
        <g transform="matrix(1,0,0,1,-319.796,-252)">
          <path
            d="M382.208,306C382.198,294.961 387.275,284.512 395.958,277.697C389.616,272.712 381.778,270 373.711,270C353.962,270 337.711,286.251 337.711,306C337.711,325.749 353.962,342 373.711,342C381.778,342 389.616,339.288 395.958,334.303C387.275,327.488 382.198,317.039 382.208,306Z"
            style="fill: rgb(235, 0, 27); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1,0,0,1,-319.796,-252)">
          <path
            d="M454.203,305.999L454.203,306.001C454.203,325.75 437.953,342 418.205,342C410.138,342 402.3,339.288 395.958,334.303C404.634,327.481 409.708,317.036 409.708,306C409.708,294.964 404.634,284.519 395.958,277.697C402.3,272.712 410.138,270 418.205,270C437.953,270 454.203,286.25 454.203,305.999Z"
            style="fill: rgb(247, 158, 27); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1,0,0,1,-319.796,-252)">
          <path
            d="M450.769,328.308L450.769,327.149L451.236,327.149L451.236,326.913L450.046,326.913L450.046,327.149L450.514,327.149L450.514,328.308L450.769,328.308ZM453.079,328.308L453.079,326.91L452.715,326.91L452.295,327.872L451.875,326.91L451.51,326.91L451.51,328.308L451.768,328.308L451.768,327.254L452.161,328.162L452.428,328.162L452.822,327.251L452.822,328.308L453.079,328.308Z"
            style="fill: rgb(247, 158, 27); fill-rule: nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
