<template>
  <div class="row job-details">
    <div class="contact-info-wrapper">
      <div class="contact-info">
        <div class="profile-img">
          <img src="/img/icons/mask-group.svg" alt="" />
        </div>
        <div class="contact-info-details">
          <h3>
            {{ job.name }}
          </h3>
          <span>{{ job.profile[0].name }}</span>
          <span>{{ job.type }}</span>
          <span
            ><PhMapPin weight="fill" size="24px" />{{ job.city }},
            {{ job.state }}</span
          >
          <span>
            <icon
              icon="calendar"
              width="20"
              height="20"
              color="black"
              class="mr-2"
            />Posted on:
            {{ $dayjs().format("MM/DD/YYYY") }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <div v-if="this.$store.state.userProfile.type == 'jobSeeker'">
        <div class="action-btns" v-if="job.briefing">
          <b-button
            variant="danger"
            @click="handleDeclineJob"
            class="mr-4 rounded-lg"
            >Decline</b-button
          >
          <b-button
            variant="success"
            @click="handleAcceptJob"
            class="rounded-lg"
            >I'm Interested</b-button
          >
        </div>
        <div class="action-btns" v-else>
          <DefaultButton
            :labelText="'View Company Profile'"
            @click="goToCompanyProfile(job.profile[0]._id)"
          />
          <DefaultButton :labelText="'Save'" @click="handleSaveJob" />
          <DefaultButton
            :labelText="alreadyApplied ? 'Already Applied' : 'Apply'"
            :disabled="alreadyApplied"
            @click="handleApplyForJob"
          />
        </div>
      </div>
      <div class="action-btns" v-else>
        <confirmation-modal
          buttonText="Discard"
          description="Are you sure you want to delete this job?"
          title="Delete Job"
          @onConfirm="handleDeleteJob()"
        ></confirmation-modal>
        <DefaultButton
          :labelText="'Edit'"
          @click="$router.push(`/employees/jobs/${job && job._id}/edit`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
import { PhMapPin } from "phosphor-vue"
import job from "@/api/jobs"
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue"
import profile from "@/api/profile"
export default {
  components: {
    DefaultButton,
    PhMapPin,
    ConfirmationModal,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    alreadyApplied: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    async handleDeleteJob() {
      try {
        await job.deleteJob(this.$router.currentRoute.params?.jobId)
        this.$toast.success("Job has been deleted successfully")
        this.$router.push(`/employees/jobs`)
      } catch (error) {
        this.$toast.error(error.message || "We could not delete this job.")
      }
    },
    async handleSaveJob() {
      try {
        let userProfile = JSON.parse(
          JSON.stringify(this.$store.state.userProfile)
        )
        if (userProfile.savedJobs) {
          if (userProfile.savedJobs.includes(this.job._id)) {
            let index = userProfile.savedJobs.indexOf(this.job._id)
            userProfile.savedJobs.splice(index, 1)
          } else {
            userProfile.savedJobs.push(this.job._id)
          }
        } else {
          userProfile.savedJobs = [this.job._id]
        }
        await this.$store.dispatch("api/updateProfile", {
          profileId: userProfile._id,
          data: { ...userProfile },
        })
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
    async handleApplyForJob() {
      let recipientProfileId = this.job.profile[0]._id
      let id = this.$router.currentRoute.params?.jobId
      let type = "job-application"
      let data = await profile.requestProfileAccess(
        recipientProfileId,
        id,
        type
      )
      console.log("DATA", data)
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.$router.push("/jobs/" + this.job._id + "/success")
    },
    handleDeclineJob() {
      this.declineJob = true
    },
    goToCompanyProfile(id) {
      this.$router.push({
        name: "profile-id",
        params: { profileId: id, type: "provider" },
      })
    },
    async handleAcceptJob() {
      let id = this.$router.currentRoute.params.jobId
      let type = "job"
      await profile.grantProfileAccess(this.company._id, id, type)
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.$router.push("/dashboard")
    },
  },
}
</script>

<style lang="scss" scoped>
.job-details {
  display: flex;
  padding: 24px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--Gray-50, #f6f7f9);
  margin: 0;
  margin-bottom: 30px;
  position: relative;

  .contact-info-wrapper {
    display: flex;
    width: 100%;

    .contact-info {
      display: flex;
      width: 100%;
      align-items: center;

      .profile-img {
        width: 104px;
        height: 104px;
        fill: var(--Gray-100, #eceef2);
        border-radius: 50%;
        margin-right: 32px;

        img {
          border-radius: 50%;
        }
      }

      .contact-info-details {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 6px;
          font-size: 24px;
          font-weight: 700;
        }

        span {
          margin-top: 12px;
          font-size: 14px;
          display: flex;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .action-btns {
    display: flex;
    position: absolute;
    right: 24px;
    top: 24px;
  }
}
</style>
