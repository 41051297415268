import messagesMixin from "@/mixins/messagesMixin"

export default {
  mixins: [messagesMixin],
  methods: {
    async getProduct(productSlug) {
      const response = await this.$store.dispatch("api/getProductBySlug", {
        slug: productSlug,
      })
      if (!response.data || response.data.errors) {
        this.showErrorMessage(
          response.data?.errors?.[0],
          "We couldn't retrieve the product you're trying to purchase."
        )
        return null
      }
      return response.data
    },
  },
}
