<template>
  <div>
    <div class="header">
      <div class="action">
        <DefaultButton
          :labelText="'+ New Project'"
          @click="$router.push('/trade-partners/projects/new')"
        />
      </div>
      <div class="text">
        Post or search for an upcoming project or task and quickly find trade
        partners to review and connect with
      </div>
    </div>
    <div>
      <div class="projects-table">
        <div class="project-info" v-for="(project, i) in list" :key="i">
          <div class="project-basics">
            <span class="project-name">{{ project.name }}</span>
            <!-- <capsule-list :items="[project.type]" :highlighted="true" /> -->
            <span class="posted">
              Posted {{ getFormattedDate(project.createdAt) }}
            </span>
          </div>
          <div class="count">
            <span class="stats">{{
              project.views ? project.views.length : 0
            }}</span>
            <span>Views</span>
          </div>
          <ActionButtonsDropdown
            :actions="actionsArray"
            :btnText="'Manage Post'"
            :id="project._id"
          />
        </div>
      </div>
      <div class="row pagination" v-if="projects.length">
        <b-pagination
          v-model="currentPage"
          :total-rows="projects.length"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
      <p v-if="loading" class="text-center">
        <b-spinner class="mx-auto d-inline-block"></b-spinner>
      </p>
    </div>
    <default-modal
      description="Are you sure you want to delete this project?"
      title="Delete Project"
      @onConfirm="handleDelete(deleteId)"
      id="delete-project-modal"
      :showCancel="true"
      :btnClasses="['delete-btn']"
    />
    <toast-message ref="toastMessage" />
  </div>
</template>

<script>
import projects from "@/api/projects"
import DefaultButton from "@/components/DefaultButton"
import ActionButtonsDropdown from "@/components/ActionButtonsDropdown"
import moment from "moment"

export default {
  name: "Projects",
  components: {
    DefaultButton,
    ActionButtonsDropdown,
  },
  data() {
    return {
      loading: false,
      projects: this.$store.state.projects.list,
      deleteId: null,
      actionsArray: [
        { name: "View", function: this.viewPost },
        { name: "Edit", function: this.handleEdit },
        { name: "Close", function: this.handleDeleteModal },
      ],
      limit: 9,
      currentPage: 1,
      totalRows: 0,
      pageCount: 0,
    }
  },
  computed: {
    list() {
      const items = this.projects
      // Return just page of items needed
      return items.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    viewPost(id) {
      this.$router.push("/trade-partners/projects/" + id)
    },
    goToPage(page) {
      this.currentPage = page
    },
    getFormattedDate(dateString) {
      const parsedDate = moment(dateString)

      const currentDate = moment()

      const diffInDays = currentDate.diff(parsedDate, "days")

      // Determine the display format based on the difference in days
      let displayDate
      if (diffInDays === 0) {
        displayDate = "Today"
      } else if (diffInDays === 1) {
        displayDate = "Yesterday"
      } else if (diffInDays < 30) {
        displayDate = diffInDays + " days ago"
      } else {
        displayDate = "on " + parsedDate.format("MMM D, YYYY")
      }
      return displayDate
    },
    getMatches(projects) {
      let requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        for (let p of projects) {
          if (r.type == "project" && r.project == p._id) {
            if (p.matches) {
              p.matches.push(r)
            } else {
              p.matches = [r]
            }
          }
        }
      }
      requests = this.$store.state.userProfile.profilesGranted
      for (let r of requests) {
        for (let p of projects) {
          if (r.type == "project" && r.project == p._id) {
            if (p.matches) {
              p.matches.push(r)
            } else {
              p.matches = [r]
            }
          }
        }
      }
      this.projects = projects
    },
    handleDeleteModal(id) {
      this.deleteId = id
      this.$bvModal.show("delete-project-modal")
    },
    async handleDelete(id) {
      this.loading = true
      try {
        await projects.deleteProject(id)
        this.$bvModal.hide("delete-project-modal")
        this.getProjects()
        this.$refs.toastMessage.showToast(
          "Project has been deleted successfully.",
          "success"
        )
      } catch (error) {
        this.$refs.toastMessage.showToast("Something went wrong.", "error")
      } finally {
        this.loading = false
      }
    },
    async handleEdit(id) {
      //this.loading = true
      this.$router.push("/trade-partners/projects/" + id + "/edit")
    },
    async viewMatches(id) {
      //this.loading = true
      this.$router.push("/trade-partners/projects/" + id + "/matches")
    },
    async getProjects() {
      this.loading = true
      await this.$store
        .dispatch("projects/dispatchFetchProjects")
        .finally(() => (this.loading = false))
      this.projects = this.$store.state.projects.list
      this.getMatches(this.projects)

      this.projects.forEach((project, i) => {
        this.getProjectViews(project, i)
      })
    },
    async getProjectViews(project, index) {
      let payload = {
        projectId: project._id,
        activityType: "view-project-details",
      }
      await this.$store
        .dispatch("api/getActivityLogs", payload)
        .then((response) => {
          let views = response.data
          if (views.length) {
            //remove duplicates
            views = views.filter(
              (v, i, a) => a.findIndex((t) => t.companyId == v.companyId) === i
            )
          }
          let projects = [...this.projects]
          projects[index].views = views
          this.projects = projects
        })
    },
  },
}
</script>

<style scoped>
.actions-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.categories {
  max-width: 100px;
  overflow: hidden;
  white-space: normal;
}

.header {
  border-radius: 16px;
  background: var(--gfb-blue-brand-50, #c8ecff);
  display: flex;
  align-items: center;
  padding: 24px;
  margin-bottom: 32px;

  .action {
    margin-right: 24px;
  }

  .text {
    color: var(--Gray-800, #3a4252);
    font-weight: 400;
  }
}

.projects-table {
  padding: 24px 40px;
  background: #fff;
  display: flex;
  flex-direction: column;

  .project-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Gray-200, #d5d9e2);
    padding: 24px 0;
  }

  .project-basics {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 200px;
    .project-name {
      color: var(--Gray-900, #343946);
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .posted {
      color: var(--Gray-500, #677690);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .count {
    display: flex;
    flex-direction: column;
    color: var(--Gray-600, #525e77);
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    align-items: flex-end;

    .stats {
      color: var(--Gray-800, #3a4252);
      font-size: 18px;
      padding-bottom: 15px;
    }
  }
}

.pagination {
  justify-content: center;
  margin-top: 50px;
}
</style>
