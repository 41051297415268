export default {
  methods: {
    logDevError(error) {
      if (process.env.NODE_ENV === "development") {
        console.log(error)
      }
    },
    showErrorMessage(error, message) {
      if (error) {
        this.logDevError(error)
      }
      this.$toast.error(message ?? error?.message ?? "Something went wrong.")
    },
  },
}
