import InnerLayout from "@/layout/InnerLayout"

const navItems = [
  {
    title: "Job Postings",
    to: "/employees/jobs",
  },
  // {
  //   title: "Search Job Seekers",
  //   to: "/employees/search",
  // },
  {
    title: "Company Matches",
    to: "/employees/company-matches",
  },
]

export const employeesRouter = [
  {
    path: "/employees",
    redirect: "/employees/jobs",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "jobs",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobPostings.vue"
          ),
      },
      {
        path: "briefings",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobBriefings.vue"
          ),
      },
      {
        path: "jobs/new",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobsEdit.vue"
          ),
      },
      {
        path: "jobs/:jobId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobDetail.vue"
          ),
      },
      {
        path: "jobs/:jobId/checkout-complete",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobPostingCheckoutComplete.vue"
          ),
      },
      {
        path: "jobs/:jobId/edit",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobsEdit.vue"
          ),
      },
      {
        path: "search",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/Search.vue"
          ),
      },
      {
        path: "search/profile/:profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/SearchResultProfileDetail.vue"
          ),
      },
      {
        path: "search/profile/:profileId/job",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/SearchResultProfileJobBriefing.vue"
          ),
      },
      {
        path: "search/profile/:profileId/job-application/:jobId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/SearchResultProfileJobApplication.vue"
          ),
      },
      {
        path: "skilled-workers",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/SearchSkilledWorkers.vue"
          ),
      },
      {
        path: "skilled-workers/profile/:profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/SearchResultProfileDetail.vue"
          ),
      },
      {
        path: "skilled-workers/profile/:profileId/access",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/EmployeeAccess.vue"
          ),
      },
      {
        path: "skilled-workers/profile/:profileId/access/checkout-complete",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/EmployeeAccessCheckoutComplete.vue"
          ),
      },
      {
        path: "skilled-workers/profile/:profileId/job",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/SearchResultProfileJobBriefing.vue"
          ),
      },
      {
        path: "skilled-workers/profile/:profileId/job/:jobId/response",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/EmployeeJobResponse.vue"
          ),
      },
      {
        path: "skills-needed",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/EmployeeSkillsNeeded.vue"
          ),
      },
      {
        path: "job-postings",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Toolbox/Posts.vue"
          ),
      },
      {
        path: "company-matches",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/CompanyMatches.vue"
          ),
      },
      {
        path: "jobs-view/:jobId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Employees/JobView.vue"
          ),
      },
    ],
  },
]
