<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1 orange-clr">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor.
      </span></template
    >
    <template v-slot:actions-right>
      <b-button variant="link" to="/vendors" class="mr-1"
        >Back to Search</b-button
      >
      <b-button
        variant="success"
        @click="handleConnectToVendor"
        class="rounded-lg"
        >Apply</b-button
      >
    </template>
    <profile></profile>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  components: {
    InnerLayoutActionBarWrapper,
  },
  methods: {
    handleConnectToVendor() {
      console.log("TODO: Handle connect to vendor")
    },
  },
}
</script>

<style scoped></style>
