<template>
  <div class="">
    <b-row class="mb-5">
      <b-col sm md="7">
        <CustomMultiSelect
          :selectOptions="categoryOptions"
          :selectedList="employeeSkillsNeeded"
          selectLabel="Search employee skill needed"
          @remove-option="removeSkill"
          @select-option="addSkill"
        ></CustomMultiSelect>
      </b-col>
      <b-col>
        <div class="btn-group" role="group" aria-label="Basic example">
          <DefaultButton
            :labelText="'Save'"
            @click="updateSkillsNeeded"
            :disabled="disabled"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import options from "@/utils/options"
import DefaultButton from "@/components/DefaultButton"
import CustomMultiSelect from "@/components/CustomMultiSelect"

export default {
  components: {
    DefaultButton,
    CustomMultiSelect,
  },
  data() {
    return {
      categoryOptions: options.categoryOptions,
      employeeSkillsNeeded:
        this.$store.state.userProfile.employeeSkillsNeeded || [],
      disabled: false,
    }
  },
  methods: {
    removeSkill(option) {
      this.employeeSkillsNeeded = this.employeeSkillsNeeded.filter(
        (category) => category !== option
      )
    },
    addSkill(option) {
      this.employeeSkillsNeeded.push(option)
    },
    async updateSkillsNeeded() {
      this.disabled = true
      const profile = this.$store.state.userProfile

      const updatedProfile = {
        ...profile,
        employeeSkillsNeeded: this.employeeSkillsNeeded,
      }
      const response = await this.$store.dispatch("api/updateCompanyProfile", {
        profileId: profile._id,
        data: updatedProfile,
      })

      if (!response.data) {
        this.showErrorMessage(
          response?.data?.errors?.[0],
          `We couldn't  update employee skills needed.`
        )
      } else if (response.data?._id) {
        if (response.data.errors) {
          this.showErrorMessage(
            response?.data?.errors?.[0],
            `Employee skills updated, but with errors. Please check to make sure it is complete.`
          )
        }
      } else {
        // Default case
        this.showErrorMessage(
          response?.data?.errors?.[0],
          `We couldn't update employee skills needed.`
        )
      }
      this.disabled = false
    },
  },
}
</script>

<style global lang="scss"></style>
