import InnerLayout from "@/layout/InnerLayout"

const navItems = [
  {
    title: "Search Training Opportunities",
    to: "/trainings/search",
  },
  {
    title: "Saved Training Opportunities",
    to: "/trainings/saved",
  },
]

export const trainingsRouter = [
  {
    path: "/trainings",
    redirect: "/trainings/search",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "search",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Trainings/SearchTrainings.vue"
          ),
      },
      {
        path: "saved",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Trainings/SavedTrainings.vue"
          ),
      },
      {
        path: ":trainingId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Trainings/TrainingDetail.vue"
          ),
      },
    ],
  },
]
