export function validateEmail(email) {
  return (
    !!email &&
    /* eslint-disable-next-line no-control-regex */
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    )
  )
}

export function validateUrl(url) {
  const regex =
    /^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/

  return regex.test(url)
}

export function validateUsPhoneNumber(phoneNumber) {
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumber)
}

export function validateZipCode(zip) {
  return /^\d{5}(?:[-\s]\d{4})?$/.test(zip)
}

export function isNullish(value) {
  return value === undefined || value === null
}

export function requiredError(value, secondaryValidator) {
  return value === "" || isNullish(value)
    ? "This is required"
    : secondaryValidator
    ? secondaryValidator
    : null
}

export function isObject(obj) {
  const type = typeof obj
  return (
    !Array.isArray(obj) && (type === "function" || (type === "object" && !!obj))
  )
}

export function deNullifyErrors(errors) {
  return Object.entries(errors).reduce((deNullified, [key, value]) => {
    const deNullifiedValue = isObject(value)
      ? Object.keys(deNullifyErrors(value)).length > 0
        ? deNullifyErrors(value)
        : null
      : value
    return {
      ...deNullified,
      ...(deNullifiedValue ? { [key]: deNullifiedValue } : {}),
    }
  }, {})
}

export function normalize(dataObj) {
  if (!isObject(dataObj)) {
    return dataObj
  }
  return Object.entries(dataObj).reduce((data, [key, value]) => {
    if (
      value === undefined ||
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length < 1)
    )
      return data
    return {
      ...data,
      [key]:
        value === "true"
          ? true
          : value === "false"
          ? false
          : isObject(value)
          ? normalize(value)
          : Array.isArray(value)
          ? value.map((item) => normalize(item))
          : value,
    }
  }, {})
}
