<template>
  <div class="profile-section">
    <profile-section-header
      title="Additional Information"
      @handle-edit-click="handleEditClick"
      :isEditing="isEditing"
    />
    <div v-if="profile.provider || profile.vendor">
      <h3 class="overview-heading mt-4">
        {{
          profile.provider
            ? "Does your company perform work for other general contractors?"
            : profile.vendor
            ? "Does your business provide installation labor services for the products or materials you sell"
            : ""
        }}
      </h3>
      <capsule-list
        :items="trueFalse"
        :selectedItems="[
          profile.provider
            ? profile.provider.workForOtherContractors
            : profile.vendor.providesInstallLaborServices,
        ]"
      />
      <div
        v-if="
          profile.vendor && profile.vendor.providesInstallLaborServices === true
        "
      >
        <h3>And we use:</h3>
        <capsule-list
          :items="laborOptions"
          :selectedItems="[profile.vendor.typeLaborServices]"
        />
      </div>
    </div>
    <h3 class="overview-heading mt-4">
      Do you offer paid training or apprenticeship programs for non-skilled job
      seekers?
    </h3>
    <capsule-list
      :items="trueFalse"
      :selectedItems="[profile.apprenticeship]"
    />
  </div>
</template>

<script>
import ProfileSectionHeader from "../ProfileSectionHeader.vue"
import options from "@/utils/options"
import CapsuleList from "@/components/List/CapsuleList.vue"

export default {
  name: "company-profile-additional",
  components: {
    ProfileSectionHeader,
    CapsuleList,
  },
  data() {
    return {
      trueFalse: options.trueFalse,
      yesNo: options.yesNo,
      laborOptions: options.laborOptions,
      paymentProcessTimeOptions: options.paymentProcessTimeOptions,
      designationsOptions: options.designationsOptions,
      volumeCompletedProjectsOptions: options.volumeCompletedProjectsOptions,
      isFranchise: this.profile.provider
        ? this.profile.provider.isFranchise
        : this.profile.vendor
        ? this.profile.vendor.isFranchise
        : null,
    }
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEditClick() {
      this.$emit("handle-edit-click")
    },
  },
}
</script>

<style lang="scss" scoped></style>
