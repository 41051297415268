import axios from "@/utils/api-v1"

export default {
  async uploadFile(file) {
    const formData = new FormData()
    console.log("File object before sending ", file)
    formData.append("files", file)
    return await axios.post(`/api/files/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  },
  async getFileUrl({ id }) {
    return await axios.get(`/api/files/url/${id}`)
    /*
      name,
      version,
      profileId,
      */
    //)
  },
  async getFileContents({ id }) {
    return await axios.get(`/api/files/get/${id}`)
  },
  async removeFile({ id, type }) {
    return await axios.delete(`/api/files/${id}/${type}`)
  },
}
