import { loadStripe } from "@stripe/stripe-js"

export default {
  data() {
    return {
      stripe: null,
      elements: null,
    }
  },
  created() {
    this.createStripeInstance()
  },
  methods: {
    async createStripeInstance() {
      const stripe = await loadStripe(
        process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
        //{ stripeAccount: 'acct_1JxDcbJVc2vJw9dF'}
      )
      this.stripe = stripe
    },
    async mountStripeElements(clientSecret) {
      const options = {
        clientSecret,
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
          },
        ],
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: "Inter, sans-serif",
            fontSizeBase: "14px",
            borderRadius: "6px",
            colorPrimary: "#39c571",
            colorBackground: "white",
            colorSuccess: "$39c571",
            colorText: "#8898aa",
            colorDanger: "#f5365c",
            spacingUnit: "4px",
          },
          rules: {
            ".Label": {
              color: "#525f7f",
              fontSize: "1rem",
              fontWeight: 600,
            },
            ".Input": {
              boxShadow: "none",
            },
          },
        },
      }
      const elements = this.stripe.elements(options)
      const paymentElement = elements.create("payment")
      this.elements = elements
      paymentElement.mount("#payment-element")
    },
    async confirmPayment(return_url) {
      const { error, paymentIntent } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url,
        },
        redirect: "if_required",
      })
      return { error, paymentIntent }
    },
    async confirmCardPayment(clientSecret, payment_method) {
      console.log("payment", payment_method)
      return await this.stripe.confirmCardPayment(clientSecret, {
        payment_method,
      })
    },
    async confirmSetup() {
      return await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: "if_required",
      })
    },
  },
}
