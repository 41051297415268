<template>
  <div>
    <div class="form-container">
      <div class="form-content-body">
        <h2 class="font-48-900 text-center dark-text mb-4">
          {{
            status === "requested"
              ? "Reset email sent"
              : status !== "complete"
              ? "Reset Password"
              : "Reset Complete"
          }}
        </h2>

        <div v-if="status === 'requested' || status === 'complete'">
          <div class="d-flex justify-content-center align-items-cente mb-4">
            <div class="large-green-circle d-center-center rounded-circle">
              <i class="fas fa-check"></i>
            </div>
          </div>
        </div>
        <div v-else>
          <form method="POST" @submit.prevent="validate">
            <!-- base-input -->
            <base-input
              v-if="!resetKey"
              label="Email"
              class="input-group-alternative"
              placeholder="email"
              type="email"
              v-model="email"
            />
            <form-error v-show="errors && errors.email">{{
              errors && errors.email
            }}</form-error>
            <template v-if="resetKey">
              <base-input
                label="New password"
                class="input-group-alternative mb-3"
                type="password"
                placeholder="password"
                v-model="password"
              />
              <base-input
                class="input-group-alternative mb-3"
                type="password"
                placeholder="confirm password"
                v-model="confirmPassword"
              >
              </base-input>
              <form-error v-show="errors && errors.password">{{
                errors && errors.password
              }}</form-error>
              <h2>Password Requirements:</h2>
              <ul>
                <li>12 characters long</li>
                <li>One or more upper-case characters</li>
                <li>One or more lower-case characters</li>
                <li>One or more numbers</li>
                <li>
                  One or more special characters (@, $, !,% , *, #, ?, &, etc.)
                </li>
              </ul>
            </template>
            <button
              class="btn btn-dark-white w-100 btn-form go-rounded-14 mb-4"
              :disabled="loading"
              type="submit"
              style="margin-top: 20px"
            >
              Reset password
              <b-spinner small v-if="loading"></b-spinner>
            </button>
          </form>
        </div>

        <!-- router-link -->
        <router-link
          to="/login"
          class="d-block font-16-500 text-center mt-2"
          :class="
            status === 'requested'
              ? 'btn btn-dark-white go-rounded-14 text-white'
              : 'dark-text'
          "
        >
          Back to login
        </router-link>
      </div>
    </div>
    <div class="outside-copyright mt-4 text-center">
      <p class="font-16-500 d-flex justify-content-between align-items-center">
        <span>© 2020, GoFindBuild LLC.</span>
        <span class="d-block text-center">
          <a href="#!">Privacy Policy</a>
          .
          <a href="#!">Terms</a>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import { PASSWORD_MIN_LENGTH } from "@/utils/constants"
import { validateEmail } from "@/utils/validations"
export default {
  name: "resetpassword",
  data() {
    return {
      status: null,
      loading: false,
      errors: null,
      email: "",
      password: "",
      confirmPassword: "",
      resetKey: this.$route.query?.k ?? null,
    }
  },
  methods: {
    validate() {
      const { email, password, confirmPassword } = this
      const errors = this.resetKey
        ? {
            ...(!password
              ? { password: "Please enter a password" }
              : password.length < PASSWORD_MIN_LENGTH
              ? {
                  password: `Please enter a password at least ${PASSWORD_MIN_LENGTH} characters long`,
                }
              : password != confirmPassword
              ? { password: "Passwords do not match" }
              : !/[@$!%*#?&^()[\]{}'"\\/\-_><,.;`~]/g.test(password)
              ? { password: "Password requires at least one special character" }
              : !/[A-Z]/g.test(password)
              ? {
                  password:
                    "Password requires at least one upper-case character",
                }
              : !/[a-z]/g.test(password)
              ? {
                  password:
                    "Password requires at least one lower-case character",
                }
              : !/[\d]/g.test(password)
              ? { password: "Password requires at least one number" }
              : {}),
          }
        : {
            ...(!email
              ? { email: "Please enter your email address" }
              : !validateEmail(email)
              ? { email: "Please enter a valid email address" }
              : {}),
          }
      if (Object.keys(errors).length > 0) {
        this.errors = errors
        return
      }
      this.resetKey ? this.handlePasswordChange() : this.handleResetRequest()
    },
    handleResetRequest() {
      this.loading = true
      const payload = { email: this.email }
      this.$store
        .dispatch("auth/requestPasswordReset", payload)
        .then((response) => {
          this.loading = false
          if (response.data.errors) {
            this.$toast.error(response.data.errors[0])
          } else {
            this.status = "requested"
          }
        })
        .catch((error) => {
          this.loading = false
          this.$$toast.error(error.message || "Something went wrong.")
        })
    },
    async handlePasswordChange() {
      this.loading = true
      try {
        const payload = {
          password: this.password,
          passwordResetKey: this.resetKey,
        }
        await this.$store.dispatch("auth/changeUserPassword", payload)
        this.status = "complete"
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = true
      }
    },
  },
}
</script>
<style></style>
