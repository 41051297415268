<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        You are viewing {{ showMatches ? "matches" : "applicants" }} for
        <span class="orange-clr font-weight-bold">{{ job.name }}</span>
      </span></template
    >
    <template v-slot:actions-right>
      <default-button
        :labelText="'Back to List'"
        @click="$router.push('/employees/jobs')"
      />
    </template>
    <div class="row" v-if="job.matches && job.matches.length && showMatches">
      <div v-for="c in job.matches" :key="c._id" class="col-lg-4">
        <employee-card :seekerId="c._id" :match="true" :jobId="job._id" />
      </div>
    </div>
    <div
      class="row"
      v-else-if="job.applicants && job.applicants.length && showApplicants"
    >
      <div v-for="c in job.applicants" :key="c._id" class="col-lg-4">
        <employee-card :seekerId="c.profile" :match="true" :jobId="job._id" />
      </div>
    </div>
    <div v-else>
      <h2>
        You have no {{ showMatches ? "matches" : "applicants" }} for this
        project
      </h2>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import EmployeeCard from "@/components/Cards/EmployeeCard"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  name: "JobMatches",
  components: {
    EmployeeCard,
    InnerLayoutActionBarWrapper,
  },
  data() {
    return {
      projectName: "",
      job: {},
      showMatches: false,
      showApplicants: false,
    }
  },
  methods: {
    getApplicants() {
      let requests = this.$store.state.userProfile.profilesRequested
      console.log("re", requests)
      for (let r of requests) {
        if (r.type == "job-application" && r.job == this.job._id) {
          if (this.job.applicants) {
            this.job.applicants.push(r)
          } else {
            this.job.applicants = [r]
          }
        }
      }
      requests = this.$store.state.userProfile.profilesGranted
      console.log("re", requests)
      for (let r of requests) {
        if (r.type == "job-application" && r.job == this.job._id) {
          if (this.job.applicants) {
            this.job.applicants.push(r)
          } else {
            this.job.applicants = [r]
          }
        }
      }
      requests = this.$store.state.userProfile.profilesRejected
      console.log("re", requests)
      for (let r of requests) {
        if (r.type == "job-application" && r.job == this.job._id) {
          if (this.job.applicants) {
            this.job.applicants.push(r)
          } else {
            this.job.applicants = [r]
          }
        }
      }
      //remove duplicates
      this.job.applicants = this.job.applicants.filter(
        (v, i, a) => a.findIndex((t) => t.profile == v.profile) === i
      )
      //this.projects = projects;
    },
    async getJobById() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          const data = await this.$store.dispatch("jobs/dispatchFetchJobById", {
            id: this.$route.params.jobId,
          })
          if (data.error) {
            this.$router.push("/dashboard")
            return
          }
          this.job = data

          if (this.showApplicants) {
            this.getApplicants()
          }
        } catch (error) {
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    goToCompanyProfile(id) {
      this.$router.push("/profile/" + id)
    },
  },
  mounted() {
    if (this.$route.name == "jobMatches") {
      this.showMatches = true
    } else {
      this.showApplicants = true
    }
    this.getJobById()
  },
}
</script>

<style scoped></style>
