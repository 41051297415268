<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Post or search for an upcoming project or task and quickly find trade
        partners to review and connect with
      </span></template
    >
    <profile></profile>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  components: {
    InnerLayoutActionBarWrapper,
  },
}
</script>

<style scoped></style>
