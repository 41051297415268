<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        You are viewing matches for
        <span class="orange-clr font-weight-bold">{{ project.name }}</span>
      </span></template
    >
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="'Back to List'"
        @click="$router.push('/trade-partners/projects')"
      />
      <DefaultButton
        :labelText="'Delete Project'"
        @click="handleDeleteProject"
      />
    </template>
    <div class="row" v-if="project.matches && project.matches.length">
      <div v-for="c in project.matches" :key="c._id" class="col-lg-4">
        <company-card
          :companyId="c.profile"
          :projectId="$router.currentRoute.params.projectId"
          :match="true"
        />
      </div>
    </div>
    <div v-else>
      <h2>You have no matches for this project</h2>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import CompanyCard from "@/components/Cards/CompanyCard"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"

export default {
  name: "ProjectMatches",
  components: {
    CompanyCard,
    InnerLayoutActionBarWrapper,
    DefaultButton,
  },
  data() {
    return {
      projectName: "",
      project: {},
    }
  },
  methods: {
    handleDeleteProject() {
      console.log("TODO: Delete Project")
    },
    getMatches() {
      let requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        if (r.type == "project" && r.project == this.project._id) {
          if (this.project.matches && this.project.matches.length) {
            this.project.matches.push(r)
          } else {
            this.project.matches = [r]
          }
        }
      }
      requests = this.$store.state.userProfile.profilesGranted
      for (let r of requests) {
        if (r.type == "project" && r.project == this.project._id) {
          if (this.project.matches && this.project.matches.length) {
            this.project.matches.push(r)
          } else {
            this.project.matches = [r]
          }
        }
      }
      //this.projects = projects;
    },
    async getProject() {
      await this.$store
        .dispatch("projects/dispatchFetchProjects")
        .finally(() => (this.loading = false))
      let projects = this.$store.state.projects.list
      let projectId = this.$router.currentRoute.params.projectId
      for (let p of projects) {
        if (p._id == projectId) {
          this.project = p
          this.getMatches()
          break
        }
      }
    },
  },
  mounted() {
    this.getProject()
  },
}
</script>

<style scoped></style>
