/* eslint-disable no-unused-vars */
import jwt from "@/api/jwt.js"
import profileApi from "@/api/profile.js"
import user from "@/api/user.js"
import companyApi from "@/api/company.js"
import router from "@/router"

export default {
  registerUser({ dispatch }, payload) {},
  async requestPasswordReset(_, payload) {
    return await user.requestPasswordReset(payload.email)
  },
  async changeUserPassword(_, payload) {
    return await user.changeUserPassword(
      payload.passwordResetKey,
      payload.password
    )
  },
  // JWT
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(async (response) => {
          const { accessToken } = response.data ?? {}
          const user = response.data?.userData
          // If there's user data in response
          if (user) {
            // Set accessToken
            commit("SET_TOKEN", { accessToken }, { root: true })
            localStorage.setItem("GFB_ACCESS_TOKEN", accessToken)

            commit(
              "UPDATE_USER_INFO",
              {
                ...user,
                profilePhoto:
                  user.profilePhoto ||
                  require("@/assets/images/portrait/small/sample-avatar.jpg"),
              },
              {
                root: true,
              }
            )

            // Set bearer token in axios
            commit("SET_BEARER", accessToken)

            resolve(response)

            // Navigate User to homepage

            const { data } = await profileApi.checkUserProfileExists(user._id)
            const { data: companyData } =
              await companyApi.checkUserProfileExists(user._id)

            const profile = data?.profile ?? {}
            const companyProfile = companyData?.profile ?? {}

            const profileExists = profile || companyProfile

            const profileType = localStorage.getItem("profileType")

            if (profileExists) {
              const { data } = profile
                ? await profileApi.getUserProfile(user._id)
                : await companyApi.getUserProfile(user._id)
              if (data) {
                commit("UPDATE_USER_PROFILE", data, { root: true })

                let userProfile = data

                if (profileType == "provider") {
                  userProfile.lastActivity = new Date()
                  userProfile.affiliations = userProfile.affiliations
                    ? userProfile.affiliations
                        .filter(Boolean)
                        .map((affiliation) =>
                          typeof affiliation === "string"
                            ? affiliation
                            : affiliation.name
                        )
                    : []
                  companyApi.updateProfile(userProfile._id, { ...userProfile })
                } else {
                  userProfile.lastActivity = new Date()
                  profileApi.updateProfile(userProfile._id, { ...userProfile })
                }
              }
            }

            const redirectPath =
              router.currentRoute.query.redirect || "/dashboard"

            const to =
              user.role == "support"
                ? "admin/dashboard"
                : router.currentRoute.query.to || profileExists
                ? redirectPath
                : {
                    path: "complete-profile",
                    ...(profileType ? { query: { profileType } } : {}),
                  }

            router.push(to)
          } else {
            reject({ message: "Wrong Email or Password" })
          }
        })
        .catch((error) => {
          //reject(error);
          reject({ message: "Wrong Email or Password" })
          //console.log(error);
        })
    })
  },
  registerUserJWT({ commit }, payload) {
    const { displayName, email, password, confirmPassword } =
      payload.userDetails

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({
          message: "Password doesn't match. Please try again.",
        })
      }

      jwt
        .registerUser(displayName, email, password)
        .then((response) => {
          const { accessToken, refreshToken, userData } = response?.data ?? {}

          commit("SET_TOKEN", { accessToken }, { root: true })
          commit("UPDATE_USER_INFO", userData, {
            root: true,
          })

          resolve(response)

          // Redirect User
          router.push(router.currentRoute.query.to || "/")
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  registerUserEmail({ commit }, payload) {
    const { email } = payload.userDetails

    return new Promise((resolve, reject) => {
      user
        .registerUser(email)
        .then((response) => {
          // show message to user
          resolve(response)
          // Redirect User
          //router.push(router.currentRoute.query.to || '/')
        })
        .catch((error) => {
          reject(error)
          //console.log(error);
          //reject({message: 'Email address not available'})
        })
    })
  },
  async confirmUserEmail({ commit }, payload) {
    const { emailConfirmationKey, password } = payload.userDetails
    try {
      const { data } = await user.confirmUserEmail(
        emailConfirmationKey,
        password
      )
      return data
    } catch (error) {
      console.error(error)
      throw new Error("We couldn't confirm your account.")
    }
  },
  refreshToken({ commit }) {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        const { accessToken } = response.data
        if (accessToken) {
          localStorage.setItem("GFB_ACCESS_TOKEN", accessToken)
          commit("SET_TOKEN", { accessToken }, { root: true })

          const profileType = localStorage.getItem("profileType")
          let userProfile = JSON.parse(localStorage.getItem("userProfile"))
          if (profileType == "provider") {
            userProfile.lastActivity = new Date()
            userProfile.affiliations = userProfile.affiliations
              ? userProfile.affiliations
                  .filter(Boolean)
                  .map((affiliation) =>
                    typeof affiliation === "string"
                      ? affiliation
                      : affiliation.name
                  )
              : []
            companyApi.updateProfile(userProfile._id, { ...userProfile })
          } else {
            userProfile.lastActivity = new Date()
            profileApi.updateProfile(userProfile._id, { ...userProfile })
          }
        }
        resolve(response)
      })
    })
  },
  async revokeToken({ commit }) {
    await jwt.revokeToken()
    commit("SET_TOKEN", { accessToken: null }, { root: true })
    commit("CLEAR_USER_INFO", {}, { root: true })
    commit("CLEAR_USER_PROFILE", {}, { root: true })
    return
  },
}
