// import auth from '@/auth/authService'

export default {
  isUserLoggedIn: () => {
    let isAuthenticated = false

    const authUser = localStorage.getItem("userInfo")

    if (!authUser) {
      isAuthenticated = false
    } else {
      isAuthenticated = true
    }

    return isAuthenticated
  },
}
