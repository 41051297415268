import axios from "@/utils/api-v1"

export default {
  getProjects(params) {
    return axios.get("/api/projects", params)
  },

  getProjectDetails(id) {
    return axios.get(`/api/projects/${id}`)
  },

  postProject(payload) {
    return axios.post("/api/projects", payload)
  },

  updateProject(id, payload) {
    return axios.patch(`/api/projects/${id}`, payload)
  },

  deleteProject(id) {
    return axios.delete(`/api/projects/${id}`)
  },

  filterProjects(type, params) {
    return axios.get(`/api/projects/category/${type}`, { params })
  },
  getByLocation(zip, params) {
    return axios.post(`/api/projects/location/${zip}`, params)
  },
  searchProjectsByParams(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/project/search`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
}
