<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left><div></div></template>
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="'Back to Search'"
        @click="$router.push('/trade-partners/search-projects')"
      />
      <DefaultButton
        :labelText="`I'm Interested`"
        @click="handleProjectApplication"
      />
    </template>
    <div class="w-50 py-4 mx-auto">
      <view-project v-if="project" :project="project" />
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { getProjectDetails } from "@/api/projects"
import ViewProject from "@/components/Project/View"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    ViewProject,
    DefaultButton,
  },
  data() {
    return {
      project: null,
    }
  },
  created() {
    this.getProjectDetails()
  },
  methods: {
    handleProjectApplication() {
      console.log("TODO: Apply for Project")
    },
    async getProject() {
      const projectId = this.$router.currentRoute.params?.projectId
      if (projectId) {
        const { data } = await getProjectDetails(projectId)
        if (!data || data.errors) {
          this.showErrorMessage(
            data.errors[1],
            "We couldn't find data for this project."
          )
        } else {
          this.project = data
        }
      }
    },
  },
}
</script>

<style scoped></style>
