<template>
    <div>
      <div class="toast-title">
        <span>{{ heading }}</span>
      </div>
      <p class="toast-desc" v-if="description && description.length">{{ description ? description :'' }}</p>
    </div>
  </template>
  <script>
  import { PhCheckCircle, PhInfo, PhWarning } from "phosphor-vue" 
  import ToastMessage from "@/components/ToastMessage"

  export default {
      props: ["heading", "description"],
      name:'toast-message',
      components:{ToastMessage},
      methods: {
          showToast(heading, variant, description, position) {
              const content = {
                  component: ToastMessage,
                  props: {
                      heading: heading,
                      description: description ? description :''
                  }
              };

              // Render the toast and its contents
              this.$toast(content, {
                  position: position ? position : "top-right",
                  icon: variant == 'error' ? <PhWarning
                      color="#E24646"
                      weight="bold"
                      size="32"
                  />:(variant == 'info' ? <PhInfo
                      color="#677690"
                      weight="bold"
                      size="32"
                  />: <PhCheckCircle
                      color="#1CD47E"
                      weight="bold"
                      size="32"
                  />),
                  toastClassName: variant,
              });
          },
      },
  };
  </script>
  <style lang="scss">
  .toast-title, .Vue-Toastification__toast-body {
    color: var(--Gray-900, #343946);
    font-family: Inter;
    font-weight: 600;
    line-height: 140%;
  }

  .Vue-Toastification__toast-body {
    margin-right: 50px;
  }

  

  .Vue-Toastification__toast--success {
    border-left: 8px solid var(--Green-500, #1CD47E) !important;

    svg {
        color: var(--Green-500, #1CD47E)
    }
  }

  .Vue-Toastification__toast--error {
    border-left: 8px solid var(--Red-500, #E24646) !important;

    svg {
        color: var(--Red-500, #E24646)
    }
  }

  .Vue-Toastification__toast--warning {
    border-left: 8px solid var(--Gray-500, #677690) !important;
    
    svg {
        color: var(--Gray-500, #677690)
    }
  }

  .toast-desc {
    color: var(--Gray-800, #3A4252);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-top: 5px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .Vue-Toastification__toast {
    display: flex !important;
    max-width: 480px !important;
    padding: 16px !important;
    align-items: flex-start !important;
    gap: 12px !important;
    border-radius: 9px !important;
    background: var(--White, #FFF) !important;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20) !important; 
    display: flex !important;
    align-items: center !important;

    &.success {
        border-left: 8px solid var(--Green-500, #1CD47E) !important;
    }

    &.error {
        border-left: 8px solid var(--Red-500, #E24646) !important;
    }

    &.info {
        border-left: 8px solid var(--Gray-500, #677690) !important;
    }
  }

  .Vue-Toastification__toast-component-body{
    margin-left: 10px !important;
    margin-right: 65px !important;
    max-width: 70% !important;
  }

  .Vue-Toastification__icon {
    margin: auto !important;
    width: 24px !important;
  }

  .Vue-Toastification__close-button {
    color: #B0B9C9 !important;
    opacity: 1 !important;
    font-weight: 100 !important;
    font-size: 40px !important;
    line-height: 16px !important;
    position: absolute !important;
    top: 24px !important;
    right: 16px !important;

    &:focus {
        outline: none !important;
    }
  }
  </style>
  