<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "base-card",
}
</script>

<style scoped>
.card {
  padding: 1.3rem;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.11);
  border-radius: 22px;
  margin: 0 0 0.98rem;
}
</style>
