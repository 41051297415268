/* eslint-disable no-unused-vars */
import * as common from "@/api/common.js"
import user from "@/api/user.js"
import account from "@/api/account.js"
import candidate from "@/api/candidate.js"
import file from "@/api/file.js"
import payments from "@/api/payments.js"
import profile from "@/api/profile.js"
import projects from "@/api/projects.js"
import jobs from "@/api/jobs.js"
import company from "@/api/company.js"
import activityLog from "@/api/activityLog.js"

// import router from '@/router'
// import { resolve } from 'core-js/fn/promise'

export default {
  async getZipLocation(_, { zip }) {
    return await common.getZipLocation(zip)
  },
  async getExpirationHours(_) {
    return await common.getExpirationHours()
  },
  getUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      user
        .getUser(payload.userId)
        .then((response) => {
          // show message to user
          resolve(response)
          // Redirect User
          //router.push(router.currentRoute.query.to || '/')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      account
        .createAccount(payload)
        .then((response) => {
          // show message to user
          resolve(response)
          // Redirect User
          //router.push(router.currentRoute.query.to || '/')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createCandidate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      candidate
        .createCandidate(
          payload.userId,
          payload.firstName,
          payload.lastName,
          payload.zip,
          payload.desiredPosition,
          payload.priorSkilledTradeFormalTraining,
          payload.tradeCategories
        )
        .then((response) => {
          // show message to user
          resolve(response)
          // Redirect User
          //router.push(router.currentRoute.query.to || '/')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .createProfile(payload.userId, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createCompanyProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .createProfile(payload.userId, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getProfile(_, { profileId }) {
    return await profile.getProfile(profileId)
  },
  async getCompanyProfile(_, { profileId }) {
    console.log(profileId)
    return await company.getProfile(profileId)
  },
  async getRequestedProfiles(_) {
    return await profile.getRequestedProfiles()
  },
  async getGrantedProfiles(_) {
    return await profile.getGrantedProfiles()
  },
  checkUserProfileExists({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .checkUserProfileExists(payload.userId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkCompanyProfileExists({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .checkUserProfileExists(payload.userId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getProfileCompleted(_, { profileId }) {
    return await profile.getProfileCompleted(profileId)
  },
  async getCompanyProfileCompleted(_, { profileId }) {
    return await company.getProfileCompleted(profileId)
  },
  async getProjectDetails(_, { projectId }) {
    return await projects.getProjectDetails(projectId)
  },
  async getCompanyUserProfile({ commit }, { userId }) {
    const response = await company.getUserProfile(userId)
    let profilePhoto
    if (response.data && !response.data.errors) {
      if (
        response.data.profilePhoto &&
        typeof response.data.profilePhoto === "object"
      ) {
        const f = response.data.profilePhoto
        const url = await file.getFileUrl({ id: f.id })
        response.data.profilePhotoUrl = url.data.url
      }
      commit("UPDATE_USER_PROFILE", response.data, { root: true })
    }
    return response
  },
  async getUserProfile({ commit }, { userId }) {
    const response = await profile.getUserProfile(userId)
    let profilePhoto
    if (response.data && !response.data.errors) {
      if (
        response.data.profilePhoto &&
        typeof response.data.profilePhoto === "object"
      ) {
        const f = response.data.profilePhoto
        const url = await file.getFileUrl({ id: f.id })
        response.data.profilePhotoUrl = url.data.url
      }
      commit("UPDATE_USER_PROFILE", response.data, { root: true })
    }
    return response
  },
  async sendSupport(_, payload) {
    return await profile.sendSupport(payload.profileId, payload.data)
  },
  async sendCompanySupport(_, payload) {
    return await company.sendSupport(payload.profileId, payload.data)
  },
  updateProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .updateProfile(payload.profileId, payload.data)
        .then(async (response) => {
          if (response.data && !response.data.errors) {
            if (
              response.data.profilePhoto &&
              typeof response.data.profilePhoto === "object"
            ) {
              const f = response.data.profilePhoto
              const url = await file.getFileUrl({
                id: f.id,
              })
              response.data.profilePhotoUrl = url.data.url
            }
            commit("UPDATE_USER_PROFILE", response.data, { root: true })
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCompanyProfile({ commit }, payload) {
    payload.data.affiliations = payload.data.affiliations
      ? payload.data.affiliations
          .filter(Boolean)
          .map((affiliation) =>
            typeof affiliation === "string" ? affiliation : affiliation.name
          )
      : []
    return new Promise((resolve, reject) => {
      company
        .updateProfile(payload.profileId, payload.data)
        .then(async (response) => {
          if (response.data && !response.data.errors) {
            if (
              response.data.profilePhoto &&
              typeof response.data.profilePhoto === "object"
            ) {
              const f = response.data.profilePhoto
              const url = await file.getFileUrl({
                id: f.id,
              })
              response.data.profilePhotoUrl = url.data.url
            }
            commit("UPDATE_USER_PROFILE", response.data, { root: true })
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  findUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .findUsers(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchCompaniesByParams({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .searchUsersByParams(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchUsersByParams({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .searchUsersByParams(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchJobsByParams({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jobs
        .searchJobsByParams(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchProjectsByZip({ commit }, payload) {
    return new Promise((resolve, reject) => {
      projects
        .getByLocation(payload.zip, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          reject(error)
        })
    })
  },
  postJob({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jobs
        .postJob(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getJobsByType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jobs
        .getJobsByType(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async uploadFile(_, payload) {
    const formData = new FormData()
    Object.entries(payload).forEach(([key, value]) =>
      formData.append(key, value)
    )
    return await file.uploadFile(formData)
  },
  async getFileUrl(_, { name, version, profileId, id }) {
    return await file.getFileUrl({ name, version, profileId, id })
  },
  async getFileContents(_, { id }) {
    return await file.getFileContents({ id })
  },
  async getPaymentIntent(_, { id }) {
    return await payments.getPaymentIntent(id)
  },
  async createPaymentIntent(_, data) {
    return await payments.createPaymentIntent(data)
  },
  async createPayment(_, data) {
    return await payments.insertPayment(data)
  },
  async createSetupIntent(_) {
    return await payments.createSetupIntent()
  },
  async getProductBySlug(_, { slug }) {
    return await payments.getProductBySlug(slug)
  },
  async getPaymentMethods(_) {
    return await payments.getPaymentMethods()
  },
  async getPaymentHistory(_) {
    return await payments.getPaymentHistory()
  },
  async setDefaultPaymentMethod(_, { payment_method }) {
    return await payments.setDefaultPaymentMethod({ payment_method })
  },
  async deletePaymentMethod(_, payment_method) {
    return await payments.deletePaymentMethod(payment_method)
  },
  async sendJobBriefing({ commit }, payload) {
    const { company, jobBriefing, userId } = payload
    try {
      const { data } = await profile.sendJobBriefing(
        company,
        jobBriefing,
        userId
      )
      return data
    } catch (error) {
      console.error(error)
      throw new Error("We couldn't send your job briefing.")
    }
  },
  getJobSeekers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .getJobSeekers(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCompanies({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .getCompanies(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getOpportunities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .getOpportunities(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getAppliedJobs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .getAppliedJobs(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getJobMatches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .getJobMatches(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchJobsByCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jobs
        .searchJobsByCompany(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getSuccessCenterItems({ commit }, payload) {
    return new Promise((resolve, reject) => {
      profile
        .getSuccessCenterItems(payload.profileId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getCompanySuccessCenterItems({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .getSuccessCenterItems(payload.profileId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getCompanyStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .getStats()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getCompanyActivities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .getActivities()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createLog({ commit }, payload) {
    return new Promise((resolve, reject) => {
      activityLog
        .createLog(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getCompanyMatches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      company
        .getCompanyMatches(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActivityLogs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      activityLog
        .getLogs(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getJobseekerActions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jobs
        .getJobseekerActions(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
