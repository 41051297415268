import InnerLayout from "@/layout/InnerLayout"

const navItems = [
  {
    title: "Activity",
    to: "/my-jobs/activities",
  },
  {
    title: "Saved Jobs",
    to: "/my-jobs/saved",
  },
]

export const myJobsRouter = [
  {
    path: "/my-jobs",
    redirect: "/my-jobs",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "activities",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/Activities.vue"
          ),
      },
      {
        path: "saved",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/SavedJobs.vue"
          ),
      },
    ],
  },
]
