<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1000 636"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g
      id="图层-1"
      serif:id="图层 1"
      transform="matrix(1,0,0,1,216.336,117.369)"
    >
      <g transform="matrix(11.6801,0,0,11.6801,-20.4809,513.401)">
        <path
          d="M0,-53.556L21.368,-53.556C24.351,-53.556 26.208,-51.124 25.512,-48.129L15.562,-5.418C14.859,-2.432 11.874,0 8.89,0L-12.479,0C-15.463,0 -17.318,-2.432 -16.622,-5.418L-6.667,-48.129C-5.973,-51.124 -2.99,-53.556 0,-53.556"
          style="fill: rgb(237, 23, 31); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,208.344,513.401)">
        <path
          d="M0,-53.556L24.575,-53.556C27.557,-53.556 26.212,-51.124 25.511,-48.129L15.562,-5.418C14.867,-2.432 15.085,0 12.095,0L-12.478,0C-15.465,0 -17.315,-2.432 -16.613,-5.418L-6.668,-48.129C-5.965,-51.124 -2.983,-53.556 0,-53.556"
          style="fill: rgb(8, 47, 103); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,484.03,513.401)">
        <path
          d="M0,-53.556L21.365,-53.556C24.35,-53.556 26.205,-51.124 25.506,-48.129L15.558,-5.418C14.859,-2.432 11.875,0 8.881,0L-12.478,0C-15.468,0 -17.32,-2.432 -16.619,-5.418L-6.674,-48.129C-5.974,-51.124 -2.991,-53.556 0,-53.556"
          style="fill: rgb(0, 106, 101); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,524.864,363.51)">
        <path
          d="M0,-9.184L-2.604,-0.567L-1.904,-0.567L-2.445,1.215L-3.145,1.215L-3.308,1.768L-5.786,1.768L-5.618,1.215L-10.655,1.215L-10.149,-0.434L-9.64,-0.434L-6.998,-9.184L-6.47,-10.952L-3.942,-10.952L-4.206,-10.058C-4.206,-10.058 -3.533,-10.544 -2.894,-10.71C-2.255,-10.878 1.409,-10.939 1.409,-10.939L0.866,-9.184L0,-9.184ZM-4.455,-9.184L-5.124,-6.982C-5.124,-6.982 -4.376,-7.319 -3.965,-7.432C-3.551,-7.539 -2.929,-7.581 -2.929,-7.581L-2.445,-9.184L-4.455,-9.184ZM-5.456,-5.887L-6.148,-3.597C-6.148,-3.597 -5.383,-3.989 -4.972,-4.111C-4.558,-4.207 -3.932,-4.285 -3.932,-4.285L-3.441,-5.887L-5.456,-5.887ZM-7.068,-0.542L-5.053,-0.542L-4.471,-2.478L-6.486,-2.478L-7.068,-0.542Z"
          style="fill: white"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,537.922,270.875)">
        <path
          d="M0,-3.008L2.698,-3.008L2.728,-2.002C2.712,-1.833 2.858,-1.753 3.17,-1.753L3.718,-1.753L3.217,-0.093L1.761,-0.093C0.497,0 0.02,-0.543 0.048,-1.152L0,-3.008Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,542.057,331.775)">
        <path
          d="M0,-0.345L-2.572,-0.345L-2.13,-1.825L0.809,-1.825L1.23,-3.174L-1.674,-3.174L-1.175,-4.841L6.904,-4.841L6.398,-3.174L3.689,-3.174L3.265,-1.825L5.98,-1.825L5.533,-0.345L2.593,-0.345L2.073,0.278L3.265,0.278L3.554,2.141C3.585,2.324 3.591,2.445 3.649,2.525C3.709,2.594 4.066,2.626 4.273,2.626L4.635,2.626L4.085,4.439L3.168,4.439C3.026,4.439 2.816,4.427 2.529,4.412C2.251,4.388 2.054,4.227 1.87,4.139C1.702,4.055 1.45,3.851 1.392,3.504L1.104,1.645L-0.23,3.479C-0.655,4.055 -1.229,4.496 -2.197,4.496L-4.071,4.496L-3.58,2.879L-2.865,2.879C-2.658,2.879 -2.475,2.798 -2.341,2.728C-2.203,2.67 -2.082,2.601 -1.944,2.397L0,-0.345Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,213.016,384.289)">
        <path
          d="M0,-8.889L6.81,-8.889L6.307,-7.263L3.583,-7.263L3.16,-5.88L5.946,-5.88L5.438,-4.201L2.654,-4.201L1.979,-1.948C1.896,-1.698 2.637,-1.663 2.905,-1.663L4.301,-1.856L3.742,0L0.6,0C0.353,0 0.16,-0.035 -0.114,-0.097C-0.379,-0.163 -0.496,-0.281 -0.613,-0.461C-0.727,-0.646 -0.901,-0.796 -0.783,-1.188L0.118,-4.172L-1.428,-4.172L-0.915,-5.88L0.64,-5.88L1.053,-7.263L-0.496,-7.263L0,-8.889Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,266.581,265.958)">
        <path
          d="M0,-1.678L2.793,-1.678L2.282,0.022L-1.534,0.022L-1.949,0.379C-2.125,0.552 -2.187,0.479 -2.418,0.604C-2.633,0.714 -3.088,0.929 -3.675,0.929L-4.9,0.929L-4.402,-0.706L-4.035,-0.706C-3.724,-0.706 -3.513,-0.732 -3.406,-0.801C-3.284,-0.877 -3.149,-1.05 -2.992,-1.332L-2.292,-2.607L0.487,-2.607L0,-1.678Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,313.22,384.184)">
        <path
          d="M0,-12.709L2.38,-12.709L2.034,-11.512C2.034,-11.512 2.784,-12.111 3.311,-12.325C3.835,-12.522 5.022,-12.698 5.022,-12.698L8.872,-12.718L7.56,-8.349C7.34,-7.599 7.083,-7.115 6.922,-6.895C6.775,-6.669 6.605,-6.48 6.268,-6.297C5.944,-6.124 5.649,-6.024 5.377,-5.996C5.122,-5.977 4.732,-5.967 4.19,-5.962L0.483,-5.962L-0.564,-2.504C-0.663,-2.163 -0.711,-2.001 -0.646,-1.909C-0.591,-1.825 -0.465,-1.732 -0.291,-1.732L1.344,-1.886L0.787,0.009L-1.047,0.009C-1.629,0.009 -2.053,-0.003 -2.351,-0.026C-2.633,-0.048 -2.928,-0.026 -3.123,-0.179C-3.296,-0.329 -3.555,-0.525 -3.549,-0.728C-3.53,-0.916 -3.454,-1.228 -3.333,-1.654L0,-12.709ZM5.053,-8.294L1.151,-8.294L0.913,-7.506L4.289,-7.506C4.688,-7.556 4.77,-7.499 4.802,-7.518L5.053,-8.294ZM1.368,-9.005C1.368,-9.005 2.129,-9.701 3.434,-9.927C3.731,-9.985 5.587,-9.966 5.587,-9.966L5.868,-10.9L1.936,-10.9L1.368,-9.005Z"
          style="fill: white"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,394.257,383.88)">
        <path
          d="M0,-5.63L-0.218,-4.58C-0.315,-4.254 -0.396,-4.003 -0.644,-3.793C-0.901,-3.574 -1.209,-3.343 -1.924,-3.343L-3.242,-3.29L-3.253,-2.101C-3.271,-1.763 -3.18,-1.799 -3.126,-1.744C-3.065,-1.687 -3.012,-1.66 -2.951,-1.641L-2.536,-1.66L-1.274,-1.734L-1.794,0L-3.242,0C-4.257,0 -5.009,-0.022 -5.257,-0.22C-5.499,-0.373 -5.531,-0.569 -5.524,-0.9L-5.433,-5.525L-3.124,-5.525L-3.153,-4.58L-2.596,-4.58C-2.406,-4.58 -2.281,-4.6 -2.199,-4.649C-2.132,-4.704 -2.079,-4.776 -2.042,-4.893L-1.817,-5.63L0,-5.63Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,9.04641,198.202)">
        <path
          d="M0,-12.925C-0.084,-12.553 -1.565,-5.709 -1.571,-5.705C-1.892,-4.322 -2.119,-3.33 -2.909,-2.697C-3.357,-2.329 -3.881,-2.148 -4.486,-2.148C-5.458,-2.148 -6.029,-2.631 -6.126,-3.55L-6.144,-3.864C-6.144,-3.864 -5.848,-5.721 -5.844,-5.728C-5.844,-5.728 -4.289,-11.966 -4.01,-12.787C-3.998,-12.833 -3.992,-12.856 -3.986,-12.88C-7.016,-12.853 -7.555,-12.88 -7.59,-12.925C-7.607,-12.864 -7.688,-12.476 -7.688,-12.476L-9.278,-5.452L-9.416,-4.852L-9.676,-2.904C-9.676,-2.329 -9.562,-1.855 -9.337,-1.456C-8.612,-0.188 -6.545,0 -5.379,0C-3.872,0 -2.463,-0.322 -1.509,-0.902C0.146,-1.882 0.582,-3.416 0.969,-4.772L1.147,-5.467C1.147,-5.467 2.752,-11.944 3.026,-12.787C3.034,-12.833 3.039,-12.856 3.05,-12.88C0.855,-12.856 0.209,-12.88 0,-12.925"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,112.637,108.393)">
        <path
          d="M0,7.62C-1.076,7.605 -1.453,7.605 -2.71,7.669L-2.759,7.576C-2.648,7.098 -2.529,6.627 -2.423,6.142L-2.269,5.489C-2.036,4.47 -1.808,3.286 -1.781,2.929C-1.759,2.711 -1.687,2.169 -2.306,2.169C-2.568,2.169 -2.84,2.297 -3.114,2.422C-3.266,2.964 -3.573,4.482 -3.714,5.177C-4.027,6.637 -4.047,6.802 -4.186,7.525L-4.276,7.62C-5.385,7.605 -5.768,7.605 -7.043,7.669L-7.103,7.563C-6.889,6.695 -6.677,5.82 -6.473,4.954C-5.939,2.603 -5.813,1.702 -5.664,0.507L-5.564,0.436C-4.321,0.262 -4.02,0.228 -2.675,-0.049L-2.561,0.078L-2.764,0.827C-2.537,0.693 -2.322,0.556 -2.087,0.436C-1.453,0.124 -0.747,0.032 -0.358,0.032C0.229,0.032 0.873,0.197 1.143,0.878C1.399,1.485 1.23,2.235 0.894,3.709L0.72,4.458C0.375,6.099 0.319,6.399 0.126,7.525L0,7.62Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,163.621,127.373)">
        <path
          d="M0,5.995C-0.646,5.992 -1.062,5.98 -1.47,5.992C-1.874,5.995 -2.269,6.016 -2.87,6.044L-2.906,5.992L-2.946,5.938C-2.782,5.316 -2.689,5.098 -2.609,4.877C-2.527,4.658 -2.447,4.436 -2.305,3.805C-2.117,2.983 -2.001,2.407 -1.918,1.904C-1.832,1.415 -1.783,1.001 -1.722,0.52L-1.672,0.482L-1.618,0.436C-0.975,0.345 -0.564,0.285 -0.148,0.221C0.274,0.16 0.699,0.077 1.366,-0.049L1.392,0.009L1.41,0.068C1.286,0.577 1.16,1.086 1.039,1.604C0.92,2.119 0.794,2.625 0.682,3.138C0.438,4.221 0.343,4.623 0.285,4.912C0.232,5.188 0.214,5.338 0.118,5.9L0.06,5.951L0,5.995Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,276.381,154.389)">
        <path
          d="M0,-0.017C0.377,-1.67 0.088,-2.443 -0.281,-2.916C-0.844,-3.63 -1.83,-3.861 -2.857,-3.861C-3.472,-3.861 -4.941,-3.798 -6.09,-2.738C-6.91,-1.977 -7.295,-0.939 -7.526,0.052C-7.757,1.061 -8.024,2.887 -6.349,3.564C-5.829,3.782 -5.089,3.844 -4.607,3.844C-3.38,3.844 -2.122,3.505 -1.18,2.503C-0.445,1.688 -0.114,0.478 0,-0.017M-2.809,-0.137C-2.868,0.14 -3.11,1.178 -3.443,1.619C-3.673,1.943 -3.946,2.138 -4.248,2.138C-4.333,2.138 -4.864,2.138 -4.874,1.35C-4.88,0.962 -4.799,0.558 -4.702,0.128C-4.419,-1.124 -4.085,-2.174 -3.234,-2.174C-2.564,-2.174 -2.52,-1.393 -2.809,-0.137"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,583.276,110.612)">
        <path
          d="M0,7.541C-1.296,7.529 -1.669,7.529 -2.867,7.58L-2.948,7.483C-2.62,6.25 -2.292,5.021 -2.003,3.775C-1.628,2.158 -1.542,1.471 -1.416,0.525L-1.319,0.445C-0.033,0.26 0.325,0.209 1.667,-0.039L1.709,0.071C1.463,1.085 1.217,2.1 0.983,3.118C0.488,5.251 0.308,6.335 0.118,7.449L0,7.541Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,590.482,153.303)">
        <path
          d="M0,0.221C0.377,-1.424 -1.139,0.076 -1.382,-0.467C-1.754,-1.313 -1.521,-3.03 -3.015,-3.603C-3.588,-3.826 -4.938,-3.537 -6.09,-2.484C-6.905,-1.726 -7.293,-0.701 -7.522,0.289C-7.754,1.284 -8.02,3.106 -6.354,3.76C-5.828,3.983 -5.345,4.047 -4.865,4.028C-3.189,3.937 -1.911,1.409 -0.968,0.406C-0.239,-0.386 -0.111,0.704 0,0.221M-2.564,0.098C-2.627,0.362 -2.87,1.412 -3.203,1.846C-3.423,2.157 -3.947,2.354 -4.247,2.354C-4.332,2.354 -4.859,2.354 -4.878,1.573C-4.882,1.189 -4.801,0.785 -4.701,0.351C-4.418,-0.874 -4.084,-1.92 -3.233,-1.92C-2.562,-1.92 -2.276,-1.158 -2.564,0.098"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,362.125,108.393)">
        <path
          d="M0,7.62C-1.078,7.605 -1.443,7.605 -2.71,7.669L-2.755,7.576C-2.648,7.098 -2.526,6.627 -2.414,6.142L-2.263,5.489C-2.029,4.47 -1.802,3.286 -1.776,2.929C-1.755,2.711 -1.683,2.169 -2.297,2.169C-2.563,2.169 -2.832,2.297 -3.108,2.422C-3.258,2.964 -3.566,4.482 -3.714,5.177C-4.021,6.637 -4.042,6.802 -4.181,7.525L-4.273,7.62C-5.381,7.605 -5.761,7.605 -7.033,7.669L-7.091,7.563C-6.879,6.695 -6.671,5.82 -6.467,4.954C-5.934,2.603 -5.807,1.702 -5.659,0.507L-5.559,0.436C-4.316,0.262 -4.014,0.228 -2.669,-0.049L-2.563,0.078L-2.758,0.827C-2.537,0.693 -2.312,0.556 -2.083,0.436C-1.453,0.124 -0.741,0.032 -0.36,0.032C0.229,0.032 0.874,0.197 1.151,0.878C1.404,1.485 1.233,2.235 0.894,3.709L0.725,4.458C0.373,6.099 0.319,6.399 0.133,7.525L0,7.62Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,484.485,150.827)">
        <path
          d="M0,-4.865C-0.19,-4.013 -0.743,-3.29 -1.458,-2.941C-2.049,-2.644 -2.768,-2.622 -3.512,-2.622L-3.991,-2.622L-3.955,-2.813C-3.955,-2.813 -3.068,-6.664 -3.072,-6.652L-3.046,-6.849L-3.03,-7.001L-2.676,-6.964C-2.676,-6.964 -0.854,-6.806 -0.808,-6.801C-0.087,-6.527 0.207,-5.803 0,-4.865M-1.164,-8.881C-1.169,-8.881 -2.057,-8.869 -2.057,-8.869C-4.38,-8.843 -5.311,-8.854 -5.693,-8.9C-5.733,-8.73 -5.791,-8.428 -5.791,-8.428L-6.621,-4.566C-6.621,-4.566 -8.61,3.637 -8.705,4.022C-6.678,4 -5.846,4 -5.499,4.035C-5.42,3.65 -4.946,1.359 -4.946,1.359C-4.946,1.359 -4.541,-0.324 -4.517,-0.386C-4.517,-0.386 -4.392,-0.558 -4.263,-0.627L-4.078,-0.627C-2.331,-0.627 -0.353,-0.627 1.193,-1.768C2.244,-2.549 2.969,-3.702 3.288,-5.101C3.374,-5.445 3.434,-5.857 3.434,-6.265C3.434,-6.801 3.326,-7.332 3.012,-7.748C2.224,-8.854 0.656,-8.869 -1.164,-8.881"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,707.307,236.594)">
        <path
          d="M0,-10.836L-0.103,-10.951C-1.427,-10.686 -1.669,-10.64 -2.889,-10.479L-2.981,-10.391C-2.985,-10.376 -2.989,-10.352 -2.992,-10.333L-2.998,-10.352C-3.906,-8.253 -3.877,-8.707 -4.618,-7.059C-4.623,-7.137 -4.624,-7.186 -4.626,-7.264L-4.81,-10.836L-4.927,-10.951C-6.313,-10.686 -6.35,-10.64 -7.633,-10.479L-7.736,-10.391C-7.745,-10.344 -7.748,-10.298 -7.755,-10.248L-7.745,-10.229C-7.585,-9.406 -7.622,-9.591 -7.463,-8.3C-7.388,-7.666 -7.29,-7.024 -7.215,-6.399C-7.087,-5.349 -7.017,-4.83 -6.865,-3.232C-7.728,-1.798 -7.937,-1.257 -8.762,0.001L-8.718,0.115C-7.463,0.068 -7.174,0.068 -6.246,0.068L-6.041,-0.161C-5.34,-1.672 0,-10.836 0,-10.836"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(5.91822,-10.0697,-10.0697,-5.91822,177.637,105.672)">
        <path
          d="M-1.096,-0.623C-0.311,-0.982 0.325,-0.714 0.327,-0.017C0.327,0.688 -0.306,1.544 -1.096,1.91C-1.882,2.284 -2.514,2.004 -2.518,1.305C-2.514,0.609 -1.887,-0.256 -1.096,-0.623"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(11.6801,0,0,11.6801,635.649,269.579)">
        <path
          d="M0,-2.859L-1.044,-1.072C-1.374,-0.462 -1.987,-0.003 -2.961,0L-4.624,-0.027L-4.14,-1.638L-3.818,-1.638C-3.647,-1.638 -3.522,-1.649 -3.426,-1.696C-3.344,-1.722 -3.283,-1.787 -3.215,-1.883L-2.602,-2.859L0,-2.859Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
