import state from "./moduleApiState.js"
import mutations from "./moduleApiMutations.js"
import actions from "./moduleApiActions.js"
import getters from "./moduleApiGetters.js"

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
