<template>
  <div class="my-4">
    <ProjectDetailHeader :project="project" />

    <ProfileSubSectionHeader
      title="Project Description"
      icon="description-icon"
    />
    <p>{{ project.description }}</p>

    <ProfileSubSectionHeader title="Type of Project" icon="description-icon" />
    <p>{{ project.type }}</p>

    <ProfileSubSectionHeader title="Project Location" icon="description-icon" />
    <p>{{ project.zip }}</p>

    <ProfileSubSectionHeader title="Project Start" icon="description-icon" />
    <p>{{ new Date(project.startDate).toDateString() }}</p>

    <ProfileSubSectionHeader title="Invite Vendors?" icon="description-icon" />
    <p>{{ project.inviteVendorsToView ? "Yes" : "No" }}</p>

    <div v-if="project.addedDetail">
      <ProfileSubSectionHeader
        title="Additional Detail"
        icon="description-icon"
      />
      <p>
        {{ project.addedDetail }}
      </p>
    </div>

    <p>
      <ProfileSubSectionHeader
        title="Trade Categories"
        icon="trade-experience-icon"
      />
      <capsule-list
        :items="project.contractorTradeCategories"
        :highlighted="true"
      />
    </p>

    <p v-if="project.vendorTradeCategories.length">
      <ProfileSubSectionHeader
        title="Vendor Categories:"
        icon="trade-experience-icon"
      />
      <capsule-list
        :items="project.vendorTradeCategories"
        :highlighted="true"
      />
    </p>
  </div>
</template>

<script>
import ProfileSubSectionHeader from "@/components/Profile/ProfileSubSectionHeader"
import ProjectDetailHeader from "@/components/Project/ProjectDetailHeader"

export default {
  props: {
    project: Object,
  },
  components: {
    ProfileSubSectionHeader,
    ProjectDetailHeader,
  },
}
</script>

<style></style>
