<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1" v-if="currentView === 'edit'">
        Search available job seekers or post an available job and attract
        multiple candidates to respond directly to your opportunity
      </span>
      <DefaultButton
        v-if="currentView === 'review'"
        :labelText="`Back`"
        @click="setCurrentView('edit')"
        :disabled="formLoading"
      />
    </template>
    <template v-slot:actions-right>
      <div class="d-flex align-items-center">
        <DefaultButton
          v-if="currentView === 'edit'"
          :labelText="`Cancel`"
          @click="$router.push(cancelButtonTo)"
        />
        <span class="ml-auto">
          <!--span v-if="currentView === 'review'" class="font-14-400 mr-4"
            >This posting will be active for 30 days</span
          -->
          <!--base-button
            class="rounded-lg"
            type="success"
            @click="$refs.jobForm.validate()"
            :loading="formLoading"
          >
            {{ currentView === "review" ? "Publish" : "Next" }}
          </base-button-->
        </span>
      </div>
    </template>
    <job-form
      v-if="!jobLoading"
      :job="job"
      :loading="formLoading"
      :currentView="currentView"
      :cancelButtonTo="cancelButtonTo"
      @set-current-view="setCurrentView"
      @set-loading="setLoading"
      ref="jobForm"
    />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import job from "@/api/jobs.js"
import JobForm from "@/components/Job/JobForm"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    JobForm,
    DefaultButton,
  },
  data() {
    const jobId = this.$router.currentRoute.params?.jobId
    const cancelButtonTo = jobId
      ? `/employees/jobs/${jobId}`
      : "/employees/jobs"
    return {
      job: null,
      jobLoading: true,
      currentView: "edit",
      formLoading: false,
      cancelButtonTo,
    }
  },
  created() {
    this.getJob()
  },
  methods: {
    async getJob() {
      const jobId = this.$router.currentRoute.params?.jobId
      if (jobId) {
        const { data } = await job.getJobDetails(jobId)
        if (data) {
          this.job = data
        }
      }
      this.jobLoading = false
    },
    setLoading(loading) {
      this.formLoading = loading
    },
    setCurrentView(currentView) {
      this.currentView = currentView
    },
  },
}
</script>

<style scoped></style>
