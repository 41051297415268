<template>
  <DefaultButton :labelText="buttonText" @click="showMsgBox" />
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
export default {
  components: {
    DefaultButton,
  },
  props: {
    buttonText: String,
    title: String,
    description: String,
  },
  data: () => ({
    isOpen: false,
    boxTwo: "t",
  }),
  methods: {
    showMsgBox() {
      this.boxTwo = ""
      this.$bvModal
        .msgBoxConfirm(this.description, {
          title: this.title,
          size: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((selection) => {
          if (selection) {
            this.$emit("onConfirm")
          }
        })
    },
  },
}
</script>

<style></style>
