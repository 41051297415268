<template>
  <p class="font-16-400 m-0">Update your profile to add details</p>
</template>

<script>
export default {
  name: "empty-profile-item-message",
}
</script>

<style lang="scss" scoped></style>
