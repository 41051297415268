<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Post or search for an upcoming project or task and quickly find trade
        partners to review and connect with
      </span></template
    >
    <div class="project-view py-4 mx-auto">
      <project-form v-if="step === 0" :project="project" :errors="errors" />
      <view-project v-if="step === 1" :project="project" />
      <div class="d-flex justify-space-between">
        <DefaultButton
          v-if="step === 1"
          :labelText="'Back'"
          @click="step = 0"
          :disabled="loading"
        />
        <DefaultButton
          v-if="step === 0"
          :labelText="'Cancel'"
          @click="$router.push('/trade-partners/projects')"
          :disabled="loading"
        />
        <DefaultButton
          :labelText="step === 1 ? 'Publish' : 'Next'"
          :btnClass="['right-end']"
          @click="step === 1 ? handleSubmit() : validate()"
          :disabled="loading"
        />
      </div>
      <ToastMessage ref="toastMessage" />
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import projects from "@/api/projects"
import ProjectForm from "@/components/Project/Form"
import ViewProject from "@/components/Project/View"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import { validateZipCode } from "@/utils/validations"
import { API_RESPONSE_ERRORS, ERROR_MESSAGES } from "@/utils/constants"
import DefaultButton from "@/components/DefaultButton"
import ToastMessage from "@/components/ToastMessage"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    ProjectForm,
    ViewProject,
    DefaultButton,
    ToastMessage,
},
  data() {
    const projectId = this.$router.currentRoute.params?.projectId
    const profile = this.$store.state.userProfile

    return {
      step: 0,
      isEditing: !!projectId,
      project: {
        name: "",
        type: "",
        description: "",
        zip: "",
        startDate: "",
        contractorTradeCategories: [],
        vendorTradeCategories: [],
        inviteVendorsToView: null,
        addedDetail: "",
        company: profile?._id,
      },
      loading: false,
      errors: [],
    }
  },
  computed: {
    isValid() {
      const {
        name,
        type,
        description,
        zip,
        startDate,
        contractorTradeCategories,
        inviteVendorsToView,
      } = this.project
      return (
        !!name &&
        !type &&
        !!description &&
        !!zip &&
        !!validateZipCode(zip) &&
        !!startDate &&
        !!contractorTradeCategories &&
        !!(inviteVendorsToView != null)
      )
    },
  },
  created() {
    this.getProject()
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      const createOrUpdate = this.isEditing
        ? projects.updateProject
        : projects.postProject
      try {
        const { data } = await createOrUpdate(
          this.isEditing ? this.project.id : this.project,
          this.isEditing ? this.project : null
        )
        if (data.id) {
          this.$refs.toastMessage.showToast('Project successfully posted','success');
          this.$router.push(`/trade-partners/projects/${data.id}`);
        }
      } catch (error) {
        if (
          error?.response?.data?.error ===
          API_RESPONSE_ERRORS.INVALID_ZIP_CODE_ERROR
        ) {
          this.$toast.error(ERROR_MESSAGES.INVALID_ZIP_CODE_INPUT)
        } else {
          this.$toast.error(error.message || "Something went wrong.")
          console.log(error)
        }
      } finally {
        this.loading = false
      }
    },
    validate() {
      const {
        name,
        type,
        description,
        zip,
        startDate,
        contractorTradeCategories,
        inviteVendorsToView,
      } = this.project

      if (!name) {
        this.errors.push("name")
      } else {
        this.errors = this.errors.filter((e) => e !== "name")
      }

      if (!type) {
        this.errors.push("type")
      } else {
        this.errors = this.errors.filter((e) => e !== "type")
      }

      if (!description) {
        this.errors.push("description")
      } else {
        this.errors = this.errors.filter((e) => e !== "description")
      }

      if (!zip && !validateZipCode(zip)) {
        this.errors.push("zip")
      } else {
        this.errors = this.errors.filter((e) => e !== "zip")
      }

      if (!startDate) {
        this.errors.push("startDate")
      } else {
        this.errors = this.errors.filter((e) => e !== "startDate")
      }
      if (!contractorTradeCategories.length) {
        this.errors.push("contractorTradeCategories")
      } else {
        this.errors = this.errors.filter(
          (e) => e !== "contractorTradeCategories"
        )
      }
      if (!(inviteVendorsToView != null)) {
        this.errors.push("inviteVendorsToView")
      } else {
        this.errors = this.errors.filter((e) => e !== "inviteVendorsToView")
      }

      if (this.errors.length == 0) {
        this.step = 1
      } else {
        setTimeout(() => {
          document.querySelectorAll(".input-error")[0].scrollIntoView(true)
        }, "1000")
      }
    },
    async getProject() {
      const projectId = this.$router.currentRoute.params?.projectId
      if (projectId) {
        const { data } = await projects.getProjectDetails(projectId)
        if (data) {
          const formattedData = {
            ...data,
            startDate: this.$dayjs(data.startDate).format("YYYY-MM-DD"),
          }
          this.project = formattedData
        }
      }
    },
  },
}
</script>

<style scoped>
.project-view {
  width: 80%;
}
</style>
