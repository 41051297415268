<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 78 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="icon"
    role="presentation"
  >
    <title :id="icon" lang="en">{{ icon }} icon</title>
    <g :fill="color">
      <path
        d="M49.5689 56.2763C61.3952 56.2763 71.0166 46.655 71.0166 34.8287C71.0166 23.0024 61.3952 13.3809 49.5689 13.3809C37.7426 13.3809 28.1211 23.0022 28.1211 34.8287C28.1211 46.6551 37.7426 56.2763 49.5689 56.2763V56.2763ZM33.5997 33.6795H30.4544C30.7172 29.2632 32.4831 25.2455 35.2469 22.1314L37.4645 24.3491C37.6888 24.5735 37.983 24.6857 38.2769 24.6857C38.5709 24.6857 38.8651 24.5735 39.0893 24.3491C39.5381 23.9004 39.5381 23.1729 39.0893 22.7243L36.8717 20.5066C39.9857 17.7428 44.0034 15.9769 48.4198 15.7142V18.8595C48.4198 19.494 48.9341 20.0084 49.5688 20.0084C50.2035 20.0084 50.7177 19.494 50.7177 18.8595V15.7142C55.1341 15.9769 59.1518 17.7428 62.2657 20.5066L60.048 22.7243C59.5993 23.173 59.5993 23.9006 60.048 24.3491C60.2724 24.5734 60.5666 24.6857 60.8606 24.6857C61.1545 24.6857 61.4487 24.5735 61.6731 24.3491L63.8906 22.1314C66.6543 25.2455 68.4204 29.263 68.6829 33.6794H65.5377C64.903 33.6794 64.3887 34.1938 64.3887 34.8284C64.3887 35.4629 64.903 35.9773 65.5377 35.9773H68.6829C68.4202 40.3937 66.6543 44.4114 63.8905 47.5253L61.673 45.3078C61.2241 44.8591 60.4967 44.8591 60.048 45.3078C59.5993 45.7565 59.5993 46.484 60.048 46.9326L62.2657 49.1502C59.1517 51.9141 55.1341 53.68 50.7177 53.9427V50.7974C50.7177 50.1629 50.2035 49.6484 49.5688 49.6484C48.9341 49.6484 48.4198 50.1629 48.4198 50.7974V53.9427C44.0033 53.68 39.9857 51.9141 36.8717 49.1502L39.0893 46.9327C39.5381 46.484 39.5381 45.7565 39.0893 45.3079C38.6405 44.8591 37.9131 44.8592 37.4645 45.3079L35.2469 47.5254C32.4831 44.4114 30.7172 40.3938 30.4544 35.9773H33.5997C34.2342 35.9773 34.7487 35.4629 34.7487 34.8284C34.7487 34.194 34.2342 33.6795 33.5997 33.6795V33.6795Z"
      />
      <path
        d="M63.4995 56.9422C59.335 59.5715 54.5176 60.9612 49.568 60.9612C35.158 60.9612 23.4347 49.2378 23.4347 34.8278C23.4347 28.994 25.3144 23.4761 28.8704 18.8707C29.2581 18.3686 29.1654 17.647 28.6631 17.2591C28.1609 16.8714 27.4394 16.964 27.0515 17.4664C23.1819 22.4776 21.1367 28.4811 21.1367 34.8278C21.1367 50.5049 33.8909 63.2591 49.568 63.2591C54.9525 63.2591 60.1943 61.7466 64.7263 58.8852C65.263 58.5465 65.4232 57.8369 65.0845 57.3002C64.7456 56.7637 64.0362 56.6037 63.4995 56.9422Z"
      />
      <path
        d="M56.4973 7.252V2.89663C56.4973 1.70752 55.5299 0.740234 54.3409 0.740234H44.7946C43.6055 0.740234 42.6383 1.70752 42.6383 2.89663V7.25078C38.0692 8.39546 33.8301 10.6664 30.3018 13.9191C29.8353 14.3493 29.8057 15.0761 30.2358 15.5427C30.6659 16.0092 31.3929 16.0389 31.8593 15.6087C36.6959 11.1499 42.9849 8.69435 49.5678 8.69435C63.9778 8.69435 75.7011 20.4177 75.7011 34.8277C75.7011 42.2242 72.5457 49.3064 67.044 54.2581C66.5724 54.6826 66.5341 55.409 66.9587 55.8807C67.1854 56.1329 67.4985 56.2609 67.8129 56.2609C68.0868 56.2609 68.3616 56.1637 68.5813 55.966C74.5664 50.5792 77.9991 42.8745 77.9991 34.8275C77.9992 21.5402 68.8361 10.3539 56.4973 7.252V7.252ZM44.9364 6.77342V3.03818H54.1995V6.77572C52.6918 6.52754 51.1449 6.3964 49.5681 6.3964C48.0044 6.3964 46.4562 6.52386 44.9364 6.77342V6.77342Z"
      />
      <path
        d="M49.569 35.6318C50.0001 35.6318 50.3752 35.3942 50.5717 35.043L54.5352 31.0795C54.9839 30.6308 54.9839 29.9033 54.5352 29.4546C54.0865 29.0059 53.3591 29.0059 52.9101 29.4546L50.7179 31.6467V26.0513C50.7179 25.4168 50.2036 24.9023 49.5689 24.9023C48.9342 24.9023 48.4199 25.4168 48.4199 26.0513V34.4825C48.4201 35.1173 48.9344 35.6318 49.569 35.6318V35.6318Z"
      />
      <path
        d="M16.955 35.0865C16.955 34.4519 16.4406 33.9375 15.8061 33.9375H1.14897C0.514434 33.9375 0 34.4519 0 35.0865C0 35.721 0.514434 36.2355 1.14897 36.2355H15.8061C16.4406 36.2355 16.955 35.7212 16.955 35.0865Z"
      />
      <path
        d="M5.73101 29.546H17.3375C17.972 29.546 18.4865 29.0316 18.4865 28.397C18.4865 27.7625 17.972 27.248 17.3375 27.248H5.73101C5.09647 27.248 4.58203 27.7625 4.58203 28.397C4.58203 29.0316 5.09631 29.546 5.73101 29.546Z"
      />
      <path
        d="M18.8707 20.5547H9.3521C8.71756 20.5547 8.20312 21.0691 8.20312 21.7037C8.20312 22.3382 8.71756 22.8526 9.3521 22.8526H18.8707C19.5052 22.8526 20.0196 22.3382 20.0196 21.7037C20.0196 21.069 19.5052 20.5547 18.8707 20.5547V20.5547Z"
      />
      <path
        d="M18.4863 41.2603C18.4863 40.6258 17.9719 40.1113 17.3373 40.1113H5.73101C5.09647 40.1113 4.58203 40.6258 4.58203 41.2603C4.58203 41.8948 5.09647 42.4093 5.73101 42.4093H17.3375C17.9719 42.4093 18.4863 41.8948 18.4863 41.2603Z"
      />
      <path
        d="M18.8707 46.8027H9.3521C8.71756 46.8027 8.20312 47.3172 8.20312 47.9517C8.20312 48.5863 8.71756 49.1007 9.3521 49.1007H18.8707C19.5052 49.1007 20.0196 48.5863 20.0196 47.9517C20.0196 47.3172 19.5052 46.8027 18.8707 46.8027V46.8027Z"
      />
    </g>
  </svg>
</template>
<script>
import { iconProps } from "./iconProps"
export default {
  props: iconProps,
}
</script>
