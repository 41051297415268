<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground">
      <template slot="links">
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          mobile-breakpoint="1024"
          permanent
        >
          <v-list :class="{ 'mini-list': mini }">
            <v-list-item
              title="Home"
              class="nav-link home-link mini-list-item"
              @click="$router.push('/dashboard')"
              link
            >
              <v-list-item-icon v-if="mini" class="icon-mini">
                <img class="nav-icon" :src="`/img/icons/gfb-logo-mini.svg`" />
                <span class="tooltipText">Home</span>
              </v-list-item-icon>
              <v-list-item-icon v-else class="icon-large">
                <img class="nav-icon" :src="`/img/icons/gfb-logo-large.svg`" />
              </v-list-item-icon>

              <v-list-item-content> </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-btn
            icon
            @click.stop="mini = !mini"
            :class="['toggle-nav', { open: mini }]"
          >
          </v-btn>

          <v-list :class="{ 'mini-list': mini, 'nav-list': true }" dense>
            <v-list-item
              v-for="item in sidebarItems"
              :key="item.title"
              class="nav-link mini-list-item"
              @click="$router.push(item.to)"
              link
            >
              <v-list-item-icon>
                <component size="24" v-bind:is="item.icon"></component>
                <span class="tooltipText" v-if="mini">{{ item.title }}</span>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <div
                  v-if="
                    ((item.title == 'Find employees' ||
                      item.title == 'Find Jobs') &&
                      hasJobBriefingNotification) ||
                    (item.title == 'Find Partners' && hasProjectNotification) ||
                    (item.title == 'Find employees' &&
                      hasJobApplicationNotification)
                  "
                  style="
                    float: right;
                    position: relative;
                    top: -5px;
                    flex: unset;
                  "
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="6.97656"
                      cy="6"
                      r="5"
                      fill="#FA0000"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              title="Support"
              :key="`support`"
              class="nav-link mini-list-item"
              @click="handleSupportModal()"
              link
            >
              <v-list-item-icon>
                <img class="nav-icon" :src="`/img/icons/Support 1 1.svg`" />
                <span class="tooltipText" v-if="mini">Support</span>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Support</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <navbar></navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
    <support-modal v-on:send-support-request="sendSupportRequest">
    </support-modal>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue"
import { FadeTransition } from "vue2-transitions"
import SupportModal from "@/components/Modals/SupportModal"
import {
  PhUser,
  PhHandshake,
  PhHouse,
  PhUsers,
  PhBriefcase,
  PhMagnifyingGlass,
} from "phosphor-vue"

export default {
  components: {
    Navbar,
    FadeTransition,
    SupportModal,
    PhUser,
    PhHandshake,
    PhUsers,
    PhHouse,
    PhBriefcase,
    PhMagnifyingGlass,
  },
  data() {
    const userProfile = this.$store.state.userProfile
    const profileType = userProfile?.jobSeeker ? "jobSeeker" : "company"
    const sidebarItems = [
      {
        title: "Dashboard",
        to: "/dashboard",
        icon: "PhHouse",
      },
      ...(profileType === "jobSeeker"
        ? [
            {
              title: "Profile",
              to: "/toolbox/update-profile",
              icon: "PhUser",
            },
            {
              title: "Find Jobs",
              to: "/jobs/search",
              icon: "PhMagnifyingGlass",
            },
            {
              title: "My Jobs",
              to: "/my-jobs/activities",
              icon: "PhBriefcase",
            },
          ]
        : []),
      ...(profileType === "company"
        ? [
            {
              title: "Company Profile",
              to: "/toolbox/update-profile",
              icon: "PhUser",
            },
            {
              title: "Find Partners",
              to: "/trade-partners/projects",
              icon: "PhHandshake",
            },
            {
              title: "Find employees",
              to: "/employees/jobs",
              icon: "PhUsers",
            },
          ]
        : []),
    ]
    return {
      profileType,
      sidebarItems,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
      drawer: true,
      mini: true,
      hasJobBriefingNotification:
        this.$store.state.userProfile.type == "jobSeeker" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "job" && x.profile != this.$store.state.userProfile._id
        ) > -1,
      hasJobApplicationNotification:
        this.$store.state.userProfile.type == "provider" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) => x.type == "job-application"
        ) > -1,
      hasProjectNotification:
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "project" &&
            x.profile != this.$store.state.userProfile._id
        ) > -1,
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    handleSupportModal() {
      this.$bvModal.show("support-modal")
    },
    async sendSupportRequest(data) {
      try {
        const profile = this.$store.state.userProfile
        const { id, type } = profile
        await this.$store.dispatch(
          `api/${type == "jobSeeker" ? "sendSupport" : "sendCompanySupport"}`,
          {
            data,
            profileId: id,
          }
        )
        this.$bvModal.hide("support-modal")
        this.$toast.success("Support request sent!")
      } catch (e) {
        this.$toast.error("Error sending support request")
      }
    },
  },
}
</script>
<style lang="scss">
.wrapper {
  display: flex;
}

.v-list.mini-list {
  position: absolute !important;
}

.mini-list-item {
  position: relative !important;
  padding: 8px 12px !important;
}

.mini-list-item .tooltipText {
  visibility: hidden;
  width: max-content !important;
  height: auto !important;
  background-color: var(--Gray-800, #3a4252);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 999999;
  top: 14px;
  left: 130%;
  font-size: 14px;
  font-weight: 400;
}

.mini-list-item .tooltipText::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--Gray-800, #3a4252) transparent transparent;
}

.mini-list-item:hover .tooltipText {
  visibility: visible;
}

.nav-list {
  margin-top: 25px !important;
  top: 75px;
}

.v-list-item__icon svg {
  margin: 0 7px !important;
}
</style>
