import axios from "@/utils/api-v1.js"

export default {
  login(email, pwd) {
    return axios.post("/api/auth/login", {
      email,
      password: pwd,
    })
  },
  registerUser(name, email, pwd) {
    return axios.post("/api/users", {
      displayName: name,
      email,
      password: pwd,
    })
  },
  refreshToken() {
    return axios.post("/api/auth/refresh-token")
  },
  revokeToken() {
    return axios.post("api/auth/revoke-token")
  },
}
