import axios from "@/utils/api-v1"

export default {
  async getPaymentIntent(id) {
    return await axios.get(`/api/payment/intent/${id}`)
  },
  async insertPayment(data) {
    return await axios.post(`/api/payment`, data)
  },
  async createPaymentIntent(data) {
    return await axios.post(`/api/payment/intent`, data)
  },
  async createSetupIntent() {
    return await axios.post(`/api/setup/intent`)
  },
  async getProductBySlug(slug) {
    return await axios.get(`/api/payment/products/${slug}`)
  },
  async getPaymentMethods() {
    return await axios.get(`/api/payment/payment-methods`)
  },
  async getPaymentHistory() {
    return await axios.get(`/api/payment/payment-history`)
  },
  async setDefaultPaymentMethod(payment_method) {
    return await axios.post(`/api/payment/payment-methods/set-default`, {
      payment_method,
    })
  },
  async deletePaymentMethod(payment_method) {
    return await axios.post(`/api/payment/payment-methods/delete`, {
      payment_method,
    })
  },
}
