<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 55 35"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M285,38.9C285,38.9 270.2,49.4 243,54.1L285,54.1L285,38.9Z"
        style="fill: rgb(239, 118, 34); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M285.4,19.5L230.7,19.5L230.7,54.5L285.4,54.5L285.4,19.5ZM285,19.9L285,54.1L231.1,54.1L231.1,19.9L285,19.9Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M235.9,30.2L233.2,30.2L233.2,38.1L235.4,38.1C236.4,38.2 237.5,37.8 238.3,37.2C239.2,36.5 239.7,35.4 239.7,34.2C239.7,32 238,30.2 235.9,30.2ZM237.3,36.2C236.7,36.7 235.9,36.9 235.2,36.8L234.8,36.8L234.8,31.5L235.2,31.5C236,31.4 236.7,31.7 237.3,32.1C237.9,32.6 238.2,33.3 238.2,34.1C238.2,35 237.9,35.7 237.3,36.2Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <rect
        x="240.4"
        y="30.2"
        width="1.5"
        height="7.9"
        style="fill: rgb(35, 31, 32)"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M245.7,33.2C244.8,32.9 244.5,32.6 244.5,32.2C244.5,31.8 245,31.3 245.6,31.3C246.1,31.3 246.5,31.5 246.8,31.9L247.6,30.8C247,30.2 246.1,29.9 245.3,29.9C244,29.8 242.9,30.8 242.8,32.1L242.8,32.2C242.8,33.3 243.3,33.9 244.7,34.4C245.1,34.5 245.4,34.7 245.8,34.9C246.1,35.1 246.3,35.4 246.3,35.7C246.3,36.3 245.8,36.8 245.2,36.8L245.1,36.8C244.4,36.8 243.7,36.4 243.4,35.7L242.4,36.7C243,37.7 244,38.2 245.2,38.2C246.6,38.3 247.8,37.2 247.9,35.8L247.9,35.6C248,34.5 247.5,33.9 245.7,33.2Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M248.5,34.2C248.5,36.5 250.3,38.3 252.6,38.3L252.7,38.3C253.4,38.3 254,38.1 254.6,37.8L254.6,36C254.1,36.5 253.5,36.9 252.7,36.9C251.3,36.9 250.1,35.8 250,34.4L250,34.2C250,32.7 251.1,31.5 252.5,31.5L252.6,31.5C253.3,31.5 254.1,31.8 254.5,32.4L254.5,30.5C253.9,30.2 253.3,30 252.6,30C250.4,30 248.5,31.8 248.5,34.2Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M266.9,35.5L264.8,30.2L263.1,30.2L266.4,38.3L267.3,38.3L270.7,30.2L269,30.2L266.9,35.5Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M271.4,38.1L275.8,38.1L275.8,36.8L272.9,36.8L272.9,34.7L275.7,34.7L275.7,33.3L272.9,33.3L272.9,31.6L275.8,31.6L275.8,30.2L271.4,30.2L271.4,38.1Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M281.9,32.5C281.9,31 280.9,30.2 279.1,30.2L276.8,30.2L276.8,38.1L278.3,38.1L278.3,34.9L278.5,34.9L280.6,38.1L282.5,38.1L280,34.8C281.2,34.6 282,33.7 281.9,32.5ZM278.8,33.9L278.3,33.9L278.3,31.5L278.8,31.5C279.8,31.5 280.3,31.9 280.3,32.7C280.3,33.5 279.8,33.9 278.8,33.9Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M283,30.6C283,30.5 282.9,30.4 282.7,30.4L282.5,30.4L282.5,31.1L282.7,31.1L282.7,30.8L282.9,31.1L283.1,31.1L282.9,30.8C282.9,30.8 283,30.7 283,30.6ZM282.7,30.7L282.7,30.5C282.8,30.5 282.8,30.5 282.8,30.6C282.8,30.7 282.8,30.7 282.7,30.7Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <path
        d="M282.7,30.1C282.4,30.1 282.1,30.4 282.1,30.7C282.1,31 282.4,31.3 282.7,31.3C283,31.3 283.3,31 283.3,30.7C283.3,30.4 283.1,30.1 282.7,30.1ZM282.7,31.2C282.4,31.2 282.2,31 282.2,30.7C282.2,30.4 282.4,30.2 282.7,30.2C283,30.2 283.2,30.4 283.2,30.7C283.2,31 283,31.2 282.7,31.2Z"
        style="fill: rgb(35, 31, 32); fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-230.7,-19.5)">
      <g>
        <g>
          <g>
            <g>
              <clipPath id="_clip1">
                <path
                  d="M255.2,34.3C255.2,36.6 257.1,38.5 259.4,38.5C261.7,38.5 263.6,36.6 263.6,34.3C263.6,32 261.7,30.1 259.4,30.1C257,30.1 255.2,32 255.2,34.3"
                />
              </clipPath>
              <g clip-path="url(#_clip1)">
                <path
                  d="M267.3,36L261.1,26.3L251.4,32.5L257.6,42.2L267.3,36Z"
                  style="fill: url(#_Linear2); fill-rule: nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="_Linear2"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-2.89282,-4.52928,4.52928,-2.89282,261.497,37.6108)"
      >
        <stop
          offset="0"
          style="stop-color: rgb(245, 159, 0); stop-opacity: 1"
        />
        <stop
          offset="0.23"
          style="stop-color: rgb(244, 154, 1); stop-opacity: 1"
        />
        <stop
          offset="0.5"
          style="stop-color: rgb(242, 141, 4); stop-opacity: 1"
        />
        <stop
          offset="0.62"
          style="stop-color: rgb(241, 132, 6); stop-opacity: 1"
        />
        <stop
          offset="0.76"
          style="stop-color: rgb(239, 123, 9); stop-opacity: 1"
        />
        <stop
          offset="1"
          style="stop-color: rgb(237, 111, 13); stop-opacity: 1"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
