import axios from "axios"
import router from "@/router"
import store from "@/store/store"

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: process.env.VUE_APP_REQUESTS_TIMEOUT,
})

// Access Token Interceptor
service.interceptors.request.use(
  function (config) {
    const accessToken = store.getters.accessToken
    if (accessToken) {
      config.headers.Authorization = "Bearer " + accessToken
    }
    config.withCredentials = true
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Token Refresh Interceptor
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

service.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.error(
      "Store::Service Interceptor has error",
      error,
      store.getters.accessToken
    )
    const { config: originalRequest, response } = error
    const status = response?.status
    const isAuthError = status === 401 || status === 403
    if (isAuthError || response?.data?.error) {
      if (originalRequest.url.includes("/api/auth/refresh-token")) {
        store.commit("CLEAR_USER_INFO")
        store.commit("CLEAR_USER_PROFILE")
        router.push("/login")
        return Promise.reject(error)
      } else {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch("auth/refreshToken").then(({ data }) => {
            const { accessToken } = data
            isAlreadyFetchingAccessToken = false
            onAccessTokenFetched(accessToken)
          })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token) => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
    }
    return Promise.reject(error)
  }
)

export default service
