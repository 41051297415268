<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left>
      Search for available jobs posted by companies and find your new exciting
      career today!
    </template>
    <b-row class="my-4">
      <b-col sm md="4">
        <base-input placeholder="ZIP Code" type="text" v-model="filter.zip">
        </base-input>
        <span class="zip-error" v-if="zipErr"
          >Please enter a valid zipcode.</span
        >
      </b-col>
      <b-col sm md="4">
        <CustomMultiSelect
          :selectOptions="categoryOptions"
          :selectedList="filter.categories"
          selectLabel="Trade Categories"
          @remove-option="removeCategory"
          @select-option="addCategory"
        ></CustomMultiSelect>
      </b-col>
      <b-col sm md="4">
        <CustomMultiSelect
          :selectOptions="positionOptions"
          :selectedList="filter.positions"
          selectLabel="Position Type"
          @remove-option="removePosition"
          @select-option="addPosition"
        ></CustomMultiSelect>
      </b-col>
    </b-row>
    <b-row style="align-items: center">
      <b-col sm md="8">
        <b-checkbox
          v-model="paidTrainingFirst"
          name="some-radios"
          @change="sortPaidTrainingJobs"
        >
          Display paid training jobs first
        </b-checkbox>
      </b-col>
      <b-col sm md="4" style="display: flex; justify-content: flex-end">
        <DefaultButton :labelText="'Search'" @click="searchJobs" />
      </b-col>
    </b-row>
    <div class="mt-5">
      <div class="row">
        <div
          v-for="job in jobList"
          :key="job._id"
          @click="goToJob(job._id)"
          class="col-lg-4 col-md-6"
        >
          <job-card :job="job" />
        </div>
      </div>
      <div class="row pagination" v-if="jobList.length">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
      <div class="row" v-if="jobList.length == 0">
        <div
          v-if="loadingData"
          class="text-center align-items-center"
          style="margin: auto"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <h2 class="ml-3">No results. Check back next time!</h2>
        </div>
      </div>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import JobCard from "@/components/JobCard"
import options from "@/utils/options"
import DefaultButton from "@/components/DefaultButton"
import CustomMultiSelect from "@/components/CustomMultiSelect"
import { validateZipCode } from "@/utils/validations"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    JobCard,
    DefaultButton,
    CustomMultiSelect,
  },
  data() {
    return {
      loading: false,
      categoryOptions: options.categoryOptions,
      positionOptions: options.positionOptions,
      filter: {
        categories: [],
        positions: [],
        zip: this.$store.state.userProfile.zip,
      },
      jobList: [],
      loadingData: false,
      paidTrainingFirst: false,
      limit: 20,
      currentPage: 0,
      totalRows: 0,
      pageCount: 0,
      zipErr: false,
    }
  },
  methods: {
    async searchJobs() {
      this.loadingData = true
      this.zipErr = false
      this.jobList = []

      if (!validateZipCode(this.filter.zip)) {
        this.zipErr = true
        return
      }

      const profileId = this.$store.state.userProfile?._id
      /*
      this.$store.dispatch("api/updateProfile", {
        profileId,
        data: { lastActivity: new Date() },
      })
      */
      if (profileId) {
        try {
          const data = await this.$store.dispatch("api/searchJobsByParams", {
            zip: this.filter.zip,
            tradeCategories: this.filter.categories,
            positionTypes: this.filter.positions,
            limit: this.limit,
            page: this.currentPage,
            paidTrainingFirst: this.paidTrainingFirst,
          })
          this.jobList = data.data.result
          this.totalRows = data.data.total
          this.pageCount = data.data.pageCount
          this.loadingData = false
        } catch (error) {
          this.loadingData = false
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    removeCategory(option) {
      this.filter.categories = this.filter.categories.filter(
        (category) => category !== option
      )
    },
    addCategory(option) {
      this.filter.categories.push(option)
    },
    removePosition(option) {
      this.filter.positions = this.filter.positions.filter(
        (position) => position !== option
      )
    },
    addPosition(option) {
      this.filter.positions.push(option)
    },
    goToJob(id) {
      //this.$router.push("/jobs/" + id)
      this.$router.push({ name: "jobDetail", params: { jobId: id } })
    },
    sortPaidTrainingJobs() {
      this.searchJobs()
    },
    goToPage(page) {
      this.currentPage = page
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== "jobDetail") {
      localStorage.removeItem("search.jobs.categories")
      localStorage.removeItem("search.jobs.positions")
    }

    next()
  },
  mounted() {
    if (localStorage.getItem("search.jobs.categories")) {
      this.filter.categories = JSON.parse(
        localStorage.getItem("search.jobs.categories")
      )
    }
    if (localStorage.getItem("search.jobs.positions")) {
      this.filter.positions = JSON.parse(
        localStorage.getItem("search.jobs.positions")
      )
    }
    this.searchJobs()
  },
  watch: {
    currentPage: function () {
      this.searchJobs()
    },
    "filter.categories": function () {
      localStorage.setItem(
        "search.jobs.categories",
        JSON.stringify(this.filter.categories)
      )
    },
    "filter.positions": function () {
      localStorage.setItem(
        "search.jobs.positions",
        JSON.stringify(this.filter.positions)
      )
    },
  },
}
</script>

<style scoped lang="scss">
.search-btn .btn {
  border-radius: 14px;
  height: 45px;
  line-height: 45px;
  min-width: 200px;
  padding: 0;
}
</style>
<style>
.multiselect__content-wrapper {
  position: unset;
}

.pagination {
  justify-content: center;
}

.page-item .page-link {
  border-radius: 10px !important;
}

.zip-error {
  color: #ff0000;
  font-size: 14px;
}
</style>
