<template>
  <div v-if="job">
    <JobDetailHeader
      :job="job"
      :alreadyApplied="alreadyApplied"
    ></JobDetailHeader>
    <div v-if="!declineJob">
      <div class="justify-content-center my-5 mx-5">
        <job-view :job="job"></job-view>
      </div>
    </div>
    <div class="container-fluid mt-9" v-if="declineJob">
      <job-decline
        :company="company.name"
        :companyId="company._id"
      ></job-decline>
    </div>
    <inner-layout-action-bar-wrapper v-if="!declineJob">
      <template v-slot:actions-left>
        <span class="mr-1" v-if="job.briefing">
          You are reviewing a job briefing by {{ company.name }}
        </span>
        <DefaultButton
          :labelText="'Go Back'"
          @click="declineJob ? (declineJob = false) : $router.go(-1)"
        />
      </template>

      <template v-slot:actions-right v-if="job.briefing">
        <b-button
          variant="danger"
          @click="handleDeclineJob"
          class="mr-4 rounded-lg"
          >Decline</b-button
        >
        <b-button variant="success" @click="handleAcceptJob" class="rounded-lg"
          >I'm Interested</b-button
        >
      </template>
      <template v-slot:actions-right v-else>
        <DefaultButton :labelText="'Save'" @click="handleSaveJob" />
        <DefaultButton
          :labelText="alreadyApplied ? 'Already Applied' : 'Apply'"
          @click="handleApplyForJob"
          :disabled="alreadyApplied"
        />
      </template>
    </inner-layout-action-bar-wrapper>
  </div>
  <h2 v-else class="m-4 w-100 d-flex justify-center">
    We couldn't find this job in our records.
  </h2>
</template>

<script>
import moment from "moment"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import JobDecline from "@/components/Job/JobDecline"
import profile from "@/api/profile"
import DefaultButton from "@/components/DefaultButton"
import JobView from "@/components/Job/JobView"
import JobDetailHeader from "@/components/Job/JobDetailHeader"

export default {
  name: "JobDetail",
  components: {
    InnerLayoutActionBarWrapper,
    JobDecline,
    DefaultButton,
    JobView,
    JobDetailHeader,
  },
  data() {
    return {
      job:
        this.$store.state.jobs.list.find(
          ({ _id }) => _id === this.$route.params.jobId
        ) || {},
      company: {},
      createdAt: "",
      declineJob: false,
      alreadyApplied: false,
    }
  },
  computed: {
    save() {
      return this.$store.state.userProfile.savedJobs.includes(this.job._id)
        ? "Remove"
        : "Save"
    },
  },
  methods: {
    async getJobById() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          const data = await this.$store.dispatch("jobs/dispatchFetchJobById", {
            id: this.$route.params.jobId,
          })
          if (data.error) {
            this.$router.push("/dashboard")
            return
          }
          this.job = data
          this.company = data.profile[0]
          const profilesRequested = this.company.profilesRequested
          const alreadyApplied = profilesRequested.findIndex((request) => {
            return (
              request.type === "job-application" &&
              request.job === this.job._id &&
              request.profile === profileId
            )
          })
          this.alreadyApplied = alreadyApplied < 0 ? false : true
        } catch (error) {
          console.log("error", error)
          this.logDevError(error)
        }
      }
      this.createdAt = moment(this.job.createdAt).fromNow()
    },
    async handleSaveJob() {
      try {
        let userProfile = JSON.parse(
          JSON.stringify(this.$store.state.userProfile)
        )
        if (userProfile.savedJobs) {
          if (userProfile.savedJobs.includes(this.job._id)) {
            let index = userProfile.savedJobs.indexOf(this.job._id)
            userProfile.savedJobs.splice(index, 1)
          } else {
            userProfile.savedJobs.push(this.job._id)
          }
        } else {
          userProfile.savedJobs = [this.job._id]
        }
        await this.$store.dispatch("api/updateProfile", {
          profileId: userProfile._id,
          data: { ...userProfile },
        })
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
    async handleApplyForJob() {
      let recipientProfileId = this.company._id
      let id = this.$router.currentRoute.params?.jobId
      let type = "job-application"
      let data = await profile.requestProfileAccess(
        recipientProfileId,
        id,
        type
      )
      console.log("DATA", data)
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.$router.push("/jobs/" + this.job._id + "/success")
    },
  },
  mounted() {
    this.getJobById()
  },
}
</script>

<style scoped>
.save-btn,
.apply-btn,
.profile-btn {
  width: 190px;
}
.job-title {
  font-weight: 900;
  font-size: 32px;
}
</style>
