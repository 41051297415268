var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"mobile-breakpoint":"1024","permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{class:{ 'mini-list': _vm.mini }},[_c('v-list-item',{staticClass:"nav-link home-link mini-list-item",attrs:{"title":"Home","link":""},on:{"click":function($event){return _vm.$router.push('/dashboard')}}},[(_vm.mini)?_c('v-list-item-icon',{staticClass:"icon-mini"},[_c('img',{staticClass:"nav-icon",attrs:{"src":"/img/icons/gfb-logo-mini.svg"}}),_c('span',{staticClass:"tooltipText"},[_vm._v("Home")])]):_c('v-list-item-icon',{staticClass:"icon-large"},[_c('img',{staticClass:"nav-icon",attrs:{"src":"/img/icons/gfb-logo-large.svg"}})]),_c('v-list-item-content')],1)],1),_c('v-btn',{class:['toggle-nav', { open: _vm.mini }],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-list',{class:{ 'mini-list': _vm.mini, 'nav-list': true },attrs:{"dense":""}},_vm._l((_vm.sidebarItems),function(item){return _c('v-list-item',{key:item.title,staticClass:"nav-link",attrs:{"title":item.title,"link":""},on:{"click":function($event){return _vm.$router.push(item.to)}}},[_c('v-list-item-icon',[_c('i',{class:item.icon})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),(
                  ((item.title == 'Find employees' ||
                    item.title == 'Search Jobs') &&
                    _vm.hasJobBriefingNotification) ||
                  (item.title == 'Find Partners' && _vm.hasProjectNotification) ||
                  (item.title == 'Find employees' &&
                    _vm.hasJobApplicationNotification)
                )?_c('div',{staticStyle:{"float":"right","position":"relative","top":"-5px","flex":"unset"}},[_c('svg',{attrs:{"width":"13","height":"12","viewBox":"0 0 13 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"6.97656","cy":"6","r":"5","fill":"#FA0000","stroke":"white","stroke-width":"2"}})])]):_vm._e()],1)],1)}),1)],1)],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('AdminNavbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }