<template>
  <div class="row project-details">
    <div class="contact-info-wrapper">
      <div class="contact-info">
        <div class="profile-img">
          <img src="/img/icons/mask-group.svg" alt="" />
        </div>
        <div class="contact-info-details">
          <h3>
            {{ project.name }}
          </h3>
          <span v-if="project.company">{{ project.company.name }}</span>
          <span>{{ project.type }}</span>
          <span v-if="project.city && project.state"
            ><PhMapPin weight="fill" size="24px" />{{ project.city }},
            {{ project.state }}</span
          >
          <span>
            <icon
              icon="calendar"
              width="20"
              height="20"
              color="black"
              class="mr-2"
            />Start Date:
            {{ new Date(project.startDate).toDateString() }}
          </span>
        </div>
      </div>
    </div>
    <div class="action-btns"><slot name="action-btns"></slot></div>
  </div>
</template>

<script>
import { PhMapPin } from "phosphor-vue"

export default {
  components: {
    PhMapPin,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.project-details {
  display: flex;
  padding: 24px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--Gray-50, #f6f7f9);
  margin: 0;
  margin-bottom: 30px;
  position: relative;

  .contact-info-wrapper {
    display: flex;
    width: 100%;

    .contact-info {
      display: flex;
      width: 100%;
      align-items: center;

      .profile-img {
        width: 104px;
        height: 104px;
        fill: var(--Gray-100, #eceef2);
        border-radius: 50%;
        margin-right: 32px;

        img {
          border-radius: 50%;
        }
      }

      .contact-info-details {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 6px;
          font-size: 24px;
          font-weight: 700;
        }

        span {
          margin-top: 12px;
          font-size: 14px;
          display: flex;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .action-btns {
    display: flex;
    position: absolute;
    right: 24px;
    top: 24px;
  }
}
</style>
