<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 512 512"
    role="presentation"
  >
    <g :fill="color">
      <path />
    </g>
  </svg>
</template>
<script>
import { iconProps } from "./iconProps"
export default {
  props: iconProps,
}
</script>
