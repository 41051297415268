import Badge from "../components/Badge"
import BaseAlert from "../components/BaseAlert"
import BaseButton from "../components/BaseButton"
import BaseCard from "../components/BaseCard"
import BaseCheckbox from "../components/BaseCheckbox"
import BaseInput from "../components/BaseInput"
import BaseDatePicker from "../components/BaseDatePicker"
import BaseDropdown from "../components/BaseDropdown"
import BaseModal from "../components/Modal/BaseModal"
import BaseNav from "../components/BaseNav"
import BasePagination from "../components/BasePagination"
import BaseProgress from "../components/BaseProgress"
import BaseRadio from "../components/BaseRadio"
import BaseSlider from "../components/BaseSlider"
import BaseSwitch from "../components/BaseSwitch"
import BaseTable from "../components/BaseTable"
import BaseHeader from "../components/BaseHeader"
import FormError from "../components/FormError"
import EditableParagraph from "../components/EditableParagraph"
import LoadingSpinner from "../components/LoadingSpinner"
import CloseButton from "../components/Button/CloseButton"
import EditButton from "../components/Button/EditButton"
import Card from "../components/Card"
import StatsCard from "../components/StatsCard"
import Icon from "../components/Icons/Icon"
import CapsuleList from "../components/List/CapsuleList"
import CheckedList from "../components/List/CheckedList"
import PhoneInput from "../components/PhoneInput"
import Profile from "../components/Profile/Profile"
import SignedImage from "../components/SignedImage"
import SignedFileDownloadButton from "../components/SignedFileDownloadButton"
import TabPane from "../components/Tabs/TabPane"
import Tabs from "../components/Tabs/Tabs"
import YearlyDatePicker from "../components/YearlyDatePicker"

export default {
  install(Vue) {
    Vue.component(Badge.name, Badge)
    Vue.component(BaseAlert.name, BaseAlert)
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseCard.name, BaseCard)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseDatePicker.name, BaseDatePicker)
    Vue.component(BaseModal.name, BaseModal)
    Vue.component(BasePagination.name, BasePagination)
    Vue.component(BaseProgress.name, BaseProgress)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseSlider.name, BaseSlider)
    Vue.component(BaseSwitch.name, BaseSwitch)
    Vue.component(BaseTable.name, BaseTable)
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(EditableParagraph.name, EditableParagraph)
    Vue.component(LoadingSpinner.name, LoadingSpinner)
    Vue.component(CloseButton.name, CloseButton)
    Vue.component(EditButton.name, EditButton)
    Vue.component(FormError.name, FormError)
    Vue.component(Card.name, Card)
    Vue.component(StatsCard.name, StatsCard)
    Vue.component(Icon.name, Icon)
    Vue.component(CapsuleList.name, CapsuleList)
    Vue.component(CheckedList.name, CheckedList)
    Vue.component(PhoneInput.name, PhoneInput)
    Vue.component(Profile.name, Profile)
    Vue.component(SignedImage.name, SignedImage)
    Vue.component(SignedFileDownloadButton.name, SignedFileDownloadButton)
    Vue.component(TabPane.name, TabPane)
    Vue.component(Tabs.name, Tabs)
    Vue.component(YearlyDatePicker.name, YearlyDatePicker)
  },
}
