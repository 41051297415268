<template>
  <div class="card">
    <div class="card-header">
      <div class="left">
        <signed-image
          v-if="
            company &&
            company.profilePhoto &&
            typeof company.profilePhoto === 'object'
          "
          :file="company.profilePhoto"
          :alt="company.name"
        />
        <img
          v-else
          class="default-img"
          src="/img/icons/default-company.svg"
          alt=""
        />
        <div class="job-title title-wrapper">
          <span class="company-name">{{ company.name }}</span>
          <span class="job-position"> {{ name }}</span>
        </div>
      </div>
      <div class="right">
        <PhBookmarkSimple
          color="#B0B9C9"
          size="24px"
          v-if="!save"
          @click.stop="saveUnsave()"
        />
        <PhBookmarkSimple
          weight="fill"
          size="24px"
          color="#4779c9"
          @click.stop="saveUnsave()"
          v-else
        />
      </div>
    </div>
    <div class="card-details">
      <div class="job-location">
        <PhMapPin weight="fill" size="32px" color="#E24646" /><span
          class="location"
          >{{ location || "" }}</span
        >
      </div>
      <div class="other-details">
        <span class="amount" v-if="job.compensation"
          >${{ job.compensation }}</span
        >
        <capsule-list :items="[type]" :highlighted="true" />
      </div>
    </div>
    <div class="description">
      {{ job.description ? job.description : "No description provided." }}
    </div>
    <span class="posted"> Posted {{ postDate }} </span>
  </div>
</template>

<script>
import moment from "moment"
import { PhMapPin, PhBookmarkSimple } from "phosphor-vue"

export default {
  name: "JobCard",
  props: {
    job: Object,
  },
  components: {
    PhMapPin,
    PhBookmarkSimple,
  },
  data() {
    return {
      name: this.job.name,
      location: this.job.city
        ? this.job.city + ", " + this.job.state
        : this.job.state,
      type: this.job.type,
      postDate: moment(this.job.createdAt).fromNow(),
      company: this.job.profile[0],
    }
  },
  computed: {
    save() {
      return this.$store.state.userProfile.savedJobs.includes(this.job._id)
    },
  },
  methods: {
    async saveUnsave() {
      try {
        let userProfile = JSON.parse(
          JSON.stringify(this.$store.state.userProfile)
        )
        if (userProfile.savedJobs) {
          if (userProfile.savedJobs.includes(this.job._id)) {
            let index = userProfile.savedJobs.indexOf(this.job._id)
            userProfile.savedJobs.splice(index, 1)
          } else {
            userProfile.savedJobs.push(this.job._id)
          }
        } else {
          userProfile.savedJobs = [this.job._id]
        }
        await this.$store.dispatch("api/updateProfile", {
          profileId: userProfile._id,
          data: { ...userProfile },
        })
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
    async getCompanyById() {
      try {
        const data = await this.$store.dispatch("api/getCompanyProfile", {
          profileId: this.job.company,
        })
        this.company = data.data
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
  },
  mounted() {
    if (this.company.profilePhoto) {
      this.getCompanyById()
    }
  },
}
</script>

<style scoped style="scss">
.title-wrapper {
  align-items: unset !important;
}
</style>
