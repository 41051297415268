<template>
  <div class="my-4" v-if="job">
    <div v-if="job.name && isPreview">
      <ProfileSubSectionHeader title="Job Title" icon="description-icon" />
      <p>{{ job.name }}</p>
    </div>

    <div v-if="job.zip && isPreview">
      <ProfileSubSectionHeader title="Zip Code" icon="description-icon" />
      <p>{{ job.zip }}</p>
    </div>

    <div v-if="job.description">
      <ProfileSubSectionHeader
        title="Job Description"
        icon="description-icon"
      />
      <p>{{ job.description }}</p>
    </div>

    <div>
      <ProfileSubSectionHeader
        title="Type of Position"
        icon="description-icon"
      />
      <capsule-list :items="[job.type]" :highlighted="true" />
    </div>

    <div v-if="job.tradeCategories">
      <ProfileSubSectionHeader
        title="Service Trade Categories"
        icon="trade-experience-icon"
      />
      <capsule-list :items="job.tradeCategories" :highlighted="true" />
    </div>

    <div v-if="job.qualifications && job.qualifications.length">
      <ProfileSubSectionHeader
        title="Job Qualifications"
        icon="qualifications-icon"
      />
      <capsule-list :items="job.qualifications" :highlighted="true" />
    </div>

    <div v-if="job.compensation">
      <ProfileSubSectionHeader title="Compensation" icon="compensation-icon" />
      <capsule-list :items="[job.compensation]" :highlighted="true" />
    </div>

    <div v-if="job.benefits && job.benefits.length" class="mb-4">
      <ProfileSubSectionHeader title="Benefits" icon="benefits-icon" />
      <capsule-list :items="job.benefits" :highlighted="true" />
    </div>

    <div v-if="job.whyWorkHere" class="mb-4">
      <ProfileSubSectionHeader title="Why Work Here" icon="description-icon" />
      <p>{{ job.whyWorkHere }}</p>
    </div>

    <div v-if="job.experienceRequired" class="d-flex align-items-center mt-2">
      <b> This job requires experience </b>
    </div>
    <div class="d-flex align-items-center mt-4" v-else>
      <b> This job does not require experience </b>
    </div>

    <div
      v-if="job.willingToProvidePaidTraining"
      class="d-flex align-items-center mt-4"
    >
      <icon
        icon="person-to-person"
        width="20"
        height="20"
        color="black"
        class="mr-3"
      />This company is willing to provide paid training
    </div>
  </div>
</template>

<script>
import messagesMixin from "@/mixins/messagesMixin"
import ProfileSubSectionHeader from "@/components/Profile/ProfileSubSectionHeader"
export default {
  mixins: [messagesMixin],
  components: { ProfileSubSectionHeader },
  props: {
    job: Object,
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      profile: this.$store.state.userProfile,
      city: null,
      state: null,
    }
  },
  created() {
    this.getZipLocation()
  },
  methods: {
    async getZipLocation() {
      if (this.job.zip) {
        try {
          const { data } = await this.$store.dispatch("api/getZipLocation", {
            zip: this.job.zip,
          })
          if (!data) {
            throw new Error("No data returned")
          } else if (data.errors) {
            throw new Error(data.errors[0]?.message ?? "Empty error")
          } else {
            this.city = data.city
            this.state = data.state
          }
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
  },
}
</script>

<style></style>
