import Vue from "vue"
import Vuex from "vuex"

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleAuth from "./auth/moduleAuth.js"
import moduleApi from "./api/moduleApi.js"
import projects from "./projects"
import jobs from "./jobs"

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    api: moduleApi,
    projects,
    jobs,
  },
  strict: process.env.NODE_ENV !== "production",
})
