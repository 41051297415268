<template>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    :data-target="target"
    :aria-controls="target"
    :aria-expanded="toggled"
    aria-label="Toggle navigation"
    style="background: white"
  >
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 2.47059H16.6692"
        stroke="#003049"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M1.5 10H16.6692"
        stroke="#003049"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M1.5 17.5294H16.6692"
        stroke="#003049"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  </button>
</template>
<script>
export default {
  props: {
    target: {
      type: [String, Number],
      description: "Button target element",
    },
    toggled: {
      type: Boolean,
      default: false,
      description: "Whether button is toggled",
    },
  },
}
</script>
<style></style>
