<template>
  <div class="card" v-if="companyData" @click="goToCompanyProfile">
    <div class="card-header">
      <div class="left">
        <signed-image
          v-if="companyData && typeof companyData.profilePhoto === 'object'"
          :file="companyData.profilePhoto"
          :alt="companyData.name"
        />
        <img v-else class="default-img" src="/img/icons/company.svg" alt="" />
        <div class="company-title title-wrapper">
          <span class="company-name heading">{{ companyData.name }}</span>
        </div>
      </div>
      <!-- <div class="right">
        <PhBookmarkSimple size="24px" color="#B0B9C9" v-if="!save" />
        <PhBookmarkSimple weight="fill" size="24px" color="#4779c9" v-else />
      </div> -->
    </div>
    <div class="card-details">
      <div class="company-location">
        <PhMapPin weight="fill" size="32px" color="#E24646" /><span
          class="location"
          >{{ location || "" }}</span
        >
      </div>
    </div>
    <div class="description">
      {{
        companyData.description
          ? companyData.description
          : "No description provided."
      }}
    </div>
    <div class="industry" v-if="companyData.industry">
      <div class="title">
        <img src="/img/icons/industry.svg" alt="" />Industry
      </div>
      <div class="title">{{ companyData.industry[0] }}...</div>
    </div>
    <span class="posted"> Posted {{ postDate }} </span>
  </div>
</template>

<script>
import { PhMapPin } from "phosphor-vue"
import moment from "moment"

export default {
  props: {
    company: Object,
    companyId: String,
    projectId: String,
    match: Boolean,
  },
  components: {
    PhMapPin,
  },
  data() {
    return {
      companyData: {},
      location: "",
      postDate: "",
    }
  },
  methods: {
    goToCompanyProfile() {
      if (this.match) {
        this.$router.push(
          "/trade-partners/projects/" +
            this.projectId +
            "/matches/" +
            (this.companyId || this.company._id)
        )
      } else {
        this.$router.push({
          name: "profile-id",
          params: { profileId: this.companyData._id, type: "provider" },
        })
      }
    },
    async getCompanyProfile() {
      try {
        const list = await this.$store.dispatch("api/getCompanyProfile", {
          profileId: this.companyId || this.company._id,
        })
        this.companyData = list.data
        this.location = this.companyData.city
          ? this.companyData.city + ", " + this.companyData.state
          : this.companyData.state
        this.postDate = moment(this.companyData.createdAt).fromNow()
      } catch (error) {
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
    },
  },
  mounted() {
    if (!this.company && this.companyId) {
      this.getCompanyProfile()
    } else {
      // this.companyId = this.company._id;
      this.companyData = this.company
      this.getCompanyProfile()
    }
  },
}
</script>

<style scoped>
.posted {
  position: unset;
}

.title-wrapper {
  flex-direction: unset !important;
}
</style>
