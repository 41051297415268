export const PASSWORD_MIN_LENGTH = 12

export const STRIPE_PRODUCTS = {
  JOB_POSTING: "job_posting",
  SEND_JOB_POST_TO_MATCH: "send_job_post_match",
  UNLOCK_APPLICANT_RESUME: "unlock_applicant_resume",
  SKILLED_WORKER_PRIORITY_ACCESS: "skilled_worker_priority_access",
}

export const MINOR_WORDS = [
  "a",
  "an",
  "and",
  "the",
  "but",
  "or",
  "for",
  "nor",
  "as",
  "at",
  "by",
  "for",
  "from",
  "in",
  "into",
  "near",
  "of",
  "on",
  "onto",
  "to",
  "with",
]

export const API_RESPONSE_ERRORS = {
  INVALID_ZIP_CODE_ERROR: "Could not process the location coordinates",
}
export const ERROR_MESSAGES = {
  INVALID_ZIP_CODE_INPUT: "Please enter a valid zip code",
}
