<template>
  <div class="container-fluid">
    <div class="row">
      <p class="info-header">
        This is where you’ll see any job opportunities that have been sent to
        you as a result of a match. You can view a job and decide whether to
        apply or decline. If a job is declined, it will no longer be visible in
        your inbox.
      </p>
      <div class="header">
        <h2 class="heading">Activity</h2>
        <div class="filter-switch">
          <span :class="['filter',active?'active':'']" @click="active=!active;">Active</span>
          <span :class="['filter',!active?'active':'']" @click="active=!active;">Inactive</span>
        </div>
      </div>
      <table
        class="table table-striped table-hover activities-table jobs-table"
        v-if="jobs.length"
      >
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in lists" :key="index">
            <td>
              <signed-image
                v-if="
                  item.company[0].profilePhoto &&
                  typeof item.company[0].profilePhoto === 'object'
                "
                :file="item.company[0].profilePhoto"
                :alt="item.company[0].name"
              />
              <img v-else src="/img/icons/default-company.svg" alt="" />
              {{ item.company[0].name }}
            </td>
            <td>{{ item.name }}</td>
            <td class="status">
              <span v-if="item.type=='job-application'" :class="hasProfileViewAccess(item.company[0].provider) ? 'pending-contact' : 'applied'">{{hasProfileViewAccess(item.company[0].provider) ? 'Pending Contact' : 'Applied'}}</span>
              <span v-else class="match">New Match</span>
            </td>
            <td>{{ getFormattedDate(item.createdAt) }}</td>
            <td class="action-data">
              <div class="actions-wrapper" ref="wrapper" style="width: fit-content">
                <PhDotsThreeCircle 
                  size="40"
                  weight="thin"
                  class="action-btn"
                  @click="togglePopover('popover-' + item._id, $event)"
                />
                <div class="popover-action" ref="popover" :id="'popover-' + item._id">
                  <ul>
                    <li @click="handleApplyForJob(item._id, item.company[0]._id)">
                      <PhCheck size="20" color="#525E77" weight="bold" />Apply to
                      Job
                    </li>
                    <li @click="$router.push('/jobs/' + item._id)">
                      <PhEye size="20" color="#525E77" weight="bold" />View Job
                    </li>
                    <!-- <li>
                      <PhX size="20" color="#525E77" weight="bold" />Decline Job
                    </li>
                    <li>
                      <PhTrash size="20" color="#525E77" weight="bold" />Remove
                      from list
                    </li> -->
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row pagination" v-if="jobs.length">
        <b-pagination
          v-model="currentPage"
          :total-rows="jobs.length"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
    </div>
    <toast-message ref="toastMessage" />
  </div>
</template>

<script>
import moment from "moment"
import profile from "@/api/profile"
import { PhDotsThreeCircle, PhTrash, PhCheck, PhX, PhEye } from "phosphor-vue"

export default {
  components: {
    PhDotsThreeCircle,
    PhTrash,
    PhCheck,
    PhX,
    PhEye
  },
  data() {
    return {
      jobs: [],
      columns: ["Company", "Role", "Status", "Date", "Actions"],
      currentPage: 1,
      limit: 20,
      active: true,
      totalJobs: [],
    }
  },
  methods: {
    async getAppliedJobs() {
      const applies = await this.$store.dispatch("api/getAppliedJobs", {
        limit: this.limit,
        page: this.currentPage,
      })

      let jobsApplied = applies.data.results
      for (let i = 0; i < jobsApplied.length; i++) {
        jobsApplied[i].jobDetails.type = 'job-application';
        this.totalJobs.push(jobsApplied[i].jobDetails)
      }
      this.filterJobs()
    },
    async getJobMatches() {
      const matches = await this.$store.dispatch("api/getJobMatches", {
        limit: this.limit,
        page: this.currentPage,
      })
      let jobs = [...this.totalJobs, ...matches.data.results]
      this.totalJobs = this.filterDuplicates(jobs)
      this.filterJobs()
    },
    filterJobs() {
      if (this.active) {
        this.jobs = this.totalJobs.filter(job => job.deletedAt == null || !job.deletedAt);
      } else {
        this.jobs = this.totalJobs.filter(job => job.deletedAt);
      }
      this.sortJobs()
    },
    sortJobs() {
      this.jobs.sort(function (a, b) {
        var c = new Date(a.createdAt)
        var d = new Date(b.createdAt)
        return d - c
      })
    },
    getFormattedDate(dateString) {
      const parsedDate = moment(dateString)

      const currentDate = moment()

      const diffInDays = currentDate.diff(parsedDate, "days")

      // Determine the display format based on the difference in days
      let displayDate
      if (diffInDays === 0) {
        displayDate = "Today at " + parsedDate.format("h:mm A")
      } else if (diffInDays === 1) {
        displayDate = "Yesterday at " + parsedDate.format("h:mm A")
      } else {
        displayDate = parsedDate.format("DD MMM YYYY [at] hh:mm A")
      }
      return displayDate
    },
    goToPage(page) {
      this.currentPage = page
    },
    async handleApplyForJob(job, company) {
     try {
        let type = "job-application"
        let data = await profile.requestProfileAccess(company, job, type)
        let popover = document.getElementById('popover-' + job)
        popover.style.display = 'none';
        popover.parentElement.querySelector('.action-btn').classList.remove("active");
        this.getAppliedJobs()
        this.getJobMatches()
        //Show Toast success
        this.$refs.toastMessage.showToast(
            "Job application has been sent successfully!!",
            "success"
        )
     } catch (error) {
        this.$refs.toastMessage.showToast(
            "Cannot send job application !! try again !!",
            "error"
        )
     }
    },
    togglePopover(id, event) {
            this.hideAllPopovers();
            let popover = document.getElementById(id)
            if (popover.style.display == 'none') {
                popover.style.display = 'block';
                event.target.classList.add("active");
            } else {
                popover.style.display = 'none';
                event.target.classList.remove("active");
            }
    },
    hideAllPopovers(){
      let popovers = document.getElementsByClassName('popover-action');
      let actionBtns = document.getElementsByClassName('action-btn');

      for (let i = 0; i < popovers.length; i ++) {
        popovers[i].style.display = 'none'
      }

      for (let i = 0; i < actionBtns.length; i ++) {
        actionBtns[i].classList.remove("active");
      }
    },
    filterDuplicates(arr) {
      const map = new Map();

      arr.forEach(item => {
        const { _id, type } = item;        
       
        if (_id === undefined) {
          throw new Error('Item must have an id field');
        }
        
        if (map.has(_id)) {
          const existingItem = map.get(_id);

          if (type == 'job-application' || (existingItem.type != 'job-application' && type !== undefined && (existingItem.type === undefined || type !== existingItem.type))) {
            map.set(_id, item);
          }
        } else {
          map.set(_id, item);
        }
      });

      return Array.from(map.values());
    },
    handleClickOutside(event) {
      const allPopovers = this.$refs.popover;
      const allWrappers = this.$refs.wrapper;
      let isClickInside = false;

      allWrappers.forEach((wrapper, i) => {
        if (wrapper.contains(event.target) || (allPopovers[i] && allPopovers[i].contains(event.target))) {
          isClickInside = true;
        }
      });

      if (!isClickInside) {           
         this.hideAllPopovers()
      }
    },
    hasProfileViewAccess(provider) {
      let profileId =  this.$store.state.userProfile._id,
      unlockedApplicants =
       provider.unlockedApplicants || []

      let isUnlockedProfile = unlockedApplicants.find(
        (item) => item.profile === profileId
      )
      return !isUnlockedProfile ? false : true
    }
  },
  mounted() {
    this.getAppliedJobs()
    this.getJobMatches()
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    lists() {
      const items = this.jobs
      // Return just page of items needed
      return items.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    },
  },
  watch: {
    active() {
      this.filterJobs()
    },
    totalJobs() {
      document.getElementById('activity-count').innerHTML = this.totalJobs.length
    }
  }
}
</script>

<style scoped lang="scss">
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .row {
    margin-left: 0;
    margin-right: 0;

    p.info-header {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-weight: 400;
    }

    .header {
      display: flex;
      padding: 24px 40px 16px 40px;
      align-items: center;
      gap: 48px;
      align-self: stretch;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      border: 1px solid var(--Gray-200, #d5d9e2);
      border-bottom: unset;
      width: 100%;
      background: #FFFFFF;;

      .heading {
        color: var(--Gray-900, #343946);
        font-size: 20px;
        font-weight: 600;
        text-transform: none;
      }

      .filter-switch {
        display: flex;
        padding: 4px;
        align-items: center;
        border-radius: 8px;
        background: var(--Gray-100, #ECEEF2);

        .filter {
          display: flex;
          min-width: 140px;
          padding: 8px 48px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          color: var(--Gray-600, #525E77);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          cursor: pointer;

          &.active {
            background: #fff;
            color: var(--gfb-blue-brand-500, #0095E2);
          }
        }

      }
    }

    .popover-action {
      display: none;
      min-height: 52px;
      padding: 8px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--Gray-200, #d5d9e2);
      background: var(--White, #fff);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 65px;
      right: 90px;
      z-index: 999;

      ul {
        list-style: none;
        margin-bottom: 0;

        li {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          cursor: pointer;
          border-radius: 4px;
          margin-bottom: 2px;
          margin-top: 2px;

          &:hover,
          &:focus {
            background: var(--Gray-100, #eceef2);
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
          }

        //   &:last-of-type {
        //     border-top: 1px solid var(--Gray-300, #b0b9c9);
        //   }
        }
      }
    }

    .action-btn {
      cursor: pointer;
      stroke-width: 14;
      stroke: #B0B9C9 !important;
      fill: #B0B9C9 !important;
      color: #B0B9C9 !important;

      &.active {
        stroke: #0095E2 !important;
        fill: #0095E2 !important;
        color: #0095E2 !important;
      }

      &:focus,
      &:hover {
        fill: #525E77 !important;
        stroke: #525E77 !important;
        color: #525E77 !important; 
      }
    }
  }
}
</style>
