<template>
  <div class="w-100 d-flex justify-content-between align-items-center">
    <b-spinner
      :small="small"
      type="success"
      :style="size ? `width: ${size}; height: ${size}` : ''"
      label="loading...."
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: "loading-spinner",
  props: {
    size: {
      type: String,
    },
    small: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped></style>
