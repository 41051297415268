var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',[_c('multiselect',{class:{ 'custom-multi-select': true },attrs:{"placeholder":_vm.selectLabel,"options":_vm.selectOptions,"multiple":true,"close-on-select":false,"taggable":_vm.tagFlag,"showNoOptions":_vm.showNoOptionsFlag},on:{"tag":function (tag) { return _vm.$emit('handle-tag', tag); },"select":function (option) { return _vm.$emit('select-option', option); }},scopedSlots:_vm._u([{key:"option",fn:function(scope){return _c('span',{staticClass:"checkbox-label"},[_c('input',{staticClass:"category-check",attrs:{"type":"checkbox","id":scope.option},domProps:{"value":scope.option,"checked":_vm.selected.includes(scope.option)},on:{"click":function () {
              var isSelected = _vm.selected.includes(scope.option)
              if (isSelected) {
                _vm.$emit('remove-option', scope.option)
              } else {
                _vm.$emit('select-option', scope.option)
              }
            },"focus":function($event){$event.preventDefault();}}}),_c('label',{attrs:{"for":scope.option}}),_vm._v(" "+_vm._s(scope.option.isTag ? scope.option.label : scope.option)+" ")])}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"selection"},[_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectLabel))])])],2)],1),_c('div',{class:{
      'multiselect__selected-category-list': true,
      'empty-list': _vm.selected.length ? false : true,
    }},[(_vm.selected.length)?_c('div',{staticClass:"selected-tags"},_vm._l((_vm.selected),function(experience,index){return _c('span',{key:index,staticClass:"tag-wrapper"},[_c('span',[_vm._v(_vm._s(experience))]),_c('i',{staticClass:"remove-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"click":function($event){return _vm.$emit('remove-option', experience)}}})])}),0):_c('span',[_vm._v("Your selection will appear here.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }