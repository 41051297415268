import axios from "@/utils/api-v1"
// import store from '@/store/store.js'

export default {
  createAccount(payload) {
    const user = payload.userId
    const name = payload.businessName
    const type = payload.type
    let isFranchise = payload.isFranchise
    if (isFranchise === "") {
      isFranchise = false
    }
    const address = payload.address
    const address2 = payload.address2
    const city = payload.city
    const state = payload.state
    const zip = payload.zip
    const country = payload.country
    const workRadius = payload.workRadius
    const industryOfWorkPerformed = payload.industryOfWorkPerformed
    let selfPerformWork = payload.selfPerformWork
    if (selfPerformWork === "") {
      selfPerformWork = false
    }
    const selfPerformWorkCategories = payload.selfPerformWorkCategories
    return axios.post("/api/accounts/upsert", {
      user,
      name,
      type,
      isFranchise,
      address,
      address2,
      city,
      state,
      zip,
      country,
      workRadius,
      industryOfWorkPerformed,
      selfPerformWork,
      selfPerformWorkCategories,
    })
  },
}
