<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Post or search for an upcoming project or task and quickly find trade
        partners to review and connect with
      </span></template
    >
    <b-row class="my-4">
      <b-col sm md="3">
        <base-input placeholder="ZIP Code" type="text" v-model="filter.zip">
        </base-input>
      </b-col>
      <b-col sm md="5">
        <multiselect
          style="margin-bottom: 20px"
          v-model="filter.categories"
          placeholder="Industry Type"
          :options="categoryOptions"
          :multiple="true"
          :close-on-select="false"
        >
        </multiselect>
      </b-col>
      <b-col sm md="2">
        <DefaultButton :labelText="'Search'" @click="filterProjects" />
      </b-col>
    </b-row>

    <div class="row" v-if="projectSearch.length">
      <div v-for="c in projectSearch" :key="c._id" class="col-lg-4">
        <project-card :project="c" />
      </div>
    </div>
    <div class="row" v-else-if="projectSearch.length == 0">
      <div
        v-if="loadingData"
        class="text-center align-items-center"
        style="margin: auto"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <h2 v-else-if="loadingData == false" style="margin: auto">
        There are no projects that match the zip code and/or categories
      </h2>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import ProjectCard from "@/components/Cards/ProjectCard"
import options from "@/utils/options"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    ProjectCard,
    DefaultButton,
  },
  data() {
    return {
      filter: {
        zip: this.$store.state.userProfile.zip,
        categories: [],
      },
      categoryOptions: options.industryOptions,
      projectSearch: [],
      loadingData: null,
    }
  },
  methods: {
    async filterProjects() {
      try {
        this.loadingData = true
        this.projectSearch = []
        const { zip, categories } = { ...this.filter }

        const list = await this.$store.dispatch("api/searchProjectsByZip", {
          zip,
          industry: categories,
        })
        this.projectSearch = list.data
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
    },
  },
  mounted() {
    this.filterProjects()
  },
}
</script>

<style scoped></style>
<style>
.multiselect__content-wrapper {
  position: unset;
}
</style>
