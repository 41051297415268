<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1000 636"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(4.16667,0,0,4.16667,-165,-194.5)">
      <g transform="matrix(0.621075,0,0,0.621316,60.5701,46.4762)">
        <path
          d="M318.898,196.393C318.898,223.788 296.688,246 269.292,246L0,246L0,49.934C0,22.538 22.21,0.328 49.609,0.328L318.898,0.328L318.898,196.393Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.621075,0,0,0.621316,60.5701,46.4762)">
        <path
          d="M231.099,146.162L251.609,146.162C252.254,146.162 253.474,146.06 254.055,145.932C257.992,145.081 261.271,141.585 261.271,136.824C261.271,132.061 257.992,128.557 254.055,127.705C253.474,127.578 252.254,127.474 251.609,127.474L231.099,127.474L231.099,146.162Z"
          style="fill: url(#_Linear1)"
        />
      </g>
      <g transform="matrix(0.621075,0,0,0.621316,60.5701,46.4762)">
        <path
          d="M249.212,16.865C229.644,16.865 213.778,32.729 213.778,52.295L213.778,89.098L263.916,89.098C265.078,89.098 266.5,89.179 267.387,89.225C278.733,89.794 287.102,95.623 287.102,105.739C287.102,113.693 281.382,120.485 270.973,122.015L270.973,122.414C282.472,123.212 291.275,129.539 291.275,139.511C291.275,150.296 281.612,157.23 268.732,157.23L213.778,157.23L213.778,229.463L265.749,229.463C285.317,229.463 301.182,213.598 301.182,194.03L301.182,16.865L249.212,16.865Z"
          style="fill: url(#_Linear2)"
        />
      </g>
      <g transform="matrix(0.621075,0,0,0.621316,60.5701,46.4762)">
        <path
          d="M258.842,108.302C258.842,103.621 255.563,100.483 251.708,99.831C251.348,99.77 250.366,99.675 249.69,99.675L231.099,99.675L231.099,116.926L249.69,116.926C250.366,116.926 251.348,116.829 251.708,116.767C255.563,116.115 258.842,112.979 258.842,108.302Z"
          style="fill: url(#_Linear3)"
        />
      </g>
      <g transform="matrix(0.621075,0,0,0.621316,60.5701,46.4762)">
        <path
          d="M53.151,16.865C33.581,16.865 17.717,32.729 17.717,52.295L17.717,139.8C27.655,144.614 37.921,147.75 48.371,147.75C60.622,147.75 67.352,140.272 67.352,130.209L67.352,89.1L97.827,89.1L97.827,130.209C97.827,146.288 87.957,159.184 54.087,159.184C33.704,159.184 17.717,154.789 17.717,154.789L17.717,229.463L69.686,229.463C89.254,229.463 105.12,213.598 105.12,194.03L105.12,16.865L53.151,16.865Z"
          style="fill: url(#_Linear4)"
        />
      </g>
      <g transform="matrix(0.621075,0,0,0.621316,60.5701,46.4762)">
        <path
          d="M151.182,16.865C131.612,16.865 115.749,32.729 115.749,52.295L115.749,98.684C124.727,90.983 140.252,86.171 165.476,87.307C178.978,87.909 193.296,91.53 193.296,91.53L193.296,106.532C186.13,102.9 177.536,99.536 166.463,98.698C147.32,97.251 135.8,106.606 135.8,123.166C135.8,139.722 147.32,149.075 166.463,147.622C177.536,146.791 186.243,143.337 193.296,139.798L193.296,154.792C193.296,154.792 178.978,158.412 165.476,159.025C140.252,160.16 124.727,155.349 115.749,147.647L115.749,229.463L167.718,229.463C187.285,229.463 203.149,213.598 203.149,194.03L203.149,16.865L151.182,16.865Z"
          style="fill: url(#_Linear5)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="_Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(87.6278,0,0,87.6278,213.78,136.818)"
      >
        <stop offset="0" style="stop-color: rgb(0, 127, 73); stop-opacity: 1" />
        <stop
          offset="1"
          style="stop-color: rgb(98, 188, 70); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient
        id="_Linear2"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(87.6301,0,0,87.6301,213.778,123.164)"
      >
        <stop offset="0" style="stop-color: rgb(0, 127, 73); stop-opacity: 1" />
        <stop
          offset="1"
          style="stop-color: rgb(98, 188, 70); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient
        id="_Linear3"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(87.6252,0,0,87.6252,213.778,108.301)"
      >
        <stop offset="0" style="stop-color: rgb(0, 127, 73); stop-opacity: 1" />
        <stop
          offset="1"
          style="stop-color: rgb(98, 188, 70); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient
        id="_Linear4"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(88.9831,0,0,88.9831,17.6045,123.164)"
      >
        <stop
          offset="0"
          style="stop-color: rgb(50, 51, 119); stop-opacity: 1"
        />
        <stop
          offset="1"
          style="stop-color: rgb(0, 120, 193); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient
        id="_Linear5"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(86.4217,0,0,86.4217,115.225,123.164)"
      >
        <stop
          offset="0"
          style="stop-color: rgb(116, 50, 54); stop-opacity: 1"
        />
        <stop
          offset="1"
          style="stop-color: rgb(237, 24, 70); stop-opacity: 1"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
