<template>
  <div>
    <b-form-group>
      <multiselect
        :class="{ 'custom-multi-select': true }"
        v-model="selected"
        :placeholder="selectLabel"
        :options="selectOptions"
        :multiple="true"
        :close-on-select="false"
        :taggable="tagFlag"
        :showNoOptions="showNoOptionsFlag"
        @tag="(tag) => $emit('handle-tag', tag)"
        @select="(option) => $emit('select-option', option)"
      >
        <template slot="selection">
          <span class="multiselect__single">{{ selectLabel }}</span>
        </template>
        <span class="checkbox-label" slot="option" slot-scope="scope">
          <input
            class="category-check"
            type="checkbox"
            :id="scope.option"
            :value="scope.option"
            :checked="selected.includes(scope.option)"
            @click="
              () => {
                let isSelected = selected.includes(scope.option)
                if (isSelected) {
                  $emit('remove-option', scope.option)
                } else {
                  $emit('select-option', scope.option)
                }
              }
            "
            @focus.prevent
          />
          <label :for="scope.option"></label>
          {{ scope.option.isTag ? scope.option.label : scope.option }}
        </span>
      </multiselect>
    </b-form-group>
    <div
      :class="{
        'multiselect__selected-category-list': true,
        'empty-list': selected.length ? false : true,
      }"
    >
      <div class="selected-tags" v-if="selected.length">
        <span
          class="tag-wrapper"
          :key="index"
          v-for="(experience, index) in selected"
        >
          <span>{{ experience }}</span>
          <i
            aria-hidden="true"
            tabindex="1"
            class="remove-icon"
            @click="$emit('remove-option', experience)"
          ></i>
        </span>
      </div>
      <span v-else>Your selection will appear here.</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "custom-multi-select",
  props: {
    selectOptions: {
      type: Array,
    },
    selectLabel: {
      type: String,
      default: "Search or select experience",
    },
    selectedList: {
      type: Array,
    },
    tagFlag: {
      type: Boolean,
      default: false,
    },
    showNoOptionsFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [...new Set(this.selectedList)] ?? [],
      options: [...this.selectOptions] ?? [],
    }
  },
  watch: {
    selectedList(newval) {
      this.selected = [...new Set(newval)]
    },
    selectOptions(newval) {
      this.options = [...newval]
    },
  },
  methods: {
    handleTag(tag) {
      this.selectedList = this.selectedList.concat(tag)
    },
  },
}
</script>
<style></style>
