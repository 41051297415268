<template>
  <div>
    <h4 class="px-lg-3 mb-3">Your Purchase</h4>
    <div class="gray-box d-flex justify-content-between px-4 mb-4">
      <span>{{
        paymentIntent ? paymentIntent.metadata.product_name : product.name
      }}</span>
      <span class="font-weight-bold"
        >${{
          paymentIntent ? paymentIntent.amount / 100 : product.amount / 100
        }}</span
      >
    </div>
    <div v-if="selectedPaymentMethod">
      <div
        class="d-flex justify-content-between align-items-center card-btns-wrapper"
      >
        <h4 class="px-3">Payment Information</h4>
        <div>
          <b-row>
            <DefaultButton
              :labelText="'Change Card'"
              @click="handleChangeCardClick"
            />
            <add-card-modal></add-card-modal>
          </b-row>
        </div>
      </div>
      <payment-method-display
        :paymentMethod="selectedPaymentMethod"
      ></payment-method-display>

      <div class="mt-5 d-flex justify-content-between align-items-center px-3">
        <span class="mb-2 text-sm-left"
          >*Your card will be saved in Toolbox to make future connections
          easier</span
        >
        <DefaultButton
          :labelText="briefing ? 'Send Job Briefing' : 'Confirm'"
          @click="handleConfirm"
        />
      </div>
    </div>
    <div v-else class="d-flex justify-content-end align-items-center px-3">
      <base-button
        @click="handleConfirm"
        type="success"
        class="rounded-lg"
        :loading="loading"
        >Go to Billing</base-button
      >
    </div>
  </div>
</template>

<script>
import PaymentMethodDisplay from "./PaymentMethodDisplay.vue"
import addCardModal from "./AddCardModal"
import DefaultButton from "@/components/DefaultButton.vue"

export default {
  components: {
    PaymentMethodDisplay,
    addCardModal,
    DefaultButton,
  },
  props: {
    paymentIntent: Object,
    product: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    selectedPaymentMethod: {
      type: Object,
    },
    briefing: {
      type: Boolean,
    },
  },
  methods: {
    handleChangeCardClick() {
      this.$bvModal.show("skilled-worker-payment-change-card-modal")
    },
    handleConfirm() {
      this.$emit("handle-confirm")
    },
  },
}
</script>

<style lang="scss" scoped>
.card-btns-wrapper {
  margin-bottom: 25px;
}
</style>
