import { MINOR_WORDS } from "./constants"

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function createPhotoInput(file) {
  return new Promise((resolve) => {
    convertFileToBase64(file).then((data) =>
      resolve({
        name: file.name,
        data,
      })
    )
  })
}

export function titleCase(title, delimiter) {
  return title
    .split(delimiter ?? " ")
    .map((word) => {
      return MINOR_WORDS.includes(word.toLowerCase())
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(" ")
}
