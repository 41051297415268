<template>
  <div>
    <div class="form-container">
      <div class="form-content-body pb-3">
        <div v-if="!emailSent">
          <form method="POST" @submit.prevent="signup">
            <h2 class="font-48-900 text-center dark-text mb-4">Sign Up</h2>
            <!-- base-input -->
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              v-model="email"
            >
            </base-input>
            <button
              class="btn btn-dark-white w-100 btn-form go-rounded-14 mb-4"
              :disabled="!isValid"
              type="submit"
            >
              Signup
            </button>
          </form>
          <p class="text-center">
            Already have an account?
            <router-link :to="{ name: 'login' }" class="orange-clr">
              Login
            </router-link>
          </p>
        </div>
        <div v-else>
          <h2 class="font-48-900 text-center dark-text mb-4">
            Registration Successful
          </h2>
          <p>Check your email address for a link to setup your account.</p>
          <p>
            Please check your spam folder and contact support if you do not
            receive the confirmation email.
          </p>
          <router-link :to="{ name: 'login' }">
            <button
              class="btn btn-dark-white w-100 btn-form go-rounded-14 mb-4"
            >
              Back to Login
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="outside-copyright mt-4 text-center">
      <p class="font-16-500">
        By continuing you agree to GoFindBuild LLC.
        <span class="d-block text-center">
          <a href="#!">Terms of Service</a> and
          <a href="#!">Privacy Policy.</a>
        </span>
      </p>
    </div>
    <toast-message ref="toastMessage" />
  </div>
</template>
<script>
import { validateEmail } from "@/utils/validations"

export default {
  name: "register",
  created() {},
  data() {
    return {
      email: "",
      emailSent: false,
    }
  },
  mounted() {
    setTimeout(() => {
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
    }, 5000)
  },
  beforeDestroy() {
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  },
  computed: {
    isValid() {
      return validateEmail(this.email)
    },
  },
  methods: {
    async signup() {
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha("signup")
        console.log(token)
        this.registerUser()
      } catch {
        this.$refs.toastMessage.showToast(
          "Something went Wrong, Try again!!",
          "error"
        )
        this.$router.push("/signup")
      }
    },
    registerUser() {
      const payload = {
        userDetails: {
          email: this.email,
        },
      }
      const profileType =
        this.$router.currentRoute.query.profileType ?? "candidate"
      localStorage.setItem("profileType", profileType)

      this.$store
        .dispatch("auth/registerUserEmail", payload)
        .then((response) => {
          if (response.data.errors) {
            this.$toast.error(response.data.errors[0] || "Email already exist")
          } else {
            this.emailSent = true
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.$toast.error(
              error.response.data.errors[0] || "Email already exist"
            )
          } else {
            console.log("register error", error)
            this.$toast.error(error.message)
          }
        })
    },
  },
}
</script>
<style></style>
