<template>
  <div class="row">
    <div class="col">
      <profile-section-header title="General Information" />
      <form
        role="form"
        method="post"
        @submit.prevent="validate"
        class="profile-form"
      >
        <div class="d-flex justify-content-between align-items-end">
          <div
            :class="[
              'profile-avatar mr-4 mb-3 d-flex justify-content-center align-items-center',
              !profile.profilePhoto && !profile.redacted ? 'border' : '',
            ]"
          >
            <b-skeleton
              v-if="profile.redacted"
              class="rounded-circle"
              type="avatar"
              size="88px"
              animation="save"
              variant="light"
            ></b-skeleton>
            <signed-image
              v-if="profile.profilePhoto"
              :file="profile.profilePhoto"
              :alt="profile.name"
            />
            <svg
              v-if="!profile.profilePhoto && !profile.redacted"
              viewBox="0 0 130 130"
              class="avatar-icon"
            >
              <circle fill="white" cx="50%" cy="50%" r="50%"></circle>
              <text
                fill="currentColor"
                x="50%"
                y="50%"
                dominant-baseline="middle"
                text-anchor="middle"
              >
                {{ initials }}
              </text>
            </svg>
            <edit-button
              v-if="isEditing"
              class="avatar-edit-button"
              @handle-click="$emit('handle-click', 'files')"
            />
          </div>
          <div class="flex-grow-1 row align-self-center">
            <base-input
              v-if="isEditing"
              classes="col-12"
              placeholder="Name"
              :label="
                profileType !== 'candidate' ? 'Company name' : 'Your name'
              "
              type="text"
              :error="errors.name"
              v-model="fields.name"
            />
            <span v-else class="col-12 font-weight-bold mb-3">{{
              fields.name
            }}</span>
          </div>
        </div>
        <div class="row">
          <base-input
            classes="col-12"
            v-if="profileType !== 'candidate'"
            placeholder="Principal contact name"
            label="Principal contact"
            type="text"
            :error="errors.principleContact"
            v-model="fields.principleContact"
            :disabled="!isEditing"
          />
          <base-input
            v-if="profileType !== 'candidate'"
            :classes="
              profileType === 'candidate' ? 'col-12' : 'col-12 col-md-6'
            "
            placeholder="Email address"
            :label="`${
              profileType !== 'candidate' ? 'E' : 'Your e'
            }mail address`"
            type="text"
            :error="errors.email"
            v-model="fields.email"
            :disabled="!isEditing"
          />
          <phone-input
            v-if="profileType !== 'candidate'"
            classes="col-12 col-md-6"
            placeholder="Phone number"
            label="Phone number"
            type="text"
            :error="errors.phoneNumber"
            :value="fields.phoneNumber"
            @on-change="fields.phoneNumber = $event"
            :disabled="!isEditing"
          />
          <base-input
            v-if="profileType !== 'candidate'"
            classes="col-12 col-md-6"
            placeholder="Website"
            label="Website"
            type="text"
            :error="errors && errors.website"
            v-model="fields.website"
            :disabled="!isEditing"
          />
          <base-input
            classes="col-12 col-md-6"
            v-if="profileType !== 'candidate'"
            placeholder="Tax ID"
            label="Tax ID"
            type="text"
            :error="errors && errors.taxId"
            v-model="fields.taxId"
            :disabled="!isEditing"
          />
          <base-input
            classes="col-12 col-md-6"
            placeholder="Address"
            :label="`${profileType !== 'candidate' ? 'A' : 'A'}ddress`"
            type="text"
            :error="errors && errors.address"
            v-model="fields.address"
            :disabled="!isEditing"
          />
          <base-input
            classes="col-12 col-md-6"
            placeholder="Address 2"
            :label="`${profileType !== 'candidate' ? 'A' : 'A'}ddress (line 2)`"
            type="text"
            :error="errors && errors.address2"
            v-model="fields.address2"
            :disabled="!isEditing"
          />
          <div class="col-12 col-md-6">
            <base-input
              classes="mb-0"
              placeholder="ZIP code"
              :label="`${profileType !== 'candidate' ? 'Z' : 'Z'}IP code`"
              type="text"
              :error="errors && errors.zip"
              v-model="fields.zip"
              :disabled="!isEditing"
            />
            <p class="m-0 ml-1 mb-3" v-show="location">
              Location:
              <span class="text-dark font-weight-bold">{{ location }}</span>
            </p>
          </div>
        </div>
        <div
          v-if="isEditing"
          class="profile-header-submit d-flex justify-content-end w-100 mt-3"
        >
          <button
            type="submit"
            class="btn btn-success font-22-900 profile-header-submit"
            :class="[loading ? 'disabled' : '']"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import normalizeUrl from "normalize-url"
import options from "@/utils/options"
import {
  deNullifyErrors,
  normalize,
  requiredError,
  validateEmail,
  validateUrl,
  validateUsPhoneNumber,
  validateZipCode,
} from "@/utils/validations"
import ProfileSectionHeader from "@/components/Profile/ProfileSectionHeader"

export default {
  components: {
    ProfileSectionHeader,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const {
      name,
      taxId,
      principleContact,
      phoneNumber,
      email,
      address,
      address2,
      website,
      zip,
      workRadius,
      industry,
    } = this.profile
    const profileType = this.$store.state.profileType
    return {
      profileType,
      errors: {},
      loading: false,
      fields: {
        name,
        taxId,
        principleContact,
        phoneNumber,
        email,
        address,
        address2,
        website,
        zip,
        workRadius,
        industry,
      },
      workRadiusOptions: options.workRadiusOptions,
      industryOptions: options.industryOptions,
    }
  },
  computed: {
    location() {
      const city = this.profile.city
      const state = this.profile.state
      const location = `${city ? `${city}` : ""}${state ? `, ${state}` : ""}`
      return location
    },
    initials() {
      const { name, redacted } = this.profile
      const names = name.split(" ")
      const firstName = names[0]
      const lastName = redacted
        ? ""
        : names.length > 1
        ? names.slice(-1)[0]
        : null
      const initials =
        firstName.charAt(0) + (lastName ? lastName.charAt(0) : "")
      return initials
    },
  },
  methods: {
    validate() {
      if (!this.isEditing) {
        return
      }
      const normalized = normalize(this.fields)
      const data = {
        ...normalized,
        ...(normalized.website
          ? { website: normalizeUrl(normalized.website) }
          : {}),
      }
      const { email, zip, website, phoneNumber } = data
      const minLength2Fields = [
        "name",
        "principleContact",
        "address",
        "address2",
        "taxId",
      ]
      const minLengthErrors = Object.entries(data).reduce(
        (errors, [key, value]) => {
          if (!minLength2Fields.includes(key)) return errors
          return value.length < 2
            ? {
                ...errors,
                [key]: `Please enter a ${key} at least 2 characters long`,
              }
            : errors
        },
        {}
      )
      const validationErrors = {
        email: requiredError(
          email,
          !validateEmail(email) ? "Please enter a valid email address" : null
        ),
        zip: requiredError(
          zip,
          !validateZipCode(zip) ? "Please enter a valid ZIP code" : null
        ),
        website:
          website && !validateUrl(website)
            ? "Please enter a valid website"
            : null,
        phoneNumber:
          phoneNumber && !validateUsPhoneNumber(phoneNumber)
            ? "Please enter a valid US phone number"
            : null,
      }
      const errors = {
        ...minLengthErrors,
        ...validationErrors,
      }

      if (Object.keys(deNullifyErrors(errors)).length > 0) {
        this.errors = errors
      } else {
        this.errors = {}
        this.handleSubmit(data)
      }
    },
    async handleSubmit(data) {
      this.loading = true
      const profile = this.profile
      try {
        await this.$store.dispatch("api/updateProfile", {
          profileId: profile._id,
          data,
        })
        this.$toast.success("Profile updated")
        this.$emit("handle-complete")
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" global>
.profile-form .form-control:disabled {
  background-color: white;
}
</style>

<style lang="scss" scoped>
.profile-avatar {
  width: 88px;
  height: 88px;
  background: #ffffff;
  border-radius: 44px;
  position: relative;
  &.redacted img {
    filter: blur(8px);
  }
  &.redacted .avatar-icon {
    filter: blur(6px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 44px;
  }
  .avatar-edit-button {
    display: flex;
    position: absolute;
    background-color: #fff;
    border: 1px solid #b9bdc5;
    bottom: 0;
    right: 0;
    z-index: 2;

    &:hover {
      box-shadow: var(--box-shadow-sm);
    }
  }

  .avatar-icon {
    font-family: var(--font-family-base);
    font-size: 3rem;
    font-weight: bold;
    width: 100%;
    height: 100%;

    & text {
      color: var(--green);
    }
  }
}

.profile-header-submit .btn {
  border-radius: 14px;
  height: 45px;
  line-height: 45px;
  min-width: 200px;
  padding: 0;
}
</style>
