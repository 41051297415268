import InnerLayout from "@/layout/InnerLayout"

const navItems = [
  {
    title: "Find Vendors",
    to: "/vendors/search",
  },
]

export const vendorsRouter = [
  {
    path: "/vendors",
    redirect: "/vendors/search",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "search",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Vendors/SearchVendors.vue"
          ),
      },
      {
        path: ":profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Vendors/VendorDetail.vue"
          ),
      },
    ],
  },
]
