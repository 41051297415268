import axios from "@/utils/api-v1"
// import store from '@/store/store.js'

export default {
  createCandidate(
    userId,
    firstName,
    lastName,
    zip,
    desiredPosition,
    priorSkilledTradeFormalTraining,
    tradeCategories
  ) {
    const user = userId
    return axios.post("/api/candidates/upsert", {
      user,
      firstName,
      lastName,
      zip,
      desiredPosition,
      priorSkilledTradeFormalTraining,
      tradeCategories,
    })
  },
}
