<template>
  <button class="edit-button" type="button" @click="handleClick">
    <i class="fas fa-pencil-alt"></i>
  </button>
</template>

<script>
export default {
  name: "edit-button",
  methods: {
    handleClick() {
      this.$emit("handle-click")
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  --highlight-color: #233dd2;
  background: rgba(0, 26, 38, 0.1);
  border: 1px solid transparent;
  border-radius: 100%;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  transition: all 0.25s linear;
  &:hover,
  :focus,
  :focus-within {
    border-color: var(--highlight-color);
    color: var(--highlight-color);
  }
  &:focus,
  :focus-within {
    outline: none;
  }
}
</style>
