<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        {{
          profile && !isSkilledWorker
            ? `You are viewing the profile for ${profile.name}`
            : isSkilledWorker
            ? "Invest in priority access to this skilled worker and stay ahead of your competition!"
            : ""
        }}
      </span></template
    >
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="'Back to Search'"
        @click="
          $router.push(
            isSkilledWorker ? '/employees/skilled-workers' : '/employees/search'
          )
        "
      />
      <DefaultButton
        :labelText="
          isSkilledWorker ? 'Get Priority Access' : 'Send Job Briefing'
        "
        @click="handleEmployeeConnect"
      />
    </template>
    <profile></profile>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { nanoid } from "nanoid"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import messagesMixin from "@/mixins/messagesMixin"
import stripeMixin from "@/mixins/stripeMixin"
import { STRIPE_PRODUCTS } from "@/utils/constants"
import DefaultButton from "@/components/DefaultButton"

export default {
  mixins: [messagesMixin, stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
  },
  data() {
    const profileId = this.$router.currentRoute.params?.profileId
    const isSkilledWorker =
      this.$router.currentRoute.path.includes("skilled-workers")
    return {
      profileId,
      profile: null,
      isSkilledWorker,
      loading: false,
    }
  },
  created() {
    this.fetchProfile()
  },
  methods: {
    async fetchProfile() {
      const profileId = this.$router.currentRoute.params?.profileId
      try {
        const { data } = await this.$store.dispatch("api/getProfile", {
          profileId,
        })
        console.log("data", data)
        const timeNow = new Date()
        const previousPriorityAccess = data?.jobSeeker?.lastPriorityAccess
        const hours =
          Math.abs(
            new Date(previousPriorityAccess)?.getTime() - timeNow.getTime()
          ) / 3600000
        const isPriorityAccess =
          (data.jobSeeker.hasFormalTraining ||
            data.jobSeeker.hasHandsOnExperience) &&
          (!data.jobSeeker.lastPriorityAccess || hours < 72)
        console.log("isPriorityAccess", isPriorityAccess)
        console.log("hours", hours)
        if (
          this.isSkilledWorker &&
          !data.jobSeeker.hasHandsOnExperience &&
          !data.jobSeeker.hasFormalTraining
        ) {
          this.$router.push("/dashboard")
        }
        if (!this.isSkilledWorker && isPriorityAccess) {
          this.$router.push("/profile/" + data._id)
        }
        this.profile = data
      } catch (error) {
        this.showErrorMessage(
          error,
          "We couldn't retrieve the data for this profile."
        )
      }
    },
    async handleEmployeeConnect() {
      // TODO: We'll need to update this if statement if we decide to
      // charge for submitting a job briefing even to non-skilled workers.
      if (!this.isSkilledWorker) {
        const data = {
          candidate_profile_id: this.profileId,
          product_slug: STRIPE_PRODUCTS.CANDIDATE_JOB_BRIEFING,
          return_url: `${process.env.VUE_APP_SITE_URL}/employees/profile/${this.profileId}/access/checkout-complete`,
          idempotencyKey: nanoid(),
        }
        const defaultError = new Error(
          "We couldn't submit your payment request."
        )
        try {
          const response = await this.$store.dispatch(
            "api/createPaymentIntent",
            data
          )
          if (!response.data || response.data.errors) {
            throw response.data?.errors[0] ?? defaultError
          }
          const paymentIntent = response.data
          console.log("paymentIntent", paymentIntent)
          this.loading = false
          if (paymentIntent) {
            this.$router.push(
              `/employees/search/profile/${this.profileId}/job?payment_intent=${paymentIntent.id}`
            )
          } else {
            throw defaultError
          }
        } catch (error) {
          this.showErrorMessage(error)
        }
        return
      } else {
        const data = {
          candidate_profile_id: this.profileId,
          product_slug: STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS,
          return_url: `${process.env.VUE_APP_SITE_URL}/employees/skilled-workers/profile/${this.profileId}/access/checkout-complete`,
          idempotencyKey: nanoid(),
        }
        const defaultError = new Error(
          "We couldn't submit your payment request."
        )
        try {
          const response = await this.$store.dispatch(
            "api/createPaymentIntent",
            data
          )
          if (!response.data || response.data.errors) {
            throw response.data?.errors[0] ?? defaultError
          }
          const paymentIntent = response.data
          console.log("paymentIntent", paymentIntent)
          this.loading = false
          if (paymentIntent) {
            const baseUrl = this.isSkilledWorker
              ? "/employees/skilled-workers/profile"
              : "/employees/search/profile"
            this.$router.push(
              `${baseUrl}/${this.profileId}/access?payment_intent=${paymentIntent.id}`
            )
          } else {
            throw defaultError
          }
        } catch (error) {
          this.showErrorMessage(error)
          this.loading = false
        }
        //this.$router.push(`/employees/skilled-workers/profile/${this.profileId}/access`)
        return
      }
      /*
      this.loading = true
      const data = {
        candidate_profile_id: this.profileId,
        product_slug: STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS,
        return_url: `${process.env.VUE_APP_SITE_URL}/employees/skilled-workers/profile/${this.profileId}/access/checkout-complete`,
        idempotencyKey: nanoid(),
      }
      const defaultError = new Error("We couldn't submit your payment request.")
      try {
        const response = await this.$store.dispatch(
          "api/createPaymentIntent",
          data
        )
        if (!response.data || response.data.errors) {
          throw response.data?.errors[0] ?? defaultError
        }
        const paymentIntent = response.data
        this.loading = false
        if (paymentIntent) {
          const baseUrl = this.isSkilledWorker
            ? "/employees/skilled-workers/profile"
            : "/employees/search/profile"
          this.$router.push(
            `${baseUrl}/${this.profileId}/access?payment_intent=${paymentIntent.id}`
          )
        } else {
          throw defaultError
        }
      } catch (error) {
        this.showErrorMessage(error)
        this.loading = false
      }
      */
    },
  },
}
</script>

<style scoped></style>
