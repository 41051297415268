<template>
  <div>
    <!-- <b-button
      v-b-modal.add-new-card
      variant="link"
      class="orange-clr"
      @click="setupIntent"
      >Add Card</b-button
    > -->
    <DefaultButton
      v-b-modal.add-new-card
      :labelText="'Add Card'"
      @click="setupIntent"
    />
    <b-modal id="add-new-card" hide-footer>
      <h1>Add New Card</h1>
      <form class="mt-4">
        <b-row class="mt-4">
          <div id="payment-element" class="payment-element">
            <!-- Stripe will mount elements here -->
          </div>
        </b-row>
        <div class="text-right">
          <b-button class="btn btn-orange btn-rounded" @click="addNewCard"
            >Submit</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import stripeMixin from "@/mixins/stripeMixin"
import DefaultButton from "@/components/DefaultButton"

export default {
  name: "addCardModal",
  mixins: [stripeMixin],
  components: { DefaultButton },
  data() {
    return {
      paymentMethods: null,
      selectedPaymentMethod: null,
      fields: {
        name: this.$store.state.userProfile.name,
        address: this.$store.state.userProfile.address,
        city: this.$store.state.userProfile.city,
        state: this.$store.state.userProfile.state,
        zip: this.$store.state.userProfile.zip,
      },
      cardNumber: null,
      expiration: null,
      customer: "",
      pastPurchases: [],
      newCard: {
        number: "424242424242",
        exp_month: "12",
        exp_year: "2037",
        cvc: "578",
        name: "Hector Trevino",
        address: "123 Fake Street",
        city: "Carmel",
        state: "IN",
        zip: "46033",
      },
    }
  },
  async created() {
    //await this.getPaymentMethods()
    //await this.getCharges(this.customer)
  },
  methods: {
    async getPaymentMethods() {
      const response = await this.$store.dispatch("api/getPaymentMethods")
      if (response.data.errors) {
        this.logDevError(response.data?.errors?.[0])
      } else {
        const profile = this.$store.state.userProfile
        const defaultPaymentMethodId = profile?.defaultPaymentMethod
        const paymentMethods = response.data
        this.paymentMethods = paymentMethods ?? []
        const currentPaymentMethod = paymentMethods?.find(
          (paymentMethod) => paymentMethod.id === this.currentPaymentMethod
        )
        const defaultPaymentMethod = paymentMethods?.find(
          (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
        )
        const selectedPaymentMethod =
          currentPaymentMethod ??
          defaultPaymentMethod ??
          paymentMethods[0] ??
          null
        this.selectedPaymentMethod = selectedPaymentMethod
        this.cardNumber = "Ending in " + this.selectedPaymentMethod?.card?.last4
        this.expiration =
          this.selectedPaymentMethod?.card?.exp_month +
          "/" +
          this.selectedPaymentMethod?.card?.exp_year
        this.customer = this.selectedPaymentMethod?.customer
      }
    },
    async getCharges() {
      let pastPurchases = await this.$store.dispatch("api/getPaymentHistory")
      this.pastPurchases = pastPurchases.data
    },
    async setupIntent() {
      let intent = await this.$store.dispatch("api/createSetupIntent")
      console.log("intent", intent)
      let clientSecret = intent.data.client_secret
      this.mountStripeElements(clientSecret)
    },
    async addNewCard() {
      await this.confirmSetup()
      this.$root.$emit("bv::hide::modal", "add-new-card")
      window.location.reload()
    },
  },
}
</script>
<style lang="scss" scoped>
.payment-element {
  // Compensating for Stripe's weird -4px margin on their iframe
  padding: 12px;
  width: 100%;
}
.btn.btn-orange {
  background: #f77f00;
  border-color: #f77f00;
  color: #ffffff;
}
</style>
