<template>
  <div class="row">
    <profile-section-header title="Background Information" />
    <ProfileSubSectionHeader title="Work History" icon="work-history-icon" />
    <v-list class="experience-list">
      <template v-for="(experience, index) in experiences">
        <v-card :key="index">
          <v-list-group v-if="experience.dailyResponsibilities" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ experience.position }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ experience.from }} -
                  {{
                    experience.current ? "Till date" : experience.to
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>

            <v-list-item-content>
              <v-list-item-title class="description">{{
                experience.dailyResponsibilities
                  ? experience.dailyResponsibilities
                  : "No description provided"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-group>

          <v-list-item v-else>
            <v-list-item-content>
              <v-list-item-title
                v-text="experience.position"
              ></v-list-item-title>
              <v-list-item-subtitle
                >{{ experience.from }} -
                {{
                  experience.current ? "Till date" : experience.to
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
    </v-list>
    <empty-profile-item-message v-if="isEditing && experiences.length < 1" />
    <p class="has-references">
      <PhCheckCircle
        color="#10C95A"
        weight="fill"
        :size="32"
        v-if="profile.jobSeeker.hasReferences"
      />
      <PhXCircle color="#B0B9C9" weight="fill" :size="32" v-else />
      Professional References Available
    </p>
  </div>
</template>

<script>
import EmptyProfileItemMessage from "@/components/Profile/EmptyProfileItemMessage"
import ProfileSectionHeader from "../ProfileSectionHeader.vue"
import ProfileSubSectionHeader from "../ProfileSubSectionHeader.vue"
import { PhXCircle, PhCheckCircle } from "phosphor-vue"

export default {
  name: "candidate-profile-work",
  components: {
    EmptyProfileItemMessage,
    ProfileSectionHeader,
    ProfileSubSectionHeader,
    PhCheckCircle,
    PhXCircle,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      redacted: this.profile.redacted,
      experiences: this.profile.jobSeeker?.previousCompaniesWorkedFor ?? [],
    }
  },
}
</script>

<style lang="scss" scoped>
.has-references {
  font-weight: 600;
  color: var(--Gray-800, #3a4252);
  margin-top: 32px;
  width: 100%;
}
</style>
