<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Invest in priority access to this skilled worker and stay ahead of your
        competition!
      </span></template
    >
    <template v-slot:actions-right>
      <b-button variant="link" :to="backUrlPath" class="mr-1 orange-clr"
        >Go Back</b-button
      >
      <base-button
        type="success"
        @click="handleConfirm"
        class="rounded-lg"
        :loading="loading"
        :disabled="!product"
        >Confirm</base-button
      >
    </template>

    <div v-if="product" class="py-4 px-6">
      <div v-if="!checkout">
        <h1>Congratulations, priority access is still available</h1>
        <p class="mb-5">
          Priority access is designed to give you exclusive access to the
          skilled worker you need for your team. A ${{ product.amount / 100 }}
          investment gives you exclusive priority access among only a limited
          amount of companies to compete for this skilled worker from initial
          recruitment to a potential interview. Submit your job briefing now
          before it is too late!
        </p>
        <h2 class="text-center mb-4">Why invest in Priority Access</h2>
        <b-container class="mb-5">
          <b-row>
            <b-col
              cols="12"
              lg="4"
              v-for="feature in features"
              :key="feature.title"
            >
              <div class="d-flex flex-column align-items-center text-center">
                <icon
                  class="orange-clr mb-2"
                  :icon="feature.icon"
                  width="64"
                  height="64"
                ></icon>
                <h3>{{ feature.title }}</h3>
                <p>{{ feature.description }}</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <product-purchase
        v-if="product"
        ref="productPurchase"
        :product="product"
        :loading="loading"
        :dataRetrieving="dataRetrieving"
        :returnUrlPath="returnUrlPath"
        @set-loading="setLoading"
        @set-data-retrieving="setDataRetrieving"
        @goToCheckout="goToCheckout"
      >
      </product-purchase>
      <!--h2 class="mb-4">Your Purchase</h2>
        <b-row class="ml-4 mb-4">
          <b-col cols="12" lg="9">
              <span> {{product.name}} </span>
          </b-col>
          <b-col cols="12" lg="3">
        <b> ${{product.amount / 100}} </b>
          </b-col>
        </b-row>
        <h2 class="mb-4">Payment Information</h2>
        <b-row class="ml-4 mb-4">
          <b-col cols="12" lg="6">
            <base-input placeholder="0000 0000 0000 0000" type="text" v-model="card">
            </base-input>
          </b-col>
          <b-col cols="12" lg="3">
            <base-input placeholder="Exp" type="text" v-model="expiration">
            </base-input>
          </b-col>
          <b-col cols="12" lg="3">
            <base-input placeholder="CVV/CVC" type="text" v-model="ccv">
            </base-input>
          </b-col>
        </b-row>
        <span> *Your card will be saved in Toolbox to make future connections easier </span-->
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import ProductPurchase from "@/components/Payment/ProductPurchase"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import messagesMixin from "@/mixins/messagesMixin"
import paymentsMixin from "@/mixins/paymentsMixin"
import stripeMixin from "@/mixins/stripeMixin"
import { STRIPE_PRODUCTS } from "@/utils/constants"

export default {
  name: "EmployeeAccess",
  mixins: [messagesMixin, paymentsMixin, stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
    ProductPurchase,
  },
  data() {
    const profileId = this.$router.currentRoute.params?.profileId
    const isSkilledWorker =
      this.$router.currentRoute.path.includes("skilled-workers")
    return {
      productSlug: isSkilledWorker
        ? STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS
        : STRIPE_PRODUCTS.CANDIDATE_JOB_BRIEFING,
      product: null,
      loading: false,
      dataRetrieving: false,
      returnUrlPath: `/employees/skilled-workers/profile/${profileId}/access/checkout-complete`,
      backUrlPath: `/employees/skilled-workers/profile/${profileId}`,
      features: [
        {
          icon: "stopwatch",
          title: "Demand",
          description:
            "Take advantage of hard to find skilled workers while they are available and before they are lost to your competition!",
        },
        {
          icon: "newspaper",
          title: "Growth",
          description:
            "Skilled workers reduce the amount of time and resources spent training, which accelerates the growth of your business.",
        },
        {
          icon: "currency-circulation",
          title: "Feedback",
          description:
            "Gain valuable feedback with each job briefing sent so you know if your compensation and benefits are competitive.",
        },
      ],
      card: "",
      expiration: "",
      ccv: "",
      checkout: false,
    }
  },
  async created() {
    const product = await this.getProduct(this.productSlug)
    this.product = product
    this.dataRetrieving = false
  },
  methods: {
    async handleConfirm() {
      this.$refs.productPurchase.handleConfirm()
    },
    setLoading(loading) {
      this.loading = loading
    },
    setDataRetrieving(isRetrieving) {
      this.dataRetrieving = isRetrieving
    },
    goToCheckout() {
      console.log("CHECK")
      this.checkout = !this.checkout
    },
  },
}
</script>

<style scoped></style>
