<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left><div></div></template>
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="'Back to Matches'"
        @click="$router.push(`/trade-partners/projects/${projectId}/matches`)"
      />
      <!--b-button
        variant="danger"
        @click="handleRemoveMatch"
        class="mr-3 rounded-lg"
        >Remove from Matches</b-button
      -->
      <DefaultButton
        v-if="!apply && !accepted"
        :labelText="'Accept'"
        @click="handleAcceptMatch"
      />
    </template>
    <div v-if="!apply">
      <profile :hide="false"></profile>
    </div>
    <div v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-xl-7">
            <h1 class="mt-4 job-title">
              Congratulations, you are now connected
            </h1>
            <span
              >You can now connect on project details for
              {{ project.name }} project,</span
            >
            <h4 class="mt-4 mb-4">
              Project Posted by: {{ $store.state.userProfile.name }}
            </h4>
            <div class="row justify-content-center">
              <div class="col-6">
                <svg
                  class="mr-2"
                  width="20"
                  height="21"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.2133 22.0633L25.0179 17.8679C23.5196 16.3695 20.9724 16.9689 20.373 18.9168C19.9235 20.2653 18.4251 21.0145 17.0766 20.7148C14.0799 19.9656 10.0344 16.0699 9.28518 12.9233C8.83567 11.5747 9.73468 10.0764 11.0832 9.62694C13.0311 9.0276 13.6304 6.48039 12.1321 4.98204L7.93666 0.786637C6.73797 -0.262212 4.93994 -0.262212 3.89109 0.786637L1.04422 3.63351C-1.80266 6.63023 1.34389 14.5715 8.38616 21.6138C15.4284 28.6561 23.3697 31.9525 26.3664 28.9557L29.2133 26.1089C30.2622 24.9102 30.2622 23.1121 29.2133 22.0633Z"
                    fill="#003049"
                  />
                </svg>
                <span>{{ $store.state.userProfile.phoneNumber }}</span>
              </div>
              <div class="col-6">
                <svg
                  class="mr-2"
                  width="25"
                  height="30"
                  viewBox="0 0 30 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.6063 0.172884C29.3188 -0.0308658 28.9413 -0.0583658 28.6288 0.105384L0.503806 14.7929C0.171306 14.9666 -0.0249444 15.3216 0.0025556 15.6954C0.0313056 16.0704 0.280056 16.3904 0.633806 16.5116L8.45256 19.1841L25.1038 4.94663L12.2188 20.4704L25.3226 24.9491C25.4201 24.9816 25.5226 24.9991 25.6251 24.9991C25.7951 24.9991 25.9638 24.9529 26.1126 24.8629C26.3501 24.7179 26.5113 24.4741 26.5526 24.2004L29.9901 1.07538C30.0413 0.725384 29.8938 0.377884 29.6063 0.172884Z"
                    fill="#003049"
                  />
                </svg>
                <span>{{ $store.state.userProfile.email }}</span>
              </div>
            </div>

            <h4 class="mt-4 mb-4">
              Interested Company: {{ interestedCompany.name }}
            </h4>
            <div class="row justify-content-center">
              <div class="col-6">
                <svg
                  class="mr-2"
                  width="20"
                  height="21"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.2133 22.0633L25.0179 17.8679C23.5196 16.3695 20.9724 16.9689 20.373 18.9168C19.9235 20.2653 18.4251 21.0145 17.0766 20.7148C14.0799 19.9656 10.0344 16.0699 9.28518 12.9233C8.83567 11.5747 9.73468 10.0764 11.0832 9.62694C13.0311 9.0276 13.6304 6.48039 12.1321 4.98204L7.93666 0.786637C6.73797 -0.262212 4.93994 -0.262212 3.89109 0.786637L1.04422 3.63351C-1.80266 6.63023 1.34389 14.5715 8.38616 21.6138C15.4284 28.6561 23.3697 31.9525 26.3664 28.9557L29.2133 26.1089C30.2622 24.9102 30.2622 23.1121 29.2133 22.0633Z"
                    fill="#003049"
                  />
                </svg>
                <span>{{ interestedCompany.phoneNumber }}</span>
              </div>
              <div class="col-6">
                <svg
                  class="mr-2"
                  width="25"
                  height="30"
                  viewBox="0 0 30 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.6063 0.172884C29.3188 -0.0308658 28.9413 -0.0583658 28.6288 0.105384L0.503806 14.7929C0.171306 14.9666 -0.0249444 15.3216 0.0025556 15.6954C0.0313056 16.0704 0.280056 16.3904 0.633806 16.5116L8.45256 19.1841L25.1038 4.94663L12.2188 20.4704L25.3226 24.9491C25.4201 24.9816 25.5226 24.9991 25.6251 24.9991C25.7951 24.9991 25.9638 24.9529 26.1126 24.8629C26.3501 24.7179 26.5113 24.4741 26.5526 24.2004L29.9901 1.07538C30.0413 0.725384 29.8938 0.377884 29.6063 0.172884Z"
                    fill="#003049"
                  />
                </svg>
                <span>{{ interestedCompany.email }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <b-button variant="secondary" class="rounded-lg apply-btn"
            >Back to Search</b-button
          >
          <DefaultButton
            :labelText="'Back to Search'"
            @click="$router.push('/trade-partners/search-projects')"
          />
          <DefaultButton
            :labelText="'View Project'"
            @click="$router.push(`/trade-partners/projects/${projectId}/edit`)"
          />
        </div>
      </div>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import profile from "@/api/profile"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
  },
  data() {
    const projectId = this.$router.currentRoute.params?.projectId
    return {
      projectId,
      apply: false,
      project: {},
      interestedCompany: {},
      accepted: false,
    }
  },
  methods: {
    /*
    async handleRemoveMatch() {
      let profileId = this.$router.currentRoute.params.profileId
      let projectId = this.projectId

      let result = await profile.rejectProfileAccess(
        profileId,
        projectId,
        "project",
        null
      )
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      //if (result.data.id) {
      //  this.apply = true
        this.$router.push(
          "/trade-partners/projects/" + this.projectId + "/matches"
        )
      //}
    },
    */
    async handleAcceptMatch() {
      let profileId = this.$router.currentRoute.params.profileId
      let projectId = this.projectId

      await profile.grantProfileAccess(profileId, projectId, "project")
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      //if (result.data.id) {
      this.apply = true
      /*
        this.$router.push(
          "/trade-partners/projects/" + this.projectId + "/matches"
        )
        */
      //}
    },
    getMatches() {
      const profileId = this.$router.currentRoute.params.profileId
      const projectId = this.$router.currentRoute.params?.projectId
      let accepted = this.$store.state.userProfile.profilesGranted
      for (let a of accepted) {
        if (a.profile == profileId && a.project == projectId) {
          this.accepted = true
        }
      }
    },
    async getProject() {
      await this.$store
        .dispatch("projects/dispatchFetchProjects")
        .finally(() => (this.loading = false))
      let projects = this.$store.state.projects.list
      let projectId = this.$router.currentRoute.params.projectId
      for (let p of projects) {
        if (p._id == projectId) {
          this.project = p
          this.getMatches()
          break
        }
      }
    },
    async getCompanyProfile() {
      try {
        const list = await this.$store.dispatch("api/getProfile", {
          profileId: this.$router.currentRoute.params.profileId,
        })
        this.interestedCompany = list.data
      } catch (error) {
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
    },
  },
  mounted() {
    this.getProject()
    this.getCompanyProfile()
  },
}
</script>

<style scoped></style>
