<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        View your pending job briefings here.
      </span></template
    >
    <v-client-table
      :data="jobsWithMatches"
      :columns="seekerBriefingColumns"
      :options="options"
    >
      <template slot="createdAt" slot-scope="props">
        {{ $dayjs(props.row.createdAt).format("MM-DD-YYYY") }}
      </template>
      <template slot="matches" slot-scope="props">
        {{ props.row.matches || 0 }}
      </template>
      <template slot="action" slot-scope="props" class="cursor-pointer">
        <b-button variant="link" :to="`/jobs/${props.row._id}`">View</b-button>
      </template>
    </v-client-table>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  name: "Posts",
  components: {
    InnerLayoutActionBarWrapper,
  },
  data() {
    return {
      options: {
        headings: {
          type: "Project type",
          createdAt: "Posted On",
        },
      },
      seekerBriefingColumns: ["name", "type", "createdAt", "action"],
      loading: false,
      jobsWithMatches: [],
    }
  },
  created() {
    this.getJobBriefingMatches()
  },
  methods: {
    async getJobBriefingMatches() {
      const profileId = this.$store.state.userProfile?._id
      this.$store.dispatch("api/updateProfile", {
        profileId,
        data: { lastActivity: new Date() },
      })
      let requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        if (r.type == "job") {
          let job = await this.$store.dispatch("jobs/dispatchFetchJobById", {
            id: r.job,
          })
          console.log("job", job)
          //job.status = "Pending";
          this.jobsWithMatches.push(job)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn-active {
  background: #f77f00;
  color: white;
}
.btn-default {
  background: rgba(123, 128, 157, 0.15);
  border-color: transparent;
  color: #555b79;
}
</style>
