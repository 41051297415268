import axios from "@/utils/api-v1"
// import store from '@/store/store.js'

export default {
  login(email, pwd) {
    return axios.post("/api/users", {
      email,
      password: pwd,
    })
  },
  registerUser(email) {
    return axios.post("/api/users/register", {
      email,
    })
  },
  getUser(userId) {
    return axios.get(`/api/users/${userId}`)
  },
  requestPasswordReset(email) {
    return axios.post("/api/users/requestPasswordReset", {
      email,
    })
  },
  confirmUserEmail(emailConfirmationKey, password) {
    return axios.post("/api/users/confirmEmail", {
      emailConfirmationKey,
      password,
    })
  },
  changeUserPassword(passwordResetKey, password) {
    return axios.post("/api/users/changeUserPassword", {
      passwordResetKey,
      password,
    })
  },
  deleteUser(id) {
    return axios.delete(`/api/users/${id}`)
  },
}
