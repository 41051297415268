<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-9 col-xl-7">
        <b-row>
          <h2>You have declined the job briefing from {{ company }}</h2>
        </b-row>
        <b-row>
          <p>
            Sorry to hear this was not a good fit. It is important for our
            companies to learn why this job briefing was not ideal for you.
            Please leave your feedback as to why you have declined this job
            briefing.
          </p>
        </b-row>
        <b-row>
          <h2>Review your experience</h2>
        </b-row>
        <b-row>
          <b-form-group
            label="Please select all that apply"
            v-slot="{ ariaDescribedby }"
          >
            <b-checkbox
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="salary"
            >
              The salary was not competitive or ideal for the position
              requirements
            </b-checkbox>
            <b-checkbox
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="benefits"
            >
              The benefits package was not ideal to me
            </b-checkbox>
            <b-checkbox
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="requirements"
            >
              The position requirements were not ideal to me
            </b-checkbox>
            <b-checkbox
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="location"
            >
              The position location was not ideal to me
            </b-checkbox>
            <b-checkbox
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="other"
            >
              Other:
            </b-checkbox>
            <b-form-textarea
              v-model="details"
              class="mb-4"
              placeholder="Please describe why this job briefing was not ideal for you"
            ></b-form-textarea>
            <b-button
              variant="secondary"
              @click="handleSubmitFeedback"
              class="rounded-lg apply-btn"
              >Submit</b-button
            >
          </b-form-group>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import profile from "@/api/profile"
export default {
  components: {},
  props: {
    company: String,
    companyId: String,
  },
  data() {
    return {
      reasons: {
        salary: "",
        benefits: "",
        requirements: "",
        location: "",
        other: "",
      },
      selected: [],
      details: "",
    }
  },
  methods: {
    async handleSubmitFeedback() {
      console.log("handleSubmitFeedback")
      let id = this.$router.currentRoute.params.jobId
      let type = "job"
      await profile.rejectProfileAccess(this.companyId, id, type, {
        selected: this.selected,
        details: this.details,
      })
      this.$router.push("/dashboard")
    },
  },
}
</script>

<style></style>
