<template>
  <div class="home">
    <header class="bg-white header header-transparent position-sticky top-0">
      <div class="container-fluid">
        <!-- Brand-->
        <div class="inner-header">
          <a class="inner-brand" href="/">
            <img src="/img/home/logo.png" alt="" />
          </a>
        </div>
        <!-- Navigation-->
        <div class="inner-navigation collapse">
          <div class="inner-nav">
            <!--ul>
              <li>
                <a href="#!" class="active"
                  ><span class="menu-item-span">About Us</span></a
                >
              </li>
              <li>
                <a href="#!"
                  ><span class="menu-item-span">Our Services</span></a
                >
              </li>
              <li>
                <a href="#!"><span class="menu-item-span">Contractors</span></a>
              </li>
              <li>
                <a href="#!"><span class="menu-item-span">Vendors</span></a>
              </li>
              <li>
                <a href="#!"
                  ><span class="menu-item-span">Organizations</span></a
                >
              </li>
              <li>
                <a href="#!"><span class="menu-item-span">Job Seekers</span></a>
              </li>
              <li>
                <a href="#!"><span class="menu-item-span">Blog</span></a>
              </li>
            </ul-->
          </div>
        </div>
        <div class="extra-nav">
          <ul>
            <li>
              <button
                @click="handleSignInClick"
                class="login menu-item-span btn btn-orange btn-rounded btn-orange-outline mr-3"
              >
                Sign In
              </button>
            </li>
            <li>
              <button
                @click="handleRegisterClick"
                class="menu-item-span btn btn-orange btn-rounded"
              >
                Sign Up
              </button>
            </li>
            <li class="nav-toggle">
              <a href="#" data-toggle="collapse" data-target=".inner-navigation"
                ><span class="menu-item-span"><i class="ti-menu"></i></span
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <section id="contact" class="p-0">
      <div class="container-fluid">
        <div
          class="row align-items-center bg-img4 header-banner"
          style="
            background-image: url(/img/home/banner-4.png);
            background-size: cover;
            background-position-y: center;
          "
        >
          <div class="col-md-6 p-0">
            <div class="banner-box mb-0 bg-img4">
              <div
                class="banner-content text-center text-white position-relative"
              >
                <h2 class="font-48-800 mb-4 pb-3">Businesses / Companies</h2>
                <p class="mb-4 pb-3 text-white">
                  Find quality skilled labor crews and workers in minutes! We
                  will recruit them directly to you so you can focus on your
                  business!!
                </p>
                <button
                  @click="handleRegisterClick"
                  data-profileType="provider"
                  class="mb-4 btn btn-large btn-orange"
                  href="#!"
                >
                  Sign Up For FREE
                </button>
                <p class="d-block mb-3 alpha-gray">No Credit Card Required</p>
                <u
                  class="font-24-700 mt-9 learn-more"
                  @click="learnMore = 'company'"
                  >Learn More
                </u>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <img
                @click="learnMore = 'company'"
                class="img-fluid center-image collapse-image"
                src="/img/home/open-collapse-1.png"
                style="height: 36px; filter: brightness(2.5)"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 p-0">
            <div class="banner-box mb-0 bg-img4">
              <div
                class="banner-content text-center text-white position-relative"
              >
                <h2 class="font-48-800 mb-4 pb-3">Workers / Job Seekers</h2>
                <p class="mb-4 pb-3 text-white">
                  Find and compare high-paying careers in the skilled trades
                  industry — no college degree or experience required
                </p>
                <button
                  @click="handleRegisterClick"
                  data-profileType="candidate"
                  class="mb-4 gray-text btn btn-large btn-success"
                  href="/signup"
                >
                  Sign Up for FREE
                </button>
                <p class="d-block mb-3 alpha-gray">No Credit Card Required</p>
                <u
                  class="font-24-700 mt-9 learn-more"
                  @click="learnMore = 'jobSeeker'"
                  >Learn More
                </u>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <img
                @click="learnMore = 'jobSeeker'"
                class="img-fluid center-image collapse-image"
                src="/img/home/open-collapse-1.png"
                style="height: 36px; filter: brightness(2.5)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact-mobile" class="p-0">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 p-0">
            <div
              class="banner-box mb-0 bg-img4"
              style="background-image: url(/img/home/banner-4.png)"
            >
              <div
                class="banner-content text-center text-white position-relative"
              >
                <h2 class="font-48-800 mb-4 pt-3">Find the Perfect Career.</h2>
                <p
                  class="mb-4 pb-3 pl-3 pr-3 text-white"
                  style="font-weight: 400"
                >
                  Build your free, private skills profile and find the resources
                  you need to elevate your career or business
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">
            <button
              @click="handleRegisterClick"
              data-profileType="candidate"
              class="mb-4 gray-text btn btn-warning btn-mobile"
              href="/signup"
            >
              Register as Company
            </button>
          </div>
          <div class="col-6 p-0">
            <button
              @click="handleRegisterClick"
              data-profileType="candidate"
              class="mb-4 gray-text btn btn-success btn-mobile"
              href="/signup"
            >
              Register as Job Seeker
            </button>
          </div>
        </div>
        <div class="row">
          <div
            class="col-6"
            style="margin: auto; text-align: center"
            @click="learnMore = 'company'"
          >
            <u>Learn More</u>
          </div>
          <div
            class="col-6 p-0"
            style="margin: auto; text-align: center"
            @click="learnMore = 'jobSeeker'"
          >
            <u>Learn More</u>
          </div>
        </div>
      </div>
    </section>
    <section id="job-seekers" v-if="learnMore == 'jobSeeker'">
      <div class="container-fluid pl-4 pr-4">
        <h2 class="font-78-800 text-center mb-4">Workers / Job Seekers</h2>
        <div class="row align-items-center">
          <div class="col-lg-6 py-5 pr-2">
            <p class="font-35-500 text-center">
              Are you a skilled worker looking for a new opportunity OR are you
              interested in a career change and college is not for you? Our
              technology can allow you to compare new potential job offers in
              minutes! Join our network FREE to find an exciting high-paying
              career or paid training opportunities in the skilled trades
              industry and give yourself a raise today!
            </p>
            <div class="text-center">
              <button
                @click="handleRegisterClick"
                data-profileType="candidate"
                class="btn btn-medium btn-orange btn-rounded"
              >
                Join Our Network
              </button>
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <img
                class="img-fluid"
                src="/img/home/workers-seekers.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <h2 class="font-78-800 text-center mb-4 mt-4">
          Browse and Discover Jobs Near You
        </h2>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div>
              <img class="img-fluid" src="/img/home/businesses.png" alt="" />
            </div>
          </div>
          <div class="col-lg-6 py-5 pl-2">
            <ul style="line-height: 3">
              <li>
                Browse high-paying careers for FREE - no college degree
                required!
              </li>
              <li>
                Transition into a rewarding, high demand career with growth
                potential
              </li>
              <li>
                Get paid to learn on the job with no prior experience or
                training
              </li>
              <li>
                Already trained or skilled? Get competitive job offers while you
                sleep!
              </li>
            </ul>
            <div class="text-center">
              <button
                @click="handleRegisterClick"
                data-profileType="candidate"
                class="btn btn-medium btn-dark-white btn-rounded"
              >
                Start Browsing
              </button>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6 py-5 pl-2">
            <h2 class="font-48-800 text-center mb-4 mt-4">Stay Connected</h2>
            <ul
              style="line-height: 3; list-style-position: inside"
              class="text-center"
            >
              <li>Network and connect with valuable industry resources</li>
              <li>Take advantage of our innovative technology tools</li>
              <li>
                Get the latest industry news, updates, products, and promotions
              </li>
              <li>Build a professional reputation - make yourself valuable!</li>
            </ul>
            <div class="text-center">
              <button
                @click="handleRegisterClick"
                data-profileType="candidate"
                class="btn btn-medium btn-orange btn-rounded"
              >
                Are you in?
              </button>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="">
              <img
                class="img-fluid"
                src="/img/home/stay-connected.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-5">
          <img
            @click="learnMore = null"
            class="img-fluid center-image collapse-image"
            src="/img/home/collapse.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section id="companies" v-if="learnMore == 'company'">
      <div class="container-fluid pl-4 pr-4">
        <h2 class="font-78-800 text-center mb-4">Businesses</h2>
        <div class="row align-items-center">
          <div class="col-lg-6 py-5 pl-2">
            <p class="font-35-500 text-center">
              Join our network FREE if you are a business or independent
              contractor that provides services in the construction or skilled
              trades industry. Typical businesses include general contractors,
              developers, home builders, and subcontractors in all sectors
              (residential, commercial, industrial, civil/highway)
            </p>
            <div class="text-center">
              <button
                @click="handleRegisterClick"
                data-profileType="candidate"
                class="btn btn-medium btn-orange btn-rounded"
              >
                Join Our Network
              </button>
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <img class="img-fluid" src="/img/home/businesses.png" alt="" />
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6 pl-2 text-center">
            <div>
              <img
                style="width: 600px"
                class="img-fluid"
                src="/img/home/contractors.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-6 py-5 pl-2">
            <h2 class="font-38-800 pb-4 text-center">Contractors</h2>
            <ul
              style="line-height: 3; list-style-position: inside"
              class="text-center"
            >
              <li>
                We recruit skilled workers directly to you - receive instant
                alerts
              </li>
              <li>
                Review profiles, build connections, expand your professional
                network
              </li>
              <li>
                Find labor and material resources immediately and minimize
                delays
              </li>
              <li>
                Create a post in minutes and get results - save your business
                hours of time
              </li>
              <li>
                Get the latest industry news, updates, products, and promotions
              </li>
            </ul>
            <div class="text-center">
              <button
                @click="handleRegisterClick"
                data-profileType="vendor"
                class="btn btn-medium btn-dark-white btn-rounded"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6 py-5 pl-2">
            <h2 class="font-38-800 pb-4 text-center">Vendors</h2>
            <ul
              style="line-height: 3; list-style-position: inside"
              class="text-center"
            >
              <li>
                Post jobs to our network and fulfill your sales, installation,
                and warehousing staff
              </li>
              <li>
                Interact directly with the trade businesses that buy your
                products everyday
              </li>
              <li>
                Find and review new projects in your area and grow your sales
                revenues with ease
              </li>
              <li>
                Promote you business and products - set yourself apart from your
                competition
              </li>
              <li>
                Get the latest industry news, updates, products, and promotions
              </li>
            </ul>
            <div class="text-center">
              <button
                @click="handleRegisterClick"
                data-profileType="educational"
                class="btn btn-medium btn-orange btn-rounded"
              >
                Sign Up
              </button>
            </div>
          </div>
          <div class="col-lg-6 pl-2">
            <div class="text-center">
              <img
                style="width: 600px"
                class="img-fluid"
                src="/img/home/vendors.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-5">
          <img
            @click="learnMore = null"
            class="img-fluid center-image collapse-image"
            src="/img/home/collapse.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section id="join-our-network">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h2 class="font-78-800 text-center mb-4">Join Our Network</h2>
            <div class="row pt-4">
              <div class="col-md-3 mb-4">
                <div class="services-box text-left">
                  <!--span class="services-box-icon">
                    <img src="/img/home/growth-icon.png" alt="" />
                  </span-->
                  <h6 class="font-24-700 mb-4">Job Seekers</h6>
                  <p>
                    Find exciting career opportunities for experienced workers
                    or paid apprenticeships for anyone looking to make a career
                    change!
                  </p>
                </div>
              </div>
              <div class="col-md-3 mb-4">
                <div class="services-box text-left">
                  <!--span class="services-box-icon">
                    <img src="/img/home/graduation-icon.png" alt="" />
                  </span-->
                  <h6 class="font-24-700 mb-4">Trade Workers</h6>
                  <p>
                    Build your reputation as a professional craftsman, check out
                    industry trends, job posts, or receive potential job offers
                    with one click!
                  </p>
                </div>
              </div>
              <div class="col-md-3 mb-4">
                <div class="services-box text-left">
                  <!--span class="services-box-icon">
                    <img src="/img/home/place-icon.png" alt="" />
                  </span-->
                  <h6 class="font-24-700 mb-4">Trade Businesses</h6>
                  <p>
                    Take Advantage of our technology tools to quickly locate
                    labor and material resources. Build your network and grow
                    your business!
                  </p>
                </div>
              </div>
              <div class="col-md-3 mb-4">
                <div class="services-box text-left">
                  <!--span class="services-box-icon">
                    <img src="/img/home/place-icon.png" alt="" />
                  </span-->
                  <h6 class="font-24-700 mb-4">Product Vendors</h6>
                  <p>
                    Find and build new relationships directly with businesses
                    that buy your products. Quickly grow your sales network and
                    revenues!
                  </p>
                </div>
              </div>
            </div>
            <div class="row register-buttons">
              <div class="col-md-3 mb-4">
                <button
                  @click="handleRegisterClick"
                  data-profileType="candidate"
                  class="mb-4 gray-text btn btn-large btn-orange get-started"
                  href="/signup"
                >
                  Get Started
                </button>
              </div>
              <div class="col-md-3 mb-4">
                <button
                  @click="handleRegisterClick"
                  data-profileType="candidate"
                  class="mb-4 gray-text btn btn-large btn-orange get-started"
                  href="/signup"
                >
                  Get Started
                </button>
              </div>
              <div class="col-md-3 mb-4">
                <button
                  @click="handleRegisterClick"
                  data-profileType="candidate"
                  class="mb-4 gray-text btn btn-large btn-orange get-started"
                  href="/signup"
                >
                  Get Started
                </button>
              </div>
              <div class="col-md-3 mb-4">
                <button
                  @click="handleRegisterClick"
                  data-profileType="candidate"
                  class="mb-4 gray-text btn btn-large btn-orange get-started"
                  href="/signup"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="great-things" class="yellow-break">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <h2 class="font-48-800 text-center mb-4">
              Great Things Are Built Together - Are You In?
            </h2>
          </div>
        </div>
      </div>
    </section>
    <section id="our-services" class="dark-bg">
      <div class="container-fluid text-white">
        <div class="row">
          <div class="col-md-5 mb-4">
            <h2 class="text-left text-white" id="here-to-help">
              WE'RE HERE TO HELP.
            </h2>
          </div>
          <div class="col-md-7 mb-4">
            <div class="row">
              <div class="col-md-6 mb-4">
                <div class="services-box text-left">
                  <span class="services-box-icon">
                    <img src="/img/home/graduation-icon.png" alt="" />
                  </span>
                  <h6 class="font-24-700 mb-4">Training</h6>
                  <p class="text-white">
                    If you're reliable and willing to learn, there's a place for
                    you in the building trades industry! We have the best
                    resources to match you with a paid apprenticeship,
                    vocational program, or employer looking to hire and train
                    you today.
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="services-box text-left">
                  <span class="services-box-icon">
                    <img src="/img/home/place-icon.png" alt="" />
                  </span>
                  <h6 class="font-24-700 mb-4">Local Resources</h6>
                  <p class="text-white">
                    Quickly find local general contractors, subcontractors,
                    specialty crews, employees, and other personnel to
                    strengthen your team and grow your business - when you need
                    them and where you need them.
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="services-box text-left">
                  <span class="services-box-icon">
                    <img src="/img/home/faster-recruitment-icon.png" alt="" />
                  </span>
                  <h6 class="font-24-700 mb-4">Quality Recruitment</h6>
                  <p class="text-white">
                    Post a job, browse profiles of active job seekers, or let us
                    recruit skilled workers directly for you! Find the right
                    employment candidate or subcontractor crew to join your
                    workforce.
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="services-box text-left">
                  <span class="services-box-icon">
                    <img src="/img/home/growth-icon.png" alt="" />
                  </span>
                  <h6 class="font-24-700 mb-4">Business Growth</h6>
                  <p class="text-white">
                    Our unique site features and personnel recruitment
                    capabilities ensure you have quality options to increase
                    your business potential. Take on more projects and finish
                    projects faster to grow your revenues
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="services-box text-left">
                  <span class="services-box-icon">
                    <img src="/img/home/news-icon.png" alt="" />
                  </span>
                  <h6 class="font-24-700 mb-4">News</h6>
                  <p class="text-white">
                    Stay up to date on new innovative tools and materials,
                    discover the latest industry news and the ongoing efforts to
                    minimize the labor shortage by recruiting new and young
                    talent to the skilled trades industry.
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="services-box text-left">
                  <span class="services-box-icon">
                    <img src="/img/home/retention-icon.png" alt="" />
                  </span>
                  <h6 class="font-24-700 mb-4">Retention</h6>
                  <p class="text-white">
                    Our profile questionnaires ensure a thorough vetting process
                    that increases employee retention rates and helps
                    contractors locate the best labor crews for their projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="blog" style="padding-top: 0; padding-bottom: 0">
      <div class="container-fluid" style="padding-left: 0px">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div>
              <img class="img-fluid" src="/img/home/why-we-built.png" alt="" />
            </div>
          </div>
          <div class="col-lg-5 text-left" style="padding-left: 35px">
            <h2 class="font-48-800 pb-4">Why We Built GoFindBuild</h2>
            <p style="line-height: 3; font-size: 1.2rem">
              The number of open construction jobs averages between 300,000 to
              400,000+ each month across the nation. Our mission is to provide
              valuable labor resources to the businesses that need them most and
              provide great high-paying careers to anyone that does not want the
              burden of college debt or wants to find an easy transition into a
              rewarding new career in the skilled trades industry.
              <b>Let's build something Together - are you in?</b>
            </p>
            <!--button
              @click="handleRegisterClick"
              data-profileType="candidate"
              class="btn btn-medium btn-orange btn-rounded"
            >
              Read More
            </button-->
          </div>
        </div>
      </div>
    </section>
    <!--section id="job-seekers" class="orange-bg-500">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 py-5">
            <h2 class="font-38-800 pb-4">Companies</h2>
            <p>
              Join our network FREE if you are a business or independent
              contractor that provides services in the construction or skilled
              trades industry. Typical businesses include general contractors
              and subcontractors in all sectors (residential, commercial,
              industrial, civil/highway), developers, and home builders.
            </p>
            <button
              @click="handleRegisterClick"
              data-profileType="provider"
              class="btn btn-medium btn-orange btn-rounded"
            >
              Sign Up
            </button>
          </div>
          <div class="col-lg-7">
            <div class="text-right">
              <img
                style="width: 600px"
                class="img-fluid"
                src="/img/home/companies.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section-->
    <section id="job-seekers">
      <div class="container"></div>
    </section>
    <!--section id="job-seekers" class="orange-bg-500">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 py-5">
            <h2 class="font-38-800 pb-4">Organizations</h2>
            <p>
              Join us to contribute labor candidates, industry knowledge, or
              resources to our skilled trades network. Typical partners include
              workforce development, labor staffing, industry organizations,
              veteran’s networks, training and career centers, tool and material
              manufacturers, and general advertising to our network
            </p>
            <button
              @click="handleRegisterClick"
              data-profileType="educational"
              class="btn btn-medium btn-orange btn-rounded"
            >
              Sign Up
            </button>
          </div>
          <div class="col-lg-7">
            <div class="text-right">
              <img
                style="width: 600px"
                class="img-fluid"
                src="/img/home/organizations.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section-->
    <!-- <section id="Blog">
      <div class="container">
        <h2 class="font-38-800 pb-5 text-center">Blog</h2>
        <div class="row">
          <div class="col-lg-3">
            <div class="card blog-cards">
              <div class="blog-img-cover">
                <img src="/img/home/blog.jpg" class="card-img-top" alt="" />
              </div>
              <div class="card-body">
                <h5 class="card-title mb-4">
                  Header Two Lines Header Header Header Header Header
                </h5>
                <a href="#" class="btn tag-medium orange-bg-100 btn-rounded"
                  >Theme</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card blog-cards">
              <div class="blog-img-cover">
                <img src="/img/home/blog.jpg" class="card-img-top" alt="" />
              </div>
              <div class="card-body">
                <h5 class="card-title mb-4">
                  Header Two Lines Header Header Header Header Header
                </h5>
                <a href="#" class="btn tag-medium orange-bg-100 btn-rounded"
                  >Theme</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card blog-cards">
              <div class="blog-img-cover">
                <img src="/img/home/blog.jpg" class="card-img-top" alt="" />
              </div>
              <div class="card-body">
                <h5 class="card-title mb-4">
                  Header Two Lines Header Header Header Header Header
                </h5>
                <a href="#" class="btn tag-medium orange-bg-100 btn-rounded"
                  >Theme</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card blog-cards">
              <div class="blog-img-cover">
                <img src="/img/home/blog.jpg" class="card-img-top" alt="" />
              </div>
              <div class="card-body">
                <h5 class="card-title mb-4">
                  Header Two Lines Header Header Header Header Header
                </h5>
                <a href="#" class="btn tag-medium orange-bg-100 btn-rounded"
                  >Theme</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!--section class="orange-bg-900">
      <div class="container">
        <div class="row align-items-lg-center text-center text-lg-left space-2">
          <div class="col-lg-5">
            <h2 class="font-38-800 text-white">Not sure where you fit in?</h2>
            <p class="font-20-500 text-white mb-0 mr-lg-5 pr-lg-5">
              Find out how you can contribute to our network by singing up for
              our newsletter
            </p>
          </div>

          <div class="col-lg-5 offset-lg-2 text-lg-right">
            <form>
              <div class="card p-2 mb-3 rounded-pill">
                <div class="form-row input-group-borderless">
                  <div class="col-sm">
                    <input
                      type="text"
                      class="form-control shadow-none"
                      placeholder="Email"
                      aria-label="Search the knowledge base..."
                    />
                  </div>
                  <div class="col-sm-auto">
                    <button
                      type="submit"
                      class="btn btn-block btn-medium btn-rounded btn-orange"
                    >
                      <span class="ml-1">Search</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section-->
    <section id="footer" class="dark-bg py-4">
      <footer>
        <div class="py-4 font-weight-light bg-gray-800 text-gray-300">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-3 text-center text-md-left">
                <img src="/img/home/footer-logo.svg" alt="" />
              </div>
              <div class="col-md-7">
                <!-- <div class="footer-nav">
                  <ul>
                    <li><a href="#!">About Us</a></li>
                    <li><a href="#!">Our Services</a></li>
                    <li><a href="#!">Contractors</a></li>
                    <li><a href="#!">Vendors</a></li>
                    <li><a href="#!">Organizations</a></li>
                    <li><a href="#!">Job Seekers</a></li>
                    <li><a href="#!">Blog</a></li>
                  </ul>
                </div> -->
              </div>
              <div class="col-md-2">
                <div
                  class="text-center text-md-right d-flex justify-content-end"
                >
                  <ul class="footer-socail">
                    <li>
                      <a href="https://www.instagram.com/gofindbuild"
                        ><i class="fab fa-instagram"></i
                      ></a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/gofindbuild"
                        ><i class="fab fa-facebook-f"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <hr class="white-line" />
        </div>

        <div class="py-4 font-weight-light bg-gray-800 text-gray-300">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 text-center text-md-left">
                <p class="mb-md-0 font-18-500 alpha-gray">
                  P.O Box 503144, Indianapolis, IN 46250
                </p>
              </div>
              <div class="col-md-6">
                <div class="text-center text-md-right alpha-gray">
                  <p class="mb-md-0 font-18-500">
                    © GoFindBuild LLC. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  name: "home-page",
  data() {
    return {
      learnMore: null,
    }
  },
  methods: {
    handleRegisterClick(event) {
      const profileType =
        event.target.getAttribute("data-profileType") ?? "candidate"
      const path = `signup`
      this.$router.push({
        path,
        query: { profileType },
      })
    },
    handleSignInClick() {
      const path = `login`
      this.$router.push({
        path,
      })
    },
  },
}
</script>

<style scoped>
.collapse-image {
  cursor: pointer;
}
.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.yellow-break {
  background: #fcbf49;
  color: white;
}
.get-started {
  border-radius: 100px !important;
  width: 260px !important;
}
.learn-more {
  position: relative;
  top: 115px;
  cursor: pointer;
}
.font-112-800 {
  color: inherit;
  font-weight: 800;
  font-size: 125px;
  line-height: 152px;
}
.font-78-800 {
  color: inherit;
  font-weight: 800;
  font-size: 55px;
  line-height: 59px;
}
.font-48-800 {
  color: inherit;
  font-weight: 800;
  font-size: 40px;
  line-height: 59px;
}
.font-35-500 {
  color: inherit;
  font-weight: 500;
  font-size: 24px;
  line-height: 46px;
}
.font-38-800 {
  color: inherit;
  font-weight: 800;
  font-size: 32px;
  line-height: 46px;
}
.font-24-700 {
  color: inherit;
  font-weight: 700;
  font-size: 1.2rem;
}

.font-20-800 {
  color: inherit;
  font-size: 1rem;
  line-height: 24px;
}
.font-20-900 {
  color: inherit;
  font-weight: 900;
  font-size: 1rem;
  line-height: 25px;
}
.font-20-500 {
  color: inherit;
  font-weight: 500;
  font-size: 0.9rem;
}

.font-18-500 {
  color: inherit;
  font-weight: 500;
  font-size: 0.8rem;
}

.font-14-500 {
  color: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.125rem;
}

a:hover {
  text-decoration: none;
}

section {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}
.white-line {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-bg {
  background-color: #003049;
}
.orange-bg-900 {
  background-color: #f77f00;
}
.orange-bg-500 {
  background-color: rgba(247, 127, 0, 0.15);
}
.orange-bg-100 {
  background-color: rgba(247, 127, 0, 0.1);
}
/* .container {
  max-width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1350px) {
  .container {
    max-width: 1330px;
  }
}

@media (min-width: 1650px) {
  .container {
    max-width: 1540px;
  }
} */

.alpha-gray {
  color: rgba(255, 255, 255);
}

/* button */
.btn {
  -webkit-appearance: initial;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  display: inline-block;
  font-size: 12px;
  border: 2px solid transparent;
  letter-spacing: 0.3px;
  line-height: inherit;
  border-radius: 0;
  width: auto;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
}

/* button size */
.btn.btn-small {
  font-size: 12px;
  padding: 4px 1.8em;
}
.btn.btn-medium {
  font-weight: 800;
  font-size: 1.375rem;
  padding: 4px 35px;
  line-height: 1.8em;
}
.btn.btn-large {
  font-weight: 900;
  font-size: 1rem;
  padding: 1rem 25px;
  line-height: 1rem;
  border-radius: 20px;
}
.btn.btn-rounded {
  border-radius: 50px;
}

/* Transparent white button */
.btn.btn-trans-white {
  background: #ffffff;
  border-color: #ffffff;
  color: #002450;
}
.btn.btn-trans-white:hover {
  background: #002450 !important;
  border-color: #002450;
  color: #ffffff !important;
}

/* organge button */
.btn.btn-orange {
  background: #f77f00;
  border-color: #f77f00;
  color: #ffffff;
}
.btn.btn-orange:hover {
  background: #000 !important;
  border-color: #000;
  color: #ffffff !important;
}

.btn.btn-orange-outline {
  background: #ffffff;
  border: 3px solid #f77f00;
  color: #f77f00;
}
.btn.btn-orange-outline:hover {
  background: #000 !important;
  border-color: #000;
  color: #ffffff !important;
}

/* Dark yellow button */
.btn.btn-yellow {
  background: #e99b05;
  border-color: #e99b05;
  color: #ffffff;
}
.btn.btn-yellow:hover {
  background: #000 !important;
  border-color: #000;
  color: #ffffff !important;
}

/* Dark Blue White button */
.btn.btn-dark-white {
  background: #002450;
  border-color: #002450;
  color: #ffffff;
}
.btn.btn-dark-white:hover {
  background: #000 !important;
  border-color: #000;
  color: #fff !important;
}

/* Tags */

.tag-medium {
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: #f77f00;
  padding: 0.4rem 1rem;
}

/* Background color */
.bg-white {
  background: #ffffff;
}
.bg-light-white {
  background: #f6f6f6;
}
.bg-blue {
  background: #002450;
}
.bg-light-blue {
  background: #00b6ff;
}
.bg-black {
  background: #202020;
}
.header {
  position: sticky;
  height: 97px;
  padding: 0 20px;
  width: 100%;
  transition: height 0.3s ease-out, background 0.3s ease-out,
    box-shadow 0.3s ease-out;
  transform: translate3d(0, 0, 0);
  z-index: 1040;
}

.header.header-small {
  height: 60px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
}

.header .container-fluid {
  position: relative;
  height: 100%;
}

.inner-header::before {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-right: -1px;
  content: "";
}

.inner-header {
  position: relative;
  height: 100%;
  float: left;
  z-index: 1;
}

.inner-brand {
  display: inline-block;
  vertical-align: middle;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
  font-size: 1.25rem;
}

.extra-nav {
  position: relative;
  height: 100%;
  float: right;
  text-align: right;
}

.inner-navigation {
  position: absolute;
  height: 100%;
  float: left;
  right: 15px;
  left: 15px;
  text-align: center;
}

.inner-nav,
.extra-nav {
  vertical-align: middle;
  display: inline-block;
  height: 100%;
}

.inner-nav > ul {
  height: 100%;
  padding: 0;
  margin: 0;
}

.inner-nav > ul > li {
  position: relative;
  display: block;
  height: 100%;
  float: left;
  padding: 0 0.425rem;
  margin: 0;
}

.inner-nav > ul > li > a {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  padding: 0 7px;
  letter-spacing: 0.05625rem;
  font-weight: 600;
  font-size: 0.9rem;
}

.inner-nav > ul > li > a > .menu-item-span {
  transform: translateY(-50%);
  position: relative;
  display: block;
  top: 50%;
}

.extra-nav {
  padding-left: 10px;
}

.extra-nav > ul {
  height: 100%;
}

.extra-nav > ul > li {
  position: relative;
  display: block;
  height: 100%;
  float: left;
  margin: 0;
  transition: padding 0.3s ease-out;
}

.extra-nav > ul > li > button.login {
  color: #f77f00 !important;
}

.extra-nav > ul > li:first-child {
  border-left: 1px solid;
  padding: 0 0 0 15px;
}

.extra-nav > ul > li:only-child {
  padding-left: 5px;
}

.extra-nav > ul > li:last-child {
  padding-right: 0 !important;
}

.extra-nav > ul > li:last-child > a {
  padding-right: 0 !important;
}

.extra-nav > ul > li > button {
  position: relative;
  display: block;
  padding: 0 11px;
  letter-spacing: 0.05625rem;
}

.extra-nav > ul > li > .menu-item-span {
  transform: translateY(-50%);
  position: relative;
  display: block;
  top: 50%;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 6px 38px;
}

.top-bar-cart > span > span {
  position: absolute;
  background: #505cfd;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  left: -4px;
  top: 1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px !important;
  font-size: 0.5rem;
  color: #001a26;
}

.nav-toggle {
  display: block;
}

.header.header-transparent {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header.header-transparent .inner-brand {
  color: #fff;
}

.header.header-transparent .inner-brand .brand-dark {
  display: none;
}

.header.header-transparent .inner-brand .brand-light {
  display: inline-block;
}

/* .header.header-transparent .extra-nav > ul > li > a,
.header.header-transparent .inner-nav > ul > li > a {
  color: ;
}

.header.header-transparent .extra-nav > ul > li > a:hover,
.header.header-transparent .extra-nav > ul > li > a.active,
.header.header-transparent .inner-nav > ul > li > a:hover,
.header.header-transparent .inner-nav > ul > li > a.active {
  color: #001a26;
} */

.header.header-transparent .extra-nav > ul > li:first-child {
  border-color: rgba(255, 255, 255, 0.1);
}

.header.header-transparent .inner-nav > ul > li > a:hover::after {
  background: #fff;
  margin-left: 0;
  width: 100%;
  left: 0;
}

.header.header-transparent .inner-nav > ul > li > a::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  display: block;
  height: 1px;
  width: 20px;
  bottom: -1px;
  left: 50%;
  margin-left: -10px;
  content: "";
  transition: all 0.3s ease-out;
}

.header,
.header.header-small {
  background: #fff;
  border-bottom: 1px solid rgba(240, 240, 240, 0.8);
}

.header .inner-brand,
.header.header-small .inner-brand {
  color: #222;
}

.header .inner-brand .brand-dark,
.header.header-small .inner-brand .brand-dark {
  display: inline-block;
}

.header .inner-brand .brand-light,
.header.header-small .inner-brand .brand-light {
  display: none;
}

/* .header .extra-nav > ul > li > a,
.header .inner-nav > ul > li > a,
.header.header-small .extra-nav > ul > li > a,
.header.header-small .inner-nav > ul > li > a {
  color: rgba(0, 26, 38, 0.5);
} */

/* .header .extra-nav > ul > li > a:hover,
.header .extra-nav > ul > li > a.active, */
.header .inner-nav > ul > li > a:hover,
.header .inner-nav > ul > li > a.active,
/* .header.header-small .extra-nav > ul > li > a:hover,
.header.header-small .extra-nav > ul > li > a.active, */
.header.header-small .inner-nav > ul > li > a:hover,
.header.header-small .inner-nav > ul > li > a.active {
  color: #001a26;
}

.header .extra-nav > ul > li:first-child,
.header.header-small .extra-nav > ul > li:first-child {
  border-color: rgba(240, 240, 240, 0.8);
}

.header .inner-nav > ul > li > a::after,
.header.header-small .inner-nav > ul > li > a::after {
  display: none;
}

@media (min-width: 992px) {
  .nav-toggle {
    display: none !important;
  }

  .inner-navigation.collapse {
    display: block;
  }
  #join-our-network {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  #here-to-help {
    padding-left: 8rem;
    color: inherit;
    font-weight: 800;
    font-size: 125px;
    line-height: 152px;
  }
  #contact-mobile {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .wrapper {
    padding-top: 74px;
  }

  .header,
  .header.header-small,
  .header.header-transparent {
    background: #fff;
    border-bottom: 1px solid rgba(240, 240, 240, 0.8);
  }

  .header .inner-brand,
  .header.header-small .inner-brand,
  .header.header-transparent .inner-brand {
    color: #222;
  }

  .header .inner-brand .brand-dark,
  .header.header-small .inner-brand .brand-dark,
  .header.header-transparent .inner-brand .brand-dark {
    display: inline-block;
  }

  .header .inner-brand .brand-light,
  .header.header-small .inner-brand .brand-light,
  .header.header-transparent .inner-brand .brand-light {
    display: none;
  }

  .header .inner-nav > ul > li > a,
  .header.header-small .inner-nav > ul > li > a,
  .header.header-transparent .inner-nav > ul > li > a {
    color: #fff;
  }

  .header .inner-nav > ul > li > a:hover,
  .header.header-small .inner-nav > ul > li > a:hover,
  .header.header-transparent .inner-nav > ul > li > a:hover {
    color: rgba(255, 255, 255, 0.7);
  }

  .header .extra-nav > ul > li:first-child,
  .header.header-small .extra-nav > ul > li:first-child,
  .header.header-transparent .extra-nav > ul > li:first-child {
    border-color: rgba(240, 240, 240, 0.8);
  }

  /*   .header .extra-nav > ul > li > a,
  .header.header-small .extra-nav > ul > li > a,
  .header.header-transparent .extra-nav > ul > li > a {
    color: #222;
  } */

  /*   .header .extra-nav > ul > li > a:hover,
  .header.header-small .extra-nav > ul > li > a:hover,
  .header.header-transparent .extra-nav > ul > li > a:hover {
    color: rgba(34, 34, 34, 0.65);
  } */

  .header .extra-nav > ul > li > button.off-canvas-open,
  .header.header-small .extra-nav > ul > li > button.off-canvas-open,
  .header.header-transparent .extra-nav > ul > li > button.off-canvas-open {
    display: none;
  }

  .header .inner-nav > ul > li > a::after,
  .header.header-small .inner-nav > ul > li > a::after,
  .header.header-transparent .inner-nav > ul > li > a::after {
    display: none;
  }

  .inner-navigation {
    background: #222;
    text-align: left !important;
    max-height: 340px;
    height: auto;
    top: 100%;
    right: 0;
    left: 0;
  }

  .inner-navigation.show {
    overflow-y: auto;
  }

  .inner-navigation.collapse {
    display: none;
  }
  .extra-nav {
    display: none;
  }
  #contact {
    display: none;
  }
  #join-our-network {
    padding-left: 15px;
    padding-right: 15px;
  }
  .register-buttons {
    display: none;
  }
  .banner-box {
    min-height: 320px !important;
  }
  .btn-mobile {
    width: 100%;
    height: 100px;
    font-size: 20px;
  }
  #here-to-help {
    color: inherit;
    font-weight: 800;
    font-size: 40px;
    line-height: 52px;
  }
  .inner-navigation.collapse.show {
    display: block;
  }

  .inner-navigation.collapsing {
    height: 0;
    transition: height 0.35s ease;
  }

  .inner-nav {
    display: block;
    padding: 20px 0;
  }

  .inner-nav > ul {
    height: auto;
  }

  .inner-nav > ul > li {
    float: none;
  }

  .inner-nav > ul > li > a {
    padding: 10px 0;
  }

  .inner-nav > ul > li > a > .menu-item-span {
    transform: translateY(0);
    top: 0;
  }
}

.inner-nav .sub-menu,
.inner-nav .mega-menu {
  position: absolute;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 14px 32px 4px rgba(0, 0, 0, 0.1);
  min-width: 180px;
  left: 10px;
  margin-top: 5px;
  visibility: hidden;
  text-align: left;
  padding: 15px 0;
  opacity: 0;
}

.inner-nav .sub-menu:not(.mega-menu-row) {
  list-style: none;
}

.inner-nav .sub-menu:not(.mega-menu-row) .sub-menu {
  top: -15px;
  left: 100%;
  margin: 0 4px;
}

.inner-nav .sub-menu:not(.mega-menu-row) li {
  position: relative;
  padding: 0;
  margin: 0;
}

.inner-nav .sub-menu:not(.mega-menu-row) li.menu-item-has-children > a::after {
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -4px;
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 0.5rem;
  content: "\e649";
  transition: all 0.3s ease-in-out;
}

.inner-nav .mega-menu {
  white-space: nowrap;
  padding: 0;
}

.inner-nav .mega-menu .sub-menu {
  position: relative;
  background: transparent;
  box-shadow: none;
  padding: 0;
  left: 0;
}

.inner-nav .mega-menu .sub-menu .sub-menu {
  left: auto;
}

.inner-nav .mega-menu .sub-menu + .sub-menu {
  padding-top: 0;
}

.inner-nav .mega-menu .mega-menu-row {
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 30px 20px;
}

.inner-nav .sub-menu li > a {
  position: relative;
  padding: 8px 20px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: #9d9d9d;
}

.inner-nav .sub-menu li > a > i,
.inner-nav .sub-menu li > a > span {
  margin-right: 10px;
}

.inner-nav .sub-menu.mega-menu-row li > a {
  padding-right: 0;
  padding-left: 0;
}

.inner-nav .sub-menu li > a:hover,
.inner-nav .sub-menu li.sub-menu-open,
.inner-nav .sub-menu li.sub-menu-open > a {
  color: rgba(157, 157, 157, 0.65);
}

.inner-nav > ul li.menu-item-has-children .sub-menu,
.inner-nav > ul li.menu-item-has-children .mega-menu {
  transform: translateY(5px);
  transition: opacity 0.3s 0.1s, transform 0.3s 0.1s, visibility 0.3s 0.1s;
}

.inner-nav > ul > li.menu-item-has-children.sub-menu-open > .sub-menu,
.inner-nav
  > ul
  > li.menu-item-has-children.sub-menu-open
  > .sub-menu
  > .sub-menu-open
  > .sub-menu,
.inner-nav > ul > li.menu-item-has-children.sub-menu-open > .mega-menu {
  transform: translateY(0);
}

.inner-nav .sub-menu-open > .sub-menu,
.inner-nav .sub-menu-open > .mega-menu,
.inner-nav .sub-menu-open > .mega-menu .sub-menu {
  visibility: visible;
  opacity: 1;
}

.inner-nav > ul li.menu-item-has-children .mega-menu .sub-menu {
  transform: translateY(0);
  margin: 0;
}

@media (max-width: 991px) {
  .inner-nav .sub-menu,
  .inner-nav .mega-menu {
    background: #222;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .inner-nav .sub-menu,
  .inner-nav .mega-menu,
  .inner-nav .sub-menu .sub-menu {
    display: none;
  }

  .inner-nav .mega-menu .mega-menu-row {
    background-image: none !important;
  }

  .inner-nav .sub-menu,
  .inner-nav .sub-menu.mega-menu-row {
    padding: 0 0.625rem;
  }

  .inner-nav .sub-menu .sub-menu,
  .inner-nav .sub-menu.mega-menu-row .sub-menu {
    padding: 0 0.625rem;
  }

  .inner-nav .sub-menu li > a {
    padding: 0.625rem 0;
  }

  .inner-nav .sub-menu li > a:hover,
  .inner-nav .sub-menu li.sub-menu-open,
  .inner-nav .sub-menu li.sub-menu-open > a {
    color: rgba(255, 255, 255, 0.7);
  }
}

/* Banner */

.banner-box {
  min-height: 650px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.banner-content {
  max-width: 600px;
}
.banner-content p {
  font-weight: 700;
  font-size: 22px;
  line-height: 143.4%;
}

.banner-content p.alpha-gray {
  font-size: 0.75rem;
  /* line-height: 24px; */
}

.about-us-wrapper {
  position: relative;
  max-width: 1082px;
  margin: auto;
}
.about-us-wrapper:before,
.about-us-wrapper:after {
  background-image: url("/img/home/qoute.png");
  content: "";
  display: block;
  position: absolute;
  background-size: cover;
  width: 78px;
  height: 70px;
  opacity: 0.5;
}
.about-us-wrapper:before {
  left: -174px;
  bottom: 0;
}
.about-us-wrapper:after {
  right: -174px;
  top: 0;
  transform: scaleX(-1);
}

/* Our Services */

.services-box-icon {
  display: block;
  height: 60px;
  margin: 0.5rem;
}

/* Blog Cards */

.blog-cards {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.11);
  border-radius: 22px;
}
.blog-cards .blog-img-cover {
  height: 147px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
}
.blog-cards .blog-img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-cards .card-title {
  font-size: 1rem;
  font-weight: 800;
}

.input-group-borderless .form-control,
.input-group-borderless .input-group-text {
  border: none;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
}

/* Footer */
footer p {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}
.footer-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.footer-nav ul li a {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
}
.footer-nav ul li {
  margin-left: 20px;
}
.footer-nav ul li:first-child {
  margin-left: 0;
}

.footer-socail {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.footer-socail li a {
  background-color: #ffffff;
  width: 45px;
  height: 45px;
  border-radius: 5rem;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #003049;
  font-size: 24px;
}
</style>
