<template>
  <span class="gray-box-sm px-4 payment-method" @click="$emit('handle-click')">
    <span class="d-flex align-items-center">
      <span v-if="logoComponent" class="card-logo mr-2"
        ><component :is="logoComponent"></component
      ></span>
      <span>{{ titleCase(paymentMethod.card.brand) }}</span>
    </span>
    <span class="align-self-center text-center"
      ><span class="font-weight-bold">Exp&nbsp;</span
      >{{ paymentMethod.card.exp_month }}/{{
        paymentMethod.card.exp_year
      }}</span
    >
    <span class="text-right align-self-center"
      ><span class="font-weight-bold">Last 4&nbsp;</span
      >{{ paymentMethod.card.last4 }}</span
    >
    <span class="text-right">
      <DefaultButton :labelText="'Delete'" @click="deleteCard" />
    </span>
  </span>
</template>

<script>
import { titleCase } from "@/utils/converters"
import AmexLogo from "./Logos/AmexLogo"
import DinersLogo from "./Logos/DinersLogo"
import DiscoverLogo from "./Logos/DiscoverLogo"
import JcbLogo from "./Logos/JcbLogo"
import MastercardLogo from "./Logos/MastercardLogo"
import UnionpayLogo from "./Logos/UnionpayLogo"
import VisaLogo from "./Logos/VisaLogo"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    DefaultButton,
  },
  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoComponent() {
      const logos = {
        amex: AmexLogo,
        diners: DinersLogo,
        discover: DiscoverLogo,
        jcb: JcbLogo,
        mastercard: MastercardLogo,
        unionpay: UnionpayLogo,
        visa: VisaLogo,
      }
      const component = logos[this.paymentMethod?.card?.brand ?? ""] ?? null
      return component
    },
  },
  methods: {
    titleCase(str) {
      return titleCase(str)
    },
    async deleteCard() {
      const response = await this.$store.dispatch(
        "api/deletePaymentMethod",
        this.paymentMethod.id
      )
      console.log("response", response)
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-method {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card-logo {
  width: 37.74px;
  height: 24px;
}
</style>
