import projects from "@/api/projects"

const state = {
  list: [],
}

const getters = {}

const mutations = {
  SET_PROJECTS: (state, projects) => {
    state.list = projects
  },
  RESET: (state) => {
    state.list = null
  },
}

const actions = {
  async dispatchFetchProjects({ commit }, payload) {
    try {
      const { data } = await projects.getProjects(payload)
      commit("SET_PROJECTS", data)
    } catch (error) {
      console.log(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
