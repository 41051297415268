<template>
  <li class="nav-item">
    <router-link
      :to="link.path"
      @click.native="linkClick"
      :class="[isActive ? 'active nav-link' : 'nav-link']"
      :target="link.target"
      :href="link.path"
    >
      <template>
        <i :class="link.icon"></i>
        <span class="nav-link-text text-nowrap">{{ link.name }}</span>
        <div
          v-if="
            ((link.name == 'Find employees' || link.name == 'Find Jobs') &&
              hasJobBriefingNotification) ||
            (link.name == 'Find Partners' && hasProjectNotification) ||
            (link.name == 'Find employees' && hasJobApplicationNotification)
          "
          style="float: right; position: relative; top: -5px"
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6.97656"
              cy="6"
              r="5"
              fill="#FA0000"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </div>
      </template>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "sidebar-item",
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
        }
      },
      description:
        "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  computed: {
    isActive() {
      return this.$route.fullPath.indexOf(this.link.path.split("/")[1]) > -1
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
      hasJobBriefingNotification:
        this.$store.state.userProfile.type == "jobSeeker" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "job" && x.profile != this.$store.state.userProfile._id
        ) > -1,
      hasJobApplicationNotification:
        this.$store.state.userProfile.type == "provider" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) => x.type == "job-application"
        ) > -1,
      hasProjectNotification:
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "project" &&
            x.profile != this.$store.state.userProfile._id
        ) > -1,
    }
  },
  methods: {
    async linkClick() {
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.hasJobBriefingNotification =
        this.$store.state.userProfile.type == "jobSeeker" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "job" && x.profile != this.$store.state.userProfile._id
        ) > -1
      this.hasJobApplicationNotification =
        this.$store.state.userProfile.type == "provider" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) => x.type == "job-application"
        ) > -1
      this.hasProjectNotification =
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "project" &&
            x.profile != this.$store.state.userProfile._id
        ) > -1
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>
