<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left v-if="currentView != 'done'"
      ><span class="mr-1">
        {{
          profile
            ? `You are sending a Job Briefing to ${profile.name} for consideration`
            : ""
        }}
      </span></template
    >
    <template v-slot:actions-right v-if="currentView != 'done'">
      <b-button variant="link" :to="backUrl" class="mr-1 orange-clr"
        >Back to Search</b-button
      >
      <b-button variant="success" @click="handleNext" class="rounded-lg"
        ><b-spinner v-if="loading" small class="mr-2" />Next</b-button
      >
    </template>
    <div v-if="profile && currentView == 'edit'" class="py-4 px-9">
      <h1>Congratulations, you are now connected!</h1>
      <p>
        Please send a job briefing to {{ profile.name }} for consideration. Put
        your best offer forward and be as detailed as possible to maximize
        success. You will be notified when they respond to your job briefing and
        good luck!
      </p>
    </div>
    <job-form
      ref="jobForm"
      :job="job"
      :currentView="currentView"
      :loading="jobLoading"
      :cancelButtonTo="backUrl"
      :briefing="briefing"
      :skilledWorker="profile"
      @set-current-view="setCurrentView"
    />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import JobForm from "@/components/Job/JobForm"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
export default {
  components: {
    InnerLayoutActionBarWrapper,
    JobForm,
  },
  data() {
    // Queries in return_url after successful checkout with Stripe
    // ?payment_intent=pi_3Jy6vuJVc2vJw9dF0eNNwrQQ&payment_intent_client_secret=pi_3Jy6vuJVc2vJw9dF0eNNwrQQ_secret_z2Yl8ewh672zbW9Saw04SSHaG&redirect_status=succeeded
    const profileId = this.$router.currentRoute.params?.profileId
    const isSkilledWorker =
      this.$router.currentRoute.path.includes("skilled-workers")
    return {
      profileId,
      profile: null,
      isSkilledWorker,
      loading: false,
      backUrl: isSkilledWorker
        ? "/employees/skilled-workers"
        : "/employees/search",
      job: null,
      jobLoading: false,
      currentView: "edit",
      formLoading: false,
      briefing: true,
    }
  },
  created() {
    this.fetchProfile()
  },
  methods: {
    async fetchProfile() {
      const profileId = this.$router.currentRoute.params?.profileId
      try {
        const { data } = await this.$store.dispatch("api/getProfile", {
          profileId,
        })
        this.profile = data
      } catch (error) {
        this.showErrorMessage(
          error,
          "We couldn't retrieve the data for this profile."
        )
      }
    },
    setCurrentView(currentView) {
      this.currentView = currentView
    },
    handleNext() {
      console.log("TODO: Handle next button click")
    },
  },
}
</script>

<style lang="scss" scoped></style>
