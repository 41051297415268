<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1 orange-clr">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor.
      </span></template
    >
    <template v-slot:actions-right>
      <b-button variant="link" @click="handleSaveTraining" class="mr-1"
        >Save</b-button
      >
      <b-button
        variant="warning"
        @click="handleApplyForTraining"
        class="rounded-lg"
        >Apply</b-button
      >
    </template>
    <p class="p-2">Coming soon....</p>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  components: {
    InnerLayoutActionBarWrapper,
  },
  methods: {
    handleSaveTraining() {
      console.log("TODO: Handle save job")
    },
    handleApplyForTraining() {
      console.log("TODO: Handle apply for job")
    },
  },
}
</script>

<style scoped></style>
