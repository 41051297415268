var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-modal',{attrs:{"id":"skilled-worker-payment-change-card-modal","size":"sm","title":"Change payment method"},on:{"ok":_vm.handlePaymentMethodChangeConfirm}},[_c('form',[_c('div',{attrs:{"role":"radiogroup","aria-labelledby":"payment-method-legend","id":"select-payment-method"}},[_c('p',{staticClass:"font-14-700 px-2",attrs:{"id":"payment-method-legend"}},[_vm._v(" Please select the payment method you would like to use for this purchase: ")]),_vm._l((_vm.paymentMethods),function(paymentMethod,index){return _c('payment-method-display',{key:paymentMethod.id,class:[
            'payment-method-radio',
            {
              'cursor-pointer': _vm.selection.id !== paymentMethod.id,
            } ].concat( (_vm.selection.id === paymentMethod.id
              ? ['selected', 'shadow-lg']
              : []),
            [{ 'mb-3': index !== _vm.paymentMethods.length - 1 }] ),attrs:{"role":"radio","aria-checked":_vm.selection.id === paymentMethod.id,"tabindex":"0","paymentMethod":paymentMethod},on:{"handle-click":function($event){_vm.selection = paymentMethod}}})})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }