<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="icon"
    role="presentation"
  >
    <title :id="icon" lang="en">{{ icon }} icon</title>
    <g :fill="color">
      <path
        d="M7.28868 11.0336C7.28868 10.783 7.08546 10.5801 6.83472 10.5801H5.25059C5.00013 10.5801 4.79688 10.783 4.79688 11.0336V12.6175C4.79688 12.8684 5.00013 13.0714 5.25059 13.0714H6.83472C7.08546 13.0714 7.28868 12.8684 7.28868 12.6175V11.0336Z"
      />
      <path
        d="M11.2455 11.0336C11.2455 10.783 11.0422 10.5801 10.792 10.5801H9.20762C8.95716 10.5801 8.75391 10.783 8.75391 11.0336V12.6175C8.75391 12.8684 8.95716 13.0714 9.20762 13.0714H10.792C11.0422 13.0714 11.2455 12.8684 11.2455 12.6175V11.0336Z"
      />
      <path
        d="M15.2066 11.0336C15.2066 10.783 15.0034 10.5801 14.7529 10.5801H13.1688C12.9181 10.5801 12.7148 10.783 12.7148 11.0336V12.6175C12.7148 12.8684 12.9181 13.0714 13.1688 13.0714H14.7529C15.0034 13.0714 15.2066 12.8684 15.2066 12.6175V11.0336Z"
      />
      <path
        d="M7.28868 14.9928C7.28868 14.7419 7.08546 14.5391 6.83472 14.5391H5.25059C5.00013 14.5391 4.79688 14.7419 4.79688 14.9928V16.5765C4.79688 16.8272 5.00013 17.0302 5.25059 17.0302H6.83472C7.08546 17.0302 7.28868 16.8271 7.28868 16.5765V14.9928Z"
      />
      <path
        d="M11.2455 14.9928C11.2455 14.7419 11.0422 14.5391 10.792 14.5391H9.20762C8.95716 14.5391 8.75391 14.7419 8.75391 14.9928V16.5765C8.75391 16.8272 8.95716 17.0302 9.20762 17.0302H10.792C11.0422 17.0302 11.2455 16.8271 11.2455 16.5765V14.9928Z"
      />
      <path
        d="M15.2066 14.9928C15.2066 14.7419 15.0034 14.5391 14.7531 14.5391H13.1688C12.9181 14.5391 12.7148 14.7419 12.7148 14.9928V16.5765C12.7148 16.8272 12.9181 17.0302 13.1688 17.0302H14.7531C15.0034 17.0302 15.2066 16.8271 15.2066 16.5765V14.9928Z"
      />
      <path
        d="M18.0385 2.22744V4.64677C18.0385 5.74028 17.1514 6.62148 16.0581 6.62148H14.8088C13.7153 6.62148 12.8165 5.74028 12.8165 4.64677V2.21875H7.18545V4.64677C7.18545 5.74028 6.28663 6.62148 5.19336 6.62148H3.94379C2.85049 6.62148 1.96343 5.74028 1.96343 4.64677V2.22744C1.00786 2.25624 0.222656 3.04694 0.222656 4.01881V18.1972C0.222656 19.1874 1.02519 20.0006 2.01534 20.0006H17.9866C18.9753 20.0006 19.7793 19.1857 19.7793 18.1972V4.01881C19.7793 3.04694 18.9941 2.25624 18.0385 2.22744ZM17.4583 17.3143C17.4583 17.7422 17.1113 18.0893 16.6833 18.0893H3.28453C2.85647 18.0893 2.50953 17.7422 2.50953 17.3143V9.99064C2.50953 9.56257 2.85643 9.21543 3.28453 9.21543H16.6832C17.1113 9.21543 17.4582 9.56257 17.4582 9.99064L17.4583 17.3143Z"
      />
      <path
        d="M3.94093 5.32396H5.1767C5.55178 5.32396 5.85591 5.02028 5.85591 4.64519V0.67901C5.85591 0.303881 5.55178 0 5.1767 0H3.94093C3.5658 0 3.26172 0.303881 3.26172 0.67901V4.64519C3.26172 5.02028 3.5658 5.32396 3.94093 5.32396Z"
      />
      <path
        d="M14.7925 5.32396H16.0282C16.4031 5.32396 16.7072 5.02028 16.7072 4.64519V0.67901C16.7072 0.303881 16.4031 0 16.0282 0H14.7925C14.4174 0 14.1133 0.303881 14.1133 0.67901V4.64519C14.1133 5.02028 14.4174 5.32396 14.7925 5.32396Z"
      />
    </g>
  </svg>
</template>

<script>
import { iconProps } from "./iconProps"
export default {
  props: iconProps,
}
</script>
