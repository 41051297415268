<template>
  <!-- <button
    v-if="file"
    @click.prevent="getFileContents(file)"
    class="btn-block btn btn-medium btn-rounded"
    type="button"
  >
    <slot></slot>
  </button> -->
  <DefaultButton
    :labelText="btnLabel"
    v-if="file"
    @click.prevent="getFileContents(file)"
  />
</template>

<script>
import { saveAs } from "file-saver"
import DefaultButton from "./DefaultButton"
export default {
  components: { DefaultButton },
  name: "signed-file-download-button",
  props: {
    file: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      default: "gfb-download",
    },
    btnLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    async getFileContents() {
      const { data } = await this.$store.dispatch("api/getFileContents", {
        id: this.file,
      })
      this.handleDownload(data)
    },
    async handleDownload(file) {
      try {
        const { data } = await this.$store.dispatch("api/getFileUrl", {
          name: file.name,
          version: file.version,
          profileId: file.user.profile,
          id: file.id,
        })
        if (data.errors) {
          console.error(data.errors[0])
        } else {
          const signedUrl = data.url
          if (signedUrl) {
            saveAs(signedUrl, this.fileName)
          } else {
            throw new Error("Invalid URL returned")
          }
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error(error)
        }
        this.$toast.error("We were unable to download your file.")
      }
    },
  },
}
</script>
