<template>
  <component :is="iconComponent" v-bind="this.$props" />
</template>

<script>
import { iconProps } from "./iconProps"
// Add more icons here as needed
import CalendarIcon from "./CalendarIcon.vue"
import CandidateIcon from "./CandidateIcon.vue"
import CloseIcon from "./CloseIcon.vue"
import CurrencyCirculationIcon from "./CurrencyCirculationIcon.vue"
import LocationPinIcon from "./LocationPinIcon.vue"
import NewspaperIcon from "./NewspaperIcon.vue"
import OrganizationIcon from "./OrganizationIcon.vue"
import PersonToPersonIcon from "./PersonToPersonIcon.vue"
import ProviderIcon from "./ProviderIcon.vue"
import StopwatchIcon from "./StopwatchIcon.vue"
import VendorIcon from "./VendorIcon.vue"
import NullIcon from "./NullIcon.vue"

const icons = {
  calendar: CalendarIcon,
  candidate: CandidateIcon,
  close: CloseIcon,
  "currency-circulation": CurrencyCirculationIcon,
  educational: OrganizationIcon,
  "location-pin": LocationPinIcon,
  newspaper: NewspaperIcon,
  organization: OrganizationIcon,
  "person-to-person": PersonToPersonIcon,
  provider: ProviderIcon,
  stopwatch: StopwatchIcon,
  vendor: VendorIcon,
}

export default {
  name: "icon",
  props: iconProps,
  computed: {
    iconComponent: function () {
      return icons[this.$props.icon] ?? NullIcon
    },
  },
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
