<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1 font-weight-bold">
        Congratulations, your Job has been published!
      </span></template
    >
    <template v-slot:actions-right>
      <b-button variant="link" @click="handleDeleteJob" class="mr-1 orange-clr"
        >Discard</b-button
      >
      <b-button
        v-if="job"
        variant="warning"
        :to="`/employees/jobs/${job && job.id}/edit`"
        class="rounded-lg"
        >Edit</b-button
      >
    </template>

    <div class="w-50 py-4 mx-auto">
      <job-view v-if="job" :job="job" />
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { getJobDetails } from "@/api/jobs"
import JobView from "@/components/Job/JobView"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import messagesMixin from "@/mixins/messagesMixin"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    JobView,
  },
  mixins: [messagesMixin],
  data() {
    return {
      job: null,
    }
  },
  async created() {
    await this.getJob()
  },
  methods: {
    async getJob() {
      const jobId = this.$router.currentRoute.params?.jobId
      if (jobId) {
        const { data } = await getJobDetails(jobId)
        if (!data || data.errors) {
          this.showErrorMessage(
            data.errors[1],
            "We couldn't find data for this job."
          )
        } else {
          this.job = data
        }
      }
    },
    handleDeleteJob() {
      console.log("TODO: Delete Job")
    },
  },
}
</script>

<style scoped></style>
