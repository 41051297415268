<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="icon"
    role="presentation"
  >
    <title :id="icon" lang="en">{{ icon }} icon</title>
    <g :fill="color">
      <path
        d="M69.2583 2.70703H15.8927C13.8627 2.70703 12.2116 4.35861 12.2116 6.38859V11.9772H3.68172C1.65166 11.9772 0 13.6287 0 15.6587V63.0304C0 66.9674 3.19035 70.1738 7.12249 70.2021H7.13696C7.14976 70.2027 7.16144 70.2038 7.17424 70.2038H44.9704C45.5602 70.2038 46.0388 69.7258 46.0388 69.1354C46.0388 68.5455 45.5602 68.067 44.9704 68.067H12.2778C13.5572 66.771 14.3485 64.9914 14.3485 63.0304V38.0458C14.3485 37.4559 13.8699 36.9774 13.28 36.9774C12.6896 36.9774 12.2116 37.4559 12.2116 38.0458V63.0304C12.2116 65.7983 9.9667 68.0514 7.20151 68.0664C7.19261 68.0664 7.1837 68.0653 7.17424 68.0653C4.39681 68.0648 2.13691 65.8061 2.13691 63.0304V15.6587C2.13691 14.8073 2.82974 14.114 3.68172 14.114H12.2116V34.0571C12.2116 34.6475 12.6896 35.1255 13.28 35.1255C13.8699 35.1255 14.3485 34.6475 14.3485 34.0571V6.38859C14.3485 5.53665 15.0413 4.84385 15.8927 4.84385H69.2583C70.1103 4.84385 70.8031 5.53665 70.8031 6.38859V63.0304C70.8031 65.8077 68.5432 68.067 65.7658 68.067H48.9593C48.3688 68.067 47.8908 68.5455 47.8908 69.1354C47.8908 69.7258 48.3688 70.2038 48.9593 70.2038H65.7658C69.7218 70.2038 72.94 66.9858 72.94 63.0304V6.38859C72.94 4.35861 71.2883 2.70703 69.2583 2.70703V2.70703Z"
      />
      <path
        d="M23.409 45.3383H38.063C39.457 45.3383 40.5911 44.2042 40.5911 42.8103V28.1569C40.5911 26.763 39.457 25.6289 38.063 25.6289H23.409C22.015 25.6289 20.8809 26.763 20.8809 28.1569V42.8103C20.8809 44.2042 22.015 45.3383 23.409 45.3383V45.3383ZM23.0178 28.1569C23.0178 27.941 23.1931 27.7657 23.409 27.7657H38.063C38.2783 27.7657 38.4542 27.941 38.4542 28.1569V42.8103C38.4542 43.0256 38.2783 43.2015 38.063 43.2015H23.409C23.1931 43.2015 23.0178 43.0256 23.0178 42.8103V28.1569Z"
      />
      <path
        d="M44.4747 27.7657H64.3052C64.895 27.7657 65.3736 27.2877 65.3736 26.6973C65.3736 26.1069 64.895 25.6289 64.3052 25.6289H44.4747C43.8848 25.6289 43.4062 26.1069 43.4062 26.6973C43.4062 27.2877 43.8848 27.7657 44.4747 27.7657V27.7657Z"
      />
      <path
        d="M44.4747 36.5528H64.3052C64.895 36.5528 65.3736 36.0743 65.3736 35.4844C65.3736 34.894 64.895 34.416 64.3052 34.416H44.4747C43.8848 34.416 43.4062 34.894 43.4062 35.4844C43.4062 36.0743 43.8848 36.5528 44.4747 36.5528V36.5528Z"
      />
      <path
        d="M44.4747 45.338H64.3052C64.895 45.338 65.3736 44.8594 65.3736 44.2696C65.3736 43.6792 64.895 43.2012 64.3052 43.2012H44.4747C43.8848 43.2012 43.4062 43.6792 43.4062 44.2696C43.4062 44.8594 43.8848 45.338 44.4747 45.338Z"
      />
      <path
        d="M21.9493 54.1231H64.3053C64.8952 54.1231 65.3737 53.6451 65.3737 53.0547C65.3737 52.4649 64.8952 51.9863 64.3053 51.9863H21.9493C21.3594 51.9863 20.8809 52.4649 20.8809 53.0547C20.8809 53.6451 21.3594 54.1231 21.9493 54.1231V54.1231Z"
      />
      <path
        d="M21.9493 62.9103H64.3053C64.8952 62.9103 65.3737 62.4323 65.3737 61.8419C65.3737 61.2514 64.8952 60.7734 64.3053 60.7734H21.9493C21.3594 60.7734 20.8809 61.2514 20.8809 61.8419C20.8809 62.4323 21.3594 62.9103 21.9493 62.9103V62.9103Z"
      />
      <path
        d="M20.9082 20.1611V8.87159C20.9082 8.63954 21.0184 8.46259 21.2388 8.34128C21.4591 8.21941 21.7262 8.1582 22.039 8.1582C22.4569 8.1582 22.7752 8.23388 22.9961 8.38468C23.216 8.53549 23.4597 8.86046 23.7268 9.35849L27.0841 15.8474V8.85434C27.0841 8.62229 27.1943 8.44868 27.4147 8.33238C27.6345 8.21663 27.9016 8.1582 28.2149 8.1582C28.5282 8.1582 28.7947 8.21663 29.0151 8.33238C29.2355 8.44868 29.3457 8.62229 29.3457 8.85434V20.1611C29.3457 20.382 29.2321 20.5557 29.0062 20.6831C28.7803 20.8111 28.5165 20.8745 28.2149 20.8745C27.6 20.8745 27.1648 20.6369 26.9099 20.1611L23.1698 13.168V20.1611C23.1698 20.382 23.0568 20.5557 22.8309 20.6831C22.6049 20.8111 22.3406 20.8745 22.039 20.8745C21.7262 20.8745 21.4591 20.8111 21.2393 20.6831C21.0184 20.5557 20.9082 20.382 20.9082 20.1611Z"
      />
      <path
        d="M31.4336 20.1611V8.87159C31.4336 8.65123 31.5321 8.47761 31.7296 8.34963C31.9266 8.2222 32.1581 8.1582 32.4253 8.1582H38.6184C38.8499 8.1582 39.0274 8.25725 39.1487 8.45424C39.2706 8.65123 39.3318 8.87771 39.3318 9.13257C39.3318 9.4108 39.2678 9.64897 39.1404 9.84596C39.0124 10.0435 38.8382 10.1414 38.6184 10.1414H33.6952V13.6204H36.3396C36.5594 13.6204 36.7336 13.7106 36.8616 13.8903C36.989 14.0701 37.053 14.2815 37.053 14.5253C37.053 14.7456 36.9918 14.9454 36.8699 15.1257C36.7481 15.3054 36.5716 15.395 36.3396 15.395H33.6952V18.8918H38.6184C38.8382 18.8918 39.0124 18.9903 39.1404 19.1873C39.2678 19.3849 39.3318 19.6225 39.3318 19.9007C39.3318 20.1556 39.2706 20.382 39.1487 20.579C39.0274 20.7766 38.8499 20.8745 38.6184 20.8745H32.4253C32.1581 20.8745 31.9266 20.8111 31.7296 20.6836C31.5321 20.5557 31.4336 20.382 31.4336 20.1611V20.1611Z"
      />
      <path
        d="M40.4629 9.06301C40.4629 8.81928 40.6193 8.60783 40.9326 8.42809C41.2453 8.24835 41.5703 8.1582 41.9064 8.1582C42.3243 8.1582 42.5736 8.309 42.6543 8.61061L45.4034 18.0393L46.882 11.9856C46.986 11.5677 47.3572 11.359 47.995 11.359C48.6216 11.359 48.9866 11.5677 49.0912 11.9856L50.5698 18.0393L53.3183 8.61061C53.3996 8.309 53.6489 8.1582 54.0668 8.1582C54.4029 8.1582 54.7273 8.24835 55.0406 8.42809C55.3539 8.60783 55.5103 8.81928 55.5103 9.06301C55.5103 9.13257 55.4986 9.20213 55.4758 9.27169L52.0312 20.2134C51.857 20.7243 51.3639 20.9791 50.5526 20.9791C50.2048 20.9791 49.8915 20.9123 49.6132 20.7788C49.335 20.6458 49.1664 20.4572 49.1085 20.2134L47.9955 15.5169L46.8647 20.2134C46.8063 20.4572 46.6382 20.6458 46.36 20.7788C46.0817 20.9123 45.7684 20.9791 45.4206 20.9791C45.0611 20.9791 44.7423 20.9123 44.464 20.7788C44.1852 20.6458 44.0116 20.4572 43.9421 20.2134L40.4974 9.27169C40.474 9.20213 40.4629 9.13257 40.4629 9.06301V9.06301Z"
      />
      <path
        d="M56.084 18.8735C56.084 18.6069 56.1853 18.3371 56.3884 18.0644C56.5909 17.7923 56.8202 17.656 57.0756 17.656C57.2259 17.656 57.3973 17.7283 57.5887 17.873C57.7802 18.0171 57.9655 18.1774 58.1452 18.3515C58.325 18.5263 58.5832 18.686 58.9198 18.8306C59.256 18.9753 59.6271 19.0471 60.0328 19.0471C60.5899 19.0471 61.0534 18.9197 61.4246 18.6643C61.7958 18.4094 61.9811 18.0327 61.9811 17.5341C61.9811 17.1857 61.8798 16.8758 61.6767 16.6031C61.4741 16.331 61.207 16.1045 60.8765 15.9248C60.5465 15.7451 60.1836 15.5709 59.7896 15.4028C59.3951 15.2348 58.9978 15.0461 58.5976 14.8375C58.1975 14.6288 57.8325 14.394 57.5019 14.133C57.1714 13.872 56.9042 13.5242 56.7017 13.0891C56.4986 12.6545 56.3973 12.1586 56.3973 11.6022C56.3973 10.9784 56.5219 10.4275 56.7712 9.95116C57.0206 9.47427 57.3539 9.10367 57.7713 8.83879C58.1886 8.57391 58.6349 8.37971 59.1107 8.25617C59.586 8.13264 60.0907 8.07031 60.6244 8.07031C60.9254 8.07031 61.2443 8.09146 61.581 8.13319C61.9171 8.17493 62.2738 8.24337 62.6511 8.33853C63.0278 8.43368 63.335 8.58282 63.5726 8.78481C63.8103 8.98736 63.9294 9.22553 63.9294 9.49987C63.9294 9.75807 63.8481 10.0252 63.6862 10.3012C63.5237 10.5772 63.3033 10.7146 63.0251 10.7146C62.9204 10.7146 62.6361 10.6044 62.1725 10.3841C61.7084 10.1637 61.1925 10.0535 60.6244 10.0535C59.9978 10.0535 59.5136 10.1726 59.1719 10.4102C58.8297 10.6484 58.6583 10.9756 58.6583 11.393C58.6583 11.7296 58.7974 12.0223 59.0762 12.2716C59.3545 12.5209 59.6995 12.724 60.1113 12.8804C60.5225 13.0367 60.9694 13.2343 61.4508 13.4719C61.9316 13.7095 62.3784 13.9677 62.7902 14.2459C63.2015 14.5242 63.547 14.9271 63.8253 15.4546C64.1035 15.9827 64.2427 16.6003 64.2427 17.3076C64.2427 18.4968 63.8626 19.4149 63.1035 20.061C62.3434 20.707 61.3434 21.0303 60.1024 21.0303C59.0005 21.0303 58.0556 20.8039 57.2671 20.3515C56.478 19.9002 56.084 19.4071 56.084 18.8735V18.8735Z"
      />
    </g>
  </svg>
</template>
<script>
import { iconProps } from "./iconProps"
export default {
  props: iconProps,
}
</script>
