<template>
  <base-card>
    <div v-if="jobTitle">{{ jobTitle }}</div>
    <b-skeleton
      width="50%"
      height="1rem"
      variant="light"
      animation="wave"
      class="rounded mb-2"
    ></b-skeleton>
    <b-skeleton
      width="45%"
      height="1rem"
      variant="light"
      animation="wave"
      class="rounded mb-2"
    ></b-skeleton>
    <b-skeleton
      width="65%"
      height="1rem"
      variant="light"
      animation="wave"
      class="rounded"
    ></b-skeleton>
    <div class="lock">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
      >
        <g id="surface2643979">
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(94.509804%, 76.862746%, 5.882353%);
              fill-opacity: 1;
            "
            d="M 15 2 C 11.144531 2 8 5.144531 8 9 L 8 11 L 6 11 C 4.894531 11 4 11.894531 4 13 L 4 25 C 4 26.105469 4.894531 27 6 27 L 24 27 C 25.105469 27 26 26.105469 26 25 L 26 13 C 26 11.894531 25.105469 11 24 11 L 22 11 L 22 9 C 22 5.273438 19.035156 2.269531 15.355469 2.074219 C 15.242188 2.027344 15.121094 2.003906 15 2 Z M 15 4 C 17.773438 4 20 6.226562 20 9 L 20 11 L 10 11 L 10 9 C 10 6.226562 12.226562 4 15 4 Z M 15 4 "
          />
        </g>
      </svg>
    </div>
  </base-card>
</template>

<script>
export default {
  props: {
    jobTitle: String,
  },
}
</script>

<style lang="scss" scoped>
.lock {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
