<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left><div></div></template>
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="`Back to Project Details`"
        @click="$router.push(`/trade-partners/search-projects/${projectId}`)"
      />
    </template>
    <profile></profile>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
  },
  data() {
    const projectId = this.$router.currentRoute.params?.projectId
    return {
      projectId,
    }
  },
}
</script>

<style scoped></style>
