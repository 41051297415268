<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 470 300"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(8.59599,0,0,8.59599,-160.886,-168.481)">
      <path
        d="M68.6,50.7L66.1,48L63.6,50.7L48.2,50.7L48.2,38.2L43.2,38.2L49.4,24.1L55.4,24.1L57.6,28.9L57.6,24.1L65,24.1L66.3,27.8L67.6,24.1L73.3,24.1L73.3,21.9C73.3,20.6 72.2,19.6 71,19.6L21,19.6C19.7,19.6 18.7,20.7 18.7,21.9L18.7,52.2C18.7,53.5 19.8,54.5 21,54.5L71,54.5C72.3,54.5 73.3,53.4 73.3,52.2L73.3,50.7L68.6,50.7Z"
        style="fill: rgb(0, 113, 206); fill-rule: nonzero"
      />
      <g>
        <path
          d="M69.3,49.3L73.3,49.3L68.1,43.8L73.3,38.3L69.4,38.3L66.2,41.8L63,38.3L59.1,38.3L64.3,43.8L59.1,49.3L62.9,49.3L66.1,45.8L69.3,49.3Z"
          style="fill: rgb(0, 113, 206); fill-rule: nonzero"
        />
        <path
          d="M52.8,46.8L52.8,45.1L59,45.1L59,42.6L52.8,42.6L52.8,40.8L59.1,40.8L59.1,38.3L49.8,38.3L49.8,49.3L59.1,49.3L59.1,46.8L52.8,46.8Z"
          style="fill: rgb(0, 113, 206); fill-rule: nonzero"
        />
        <path
          d="M70.3,36.8L73.2,36.8L73.2,25.8L68.7,25.8L66.2,32.6L63.8,25.8L59.1,25.8L59.1,36.8L62.1,36.8L62.1,29.1L64.9,36.8L67.5,36.8L70.3,29.1L70.3,36.8Z"
          style="fill: rgb(0, 113, 206); fill-rule: nonzero"
        />
        <path
          d="M54.3,25.8L50.5,25.8L45.7,36.7L49,36.7L49.9,34.5L54.9,34.5L55.8,36.7L59.2,36.7L54.3,25.8ZM50.8,32.1L52.3,28.5L53.8,32.1L50.8,32.1Z"
          style="fill: rgb(0, 113, 206); fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(8.59599,0,0,8.59599,-226.145,-168.481)">
      <path
        d="M70.5,43.5L73.3,46.5L73.3,40.5L70.5,43.5Z"
        style="fill: rgb(0, 113, 206); fill-rule: nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
