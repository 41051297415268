<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1000 636"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(17.6678,0,0,17.6678,-2816.25,-335.689)">
      <rect x="159.4" y="19" width="56.6" height="36" style="fill: white" />
    </g>
    <g transform="matrix(17.6678,0,0,17.6678,-2816.25,-335.689)">
      <rect
        x="160.4"
        y="49.1"
        width="54.7"
        height="4.9"
        style="fill: rgb(252, 179, 22)"
      />
    </g>
    <g transform="matrix(17.6678,0,0,17.6678,-2816.25,-335.689)">
      <rect
        x="160.4"
        y="20"
        width="54.7"
        height="4.9"
        style="fill: rgb(16, 53, 127)"
      />
    </g>
    <g transform="matrix(17.6678,0,0,17.6678,-2816.25,-335.689)">
      <path
        d="M182.3,30.3L176.6,43.8L172.9,43.8L170.1,33C170.1,32.5 169.7,32 169.3,31.8C168.2,31.3 167,30.9 165.9,30.7L166,30.3L171.9,30.3C172.7,30.3 173.4,30.9 173.5,31.7L175,39.5L178.6,30.3L182.3,30.3ZM196.7,39.4C196.7,35.8 191.8,35.6 191.8,34.1C191.8,33.6 192.3,33.1 193.3,33C194.5,32.9 195.7,33.1 196.7,33.6L197.3,30.7C196.3,30.3 195.1,30.1 194,30.1C190.6,30.1 188.1,31.9 188.1,34.6C188.1,36.5 189.8,37.6 191.2,38.3C192.6,39 193,39.4 193,40C193,40.9 191.9,41.3 190.9,41.3C189.7,41.3 188.4,41 187.3,40.5L186.7,43.5C187.9,44 189.3,44.2 190.6,44.2C194.3,44 196.7,42.2 196.7,39.4M205.9,43.8L209.1,43.8L206.3,30.3L203.3,30.3C202.6,30.3 202.1,30.7 201.8,31.3L196.6,43.8L200.3,43.8L201,41.8L205.5,41.8L205.9,43.8ZM202,39L203.8,33.9L204.9,39L202,39ZM187.3,30.3L184.4,43.8L180.9,43.8L183.8,30.3L187.3,30.3Z"
        style="fill: rgb(16, 53, 127); fill-rule: nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
