<template>
  <div>
    <div
      class="align-items-center d-flex justify-content-between py-4 rounded-lg row"
    >
      <b-col sm>
        <div><slot name="actions-left"></slot></div>
      </b-col>
      <b-col sm>
        <div class="d-flex align-items-right flex-1" style="float: right">
          <slot name="actions-right"></slot>
        </div>
      </b-col>
    </div>
    <main class="py-4 pl-2 pr-2">
      <slot></slot>
    </main>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
