<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="icon"
    role="presentation"
  >
    <title :id="icon" lang="en">{{ icon }} icon</title>
    <path
      d="M7.17783 20.9029C1.12375 12.1263 0 11.2255 0 8C0 3.58171 3.58171 0 8 0C12.4183 0 16 3.58171 16 8C16 11.2255 14.8763 12.1263 8.82217 20.9029C8.42488 21.4768 7.57508 21.4768 7.17783 20.9029ZM8 11.3333C9.84096 11.3333 11.3333 9.84096 11.3333 8C11.3333 6.15904 9.84096 4.66667 8 4.66667C6.15904 4.66667 4.66667 6.15904 4.66667 8C4.66667 9.84096 6.15904 11.3333 8 11.3333Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import { iconProps } from "./iconProps"
export default {
  props: iconProps,
}
</script>
