<template>
  <div class="row pt-3">
    <div class="col">
      <profile-section-header title="Profile Information" />
      <div class="d-flex flex-wrap align-items-center">
        <!-- <h2 class="font-22-900 dark-text mb-0 mr-2">Latest Education:</h2> -->
        <capsule-list
          v-if="profile.jobSeeker.isVeteran"
          :items="['Veteran']"
          :highlighted="true"
        />
        <ul class="profile-status-list">
          <li v-if="profile.jobSeeker.isAdult">
            <PhCheckCircle color="#10C95A" weight="fill" size="24px" />Applicant
            is 18 years of age or older
          </li>
          <li v-else>
            <PhWarningCircle
              color="#525E77"
              weight="fill"
              size="24px"
            />Applicant is under 18 years of age
          </li>
          <li v-if="profile.jobSeeker.authorizedInUS">
            <PhCheckCircle color="#10C95A" weight="fill" size="24px" />Applicant
            is legally authorized to work in the United States
          </li>
          <li v-else>
            <PhWarningCircle
              color="#525E77"
              weight="fill"
              size="24px"
            />Applicant is not legally authorized to work in the United States
          </li>
          <li v-if="profile.jobSeeker.requireSponsorship">
            <PhWarningCircle
              color="#525E77"
              weight="fill"
              size="24px"
            />Applicant currently, or in the future, will require immigration
            sponsorship for work authorization (e.g. H-1B)
          </li>
          <li v-else>
            <PhCheckCircle color="#10C95A" weight="fill" size="24px" />Applicant
            does not require immigration sponsorship for work authorization
            (e.g. H-1B)
          </li>
        </ul>
        <ProfileSubSectionHeader
          title="Highest Level of Education"
          icon="education-icon"
        />
        <capsule-list
          :items="
            profile.jobSeeker.latestEducation
              ? [profile.jobSeeker.latestEducation]
              : []
          "
          :highlighted="true"
        />
      </div>
      <div class="mt-3 pt-3" v-if="shouldDisplayTraining">
        <editable-paragraph
          title="Training"
          :content="profile.jobSeeker.formalTrainingDescription || ''"
          dataKey="overview"
          @handle-click="$emit('handle-click')"
          :isEditing="isEditing"
        />
        <capsule-list
          class="mt-4"
          :items="profile.jobSeeker.formalTrainingCategories || []"
          :highlighted="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSectionHeader from "../ProfileSectionHeader.vue"
import ProfileSubSectionHeader from "../ProfileSubSectionHeader.vue"
import { PhCheckCircle, PhWarningCircle } from "phosphor-vue"

export default {
  name: "candidate-profile-overview",
  components: {
    ProfileSectionHeader,
    ProfileSubSectionHeader,
    PhCheckCircle,
    PhWarningCircle,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    applicant: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldDisplayTraining() {
      const { hasFormalTraining, formalTrainingCategories } =
        this.profile.jobSeeker ?? {}
      return hasFormalTraining && formalTrainingCategories?.length > 0
    },
    hasFullProfileViewAccess() {
      let profileId = this.profile._id,
        unlockedApplicants =
          this.$store.state.userProfile?.provider.unlockedApplicants || []

      let isUnlockedProfile = unlockedApplicants.find(
        (item) => item.profile === profileId
      )

      const hasProfileViewAccess = !isUnlockedProfile ? false : true
      return hasProfileViewAccess
    },
  },
}
</script>
<style lang="scss" scoped>
.profile-status-list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin-top: 20px;
  list-style: none;

  li {
    margin-bottom: 18px;
    font-weight: 600;

    svg {
      margin-right: 8px;
    }
  }
}
</style>
