import axios from "@/utils/api-v1"
// import store from '@/store/store.js'
export default {
  getJobs(params) {
    return axios.get("/api/jobs", { params })
  },

  getJobsByType(data) {
    return axios.post(`/api/jobs/type/${data.type}`, {
      zip: data.zip,
      radius: data.radius,
      filters: data.filters,
    })
  },
  getJobsByLocation(id) {
    return axios.post(`/api/jobs/location/${id}`, { zip: id })
  },
  getJobDetails(id) {
    return axios.get(`/api/jobs/${id}`)
  },

  postJob(payload) {
    return axios.post("/api/jobs", payload)
  },

  updateJob(id, payload) {
    return axios.patch(`/api/jobs/${id}`, payload)
  },

  deleteJob(id) {
    return axios.delete(`/api/jobs/${id}`)
  },

  filterJobs(type, params) {
    return axios.get(`/api/jobs/type/${type}`, { params })
  },
  searchJobsByParams(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/job/search`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  searchJobsByCompany(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/jobs/list/company`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  getJobseekerActions(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/jobs/jobseeker/actions/`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
}
