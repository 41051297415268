<template>
  <b-modal v-bind="$attrs" v-on="$listeners">
    <!-- pass through scoped slots -->
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>

    <template #modal-header="{ close }">
      <slot name="modal-header" />
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h5 class="font-22-700 m-0">{{ $attrs.title }}</h5>
        <button type="button" class="close" @click="close()" aria-label="Close">
          <icon icon="close" aria-hidden="true" />
        </button>
      </div>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button class="rounded-lg mr-3" variant="light" @click="cancel()">{{
          $attrs["cancel-title"] || "Cancel"
        }}</b-button>
        <b-button class="rounded-lg" variant="success" @click="ok()">{{
          $attrs["ok-title"] || "OK"
        }}</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "base-modal",
}
</script>
<style>
.modal-content {
  border-radius: 22px;
}
.modal-footer {
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.17);
}
</style>
