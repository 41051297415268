<template>
  <div class="d-flex flex-wrap row">
    <span
      v-for="item in listItems"
      :key="item.name"
      class="my-2 mx-1 capsule"
      :class="{ highlighted: item.highlighted, blue: blue }"
      >{{ item.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "capsule-list",
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
    },
    highlighted: {
      type: Boolean,
    },
    blue: {
      type: Boolean,
    },
  },
  computed: {
    listItems() {
      const selectedItems = this.selectedItems ?? []
      const highlightedItems = this.items.map((i) => {
        const item = typeof i === "string" ? { text: i, value: i } : i
        return {
          name: item.text,
          highlighted: this.highlighted || selectedItems.includes(item.value),
        }
      })
      return highlightedItems
    },
  },
}
</script>

<style lang="scss" scoped>
.capsule {
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--Gray-100, #eceef2);
  color: var(--Gray-800, #3a4252);

  &.blue {
    background: var(--gfb-blue-brand-400, #16afff) !important;
    color: #fff !important;
    font-weight: 400 !important;
  }
}
</style>
