<template>
  <div>
    <div v-if="!connected && project">
      <ProjectDetailHeader :project="project">
        <template v-slot:action-btns>
          <DefaultButton
            v-if="
              project.company &&
              project.company._id != $store.state.userProfile._id
            "
            :labelText="'Connect'"
            @click="handleApplyForProject"
            :disabled="loading"
          />
        </template>
      </ProjectDetailHeader>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-xl-7">
            <div v-if="project && project.description">
              <ProfileSubSectionHeader
                title="Project Description"
                icon="description-icon"
              />
              <p>{{ project.description }}</p>
            </div>

            <div v-if="project && project.addedDetail">
              <ProfileSubSectionHeader
                title="Additional Detail"
                icon="description-icon"
              />
              <p>
                {{ project.addedDetail }}
              </p>
            </div>

            <p>
              <ProfileSubSectionHeader
                title="Trade Categories"
                icon="trade-experience-icon"
              />
              <capsule-list
                :items="project ? project.contractorTradeCategories : []"
                :highlighted="true"
              />
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-lg-9 col-xl-7 d-flex justify-content-between">
          <DefaultButton
            :btnClass="['profile-btn']"
            :labelText="'View Profile'"
            @click="goToCompanyProfile(company._id)"
            :disabled="loading"
          />
          <DefaultButton
            :labelText="'Go Back'"
            @click="$router.push('/trade-partners/projects')"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-xl-7" v-if="project">
            <h1 class="mt-4 job-title">
              Success! You've sent a connection request to {{ company.name }}
            </h1>
            <span
              >{{ company.name }} will be notified of your connection in regards
              to the {{ project.name }} project
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <DefaultButton
          :labelText="'Back to Search'"
          @click="$router.push('/trade-partners/search-projects')"
        />
        <DefaultButton
          :btnClass="['apply-btn']"
          :labelText="'Back to Dashboard'"
          @click="$router.push('/dashboard')"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import { getProjectDetails } from "@/api/projects"
//import ViewProject from "@/components/Project/View"
//import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import profile from "@/api/profile"
import messagesMixin from "@/mixins/messagesMixin"
import DefaultButton from "@/components/DefaultButton"
import ProfileSubSectionHeader from "@/components/Profile/ProfileSubSectionHeader"
import ProjectDetailHeader from "@/components/Project/ProjectDetailHeader"
export default {
  name: "ProjectDetail",
  components: {
    //InnerLayoutActionBarWrapper,
    //ViewProject,
    DefaultButton,
    ProfileSubSectionHeader,
    ProjectDetailHeader,
  },
  mixins: [messagesMixin],
  data() {
    return {
      project: null,
      job: {},
      company: {},
      connected: false,
      loading: false,
    }
  },
  created() {
    //this.getProject()
  },
  methods: {
    /*
    async getProjectById() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          const data = await this.$store.dispatch("jobs/dispatchFetchJobById", {
            id: this.$route.params.jobId,
          })
          this.project = data
          this.company = data.profile[0]
        } catch (error) {
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    */
    async getProject() {
      const projectId = this.$router.currentRoute.params?.projectId
      if (projectId) {
        const data = await this.$store.dispatch("api/getProjectDetails", {
          projectId,
        })
        if (!data || data.errors) {
          this.showErrorMessage(
            data.errors[1],
            "We couldn't find data for this project."
          )
        } else {
          this.project = data.data
          this.company = this.project.company
        }
      }
    },
    handleDeleteProject() {
      console.log("TODO: Delete Project")
    },
    goToCompanyProfile(id) {
      this.$router.push("/profile/" + id + "/provider")
    },
    async handleApplyForProject() {
      this.loading = true
      let projectProfileId = this.project.company._id
      let profileId = this.$store.state.userProfile._id
      let projectId = this.project._id
      await profile.applyForProject(projectProfileId, profileId, projectId)
      /*
      this.$router.push(
        //"/trade-partners/projects/" + this.project._id + "/success"
        "/dashboard"
      )
      */
      this.connected = true
      this.loading = false
    },
  },
  mounted() {
    this.getProject()
  },
}
</script>
<style scoped>
.apply-btn {
  background-color: #b9bdc5;
  color: white;
  width: 190px;
}
.profile-btn {
  width: 190px;
}
.save-btn {
  color: #003049;
  width: 190px;
}
.job-title {
  font-weight: 900;
  font-size: 32px;
}
</style>
