<template>
  <div class="row pt-3">
    <div class="col">
      <ProfileSubSectionHeader
        title="Transportation"
        icon="transportation-icon"
      />
      <checked-list
        v-if="statuses && statuses.length > 0"
        class="mt-3"
        :items="statuses || []"
      />
    </div>
  </div>
</template>

<script>
import ProfileSubSectionHeader from "../ProfileSubSectionHeader.vue"

export default {
  name: "candidate-profile-status",
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statuses() {
      const { jobSeeker } = this.profile
      if (jobSeeker) {
        const { validDriversLicense, validCdlLicense, reliableTransportation } =
          jobSeeker ?? {}
        const statuses = [
          { label: "Valid Driver's License", value: validDriversLicense },
          { label: "Valid CDL License", value: validCdlLicense },
          { label: "Reliable Transportation", value: reliableTransportation },
        ].filter(Boolean)
        return statuses
      }
      return []
    },
  },
  components: { ProfileSubSectionHeader },
}
</script>
