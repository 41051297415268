<template>
  <div id="app" v-cloak>
    <notifications></notifications>
    <router-view />
    <v-snackbar
      class="update-snackbar"
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <DefaultButton :labelText="'Update'" @click="refreshApp" />
    </v-snackbar>
  </div>
</template>
<script>
import { refreshPageMixin } from "@/mixins/refreshPageMixin"
import update from "@/mixins/update"
import DefaultButton from "@/components/DefaultButton"

export default {
  mixins: [refreshPageMixin, update],
  components: { DefaultButton },
  async created() {
    const accessToken = localStorage.getItem("GFB_ACCESS_TOKEN")
    if (accessToken) {
      this.$store.commit("SET_TOKEN", { accessToken })
    }
    const { data } = await this.$store.dispatch("api/getExpirationHours")
    const expiration_hours = data.data
    this.$store.commit("SET_EXPIRATION_HOURS", { expiration_hours })
  },
}
</script>
<style type="scss">
.cursor-pointer {
  cursor: pointer;
}

.update-snackbar {
  .v-snack__wrapper {
    background: var(--dark-bg) !important;
  }

  .v-snack__content {
    justify-content: space-evenly;
    align-items: center;
    display: flex;

    button {
      background: #4779c9;
    }
  }
}
</style>
