<template>
  <p :class="['text-danger', { 'non-input': nonInput }]">
    <slot />
  </p>
</template>

<script>
export default {
  name: "form-error",
  props: {
    nonInput: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  font-size: 80%;
  font-weight: 400;
  margin-top: -0.875rem;
  margin-bottom: 0;
  line-height: 1;

  &.non-input {
    line-height: 1.5;
    margin-top: 0.25rem;

    &.text-danger {
      color: var(--Red-500, #e24646) !important;
      font-size: 14px;
    }
  }
}
</style>
