<template>
  <div class="row" v-if="references.length">
    <div class="col">
      <h2 class="font-22-900 dark-text mb-4">References</h2>
      <div class="row">
        <div
          v-for="(reference, index) in references"
          :key="redacted ? index : reference.email"
          class="col-xs-12 col-sm-6"
        >
          <b-skeleton-wrapper :loading="redacted">
            <template #loading>
              <skeleton-card></skeleton-card>
            </template>
            <base-card>
              <div class="card-body p-2">
                <h5 class="card-title font-16-800 m-0">
                  {{ reference.name }}
                </h5>
                <p class="font-16-500 m-0">{{ reference.relationship }}</p>
                <p class="font-16-500 mute-text m-0">
                  {{ reference.email
                  }}<span v-if="reference.phone"
                    >&nbsp;&#8226;&nbsp;{{ reference.phone }}</span
                  >
                </p>
              </div>
            </base-card>
          </b-skeleton-wrapper>
        </div>
      </div>
      <empty-profile-item-message v-if="isEditing && references.length < 1" />
    </div>
  </div>
</template>

<script>
import EmptyProfileItemMessage from "@/components/Profile/EmptyProfileItemMessage"
import SkeletonCard from "@/components/Cards/SkeletonCard"
import moment from "moment"
export default {
  name: "candidate-profile-references",
  components: {
    EmptyProfileItemMessage,
    SkeletonCard,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      redacted: this.profile.redacted,
      references: this.profile.jobSeeker?.references ?? [],
      lastActivity: moment(this.profile.lastActivity).fromNow(),
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
  .card-body {
    padding: 0;
  }
}
</style>
