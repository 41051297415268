import axios from "@/utils/api-v1"

export default {
  createLog(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/activity/logs/insert`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  getLogs(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/activity/logs`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
}
