import AdminLayout from "@/layout/AdminLayout"

export const adminRouter = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: AdminLayout,
    meta: {
      authRequired: true,
      admin: true,
    },
    children: [
      {
        path: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Admin/Dashboard.vue"
          ),
      },
      {
        path: "companies",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Admin/Companies.vue"
          ),
      },
      {
        path: "jobseekers",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Admin/JobSeekers.vue"
          ),
      },
    ],
  },
]

// propagate metadata recursively to child routes
function propagateMetadata(routes, meta) {
  routes.forEach((route) => {
    if (route.meta === undefined) {
      route.meta = meta
    }
    if (route.children !== undefined) {
      propagateMetadata(route.children, route.meta)
    }
  })
}

propagateMetadata(adminRouter, {})
