<template>
  <div class="experience-slider-container">
    <span v-if="editMode">Beginner</span>
    <v-slider
      class="experience-slider profile-slider"
      :class="{ 'edit-mode': editMode }"
      v-model="expRating"
      :max="10"
      thumb-label="always"
      :ticks="editMode ? 'always' : false"
      tick-size="6"
      :thumb-size="26"
      color="#1cd47e"
      :readonly="editMode ? false : true"
    />
    <span v-if="editMode">Expert</span>
    <div class="rating" v-if="editMode">{{ expRating }}</div>
  </div>
</template>

<script>
export default {
  name: "loading-spinner",
  props: {
    rating: {
      type: Number,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expRating: this.rating ?? [],
      editMode: this.isEditing ?? true,
    }
  },
  watch: {
    expRating(newVal) {
      this.$emit("on-change", newVal)
    },
    isEditing(newVal) {
      this.editMode = newVal
    },
    rating(newVal) {
      this.expRating = newVal
    },
  },
}
</script>

<style lang="scss" scoped>
.experience-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #525e77;
  }

  div.rating {
    display: flex;
    width: 42px;
    height: 32px;
    padding: 5px 16px 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid var(--Gray-500, #677690);
    background: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--Gray-900, #343946);
    text-align: center;
    margin-left: 30px;
  }
}
</style>
