<template>
  <b-form-group
    :label="label"
    :class="[
      'gfb-date-picker',
      classes,
      { 'has-danger': error },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
      { disabled: !!disabled },
    ]"
  >
    <b-form-datepicker
      :value="value"
      v-on="$listeners"
      v-bind="$attrs"
      :state="valid"
      :disabled="disabled"
    ></b-form-datepicker>
    <form-error nonInput v-show="error">{{ error }}</form-error>
  </b-form-group>
</template>
<script>
import { isNullish } from "@/utils/validations"
export default {
  inheritAttrs: false,
  name: "base-date-picker",
  props: {
    error: String,
    classes: {
      type: String,
      description: "String classes to be applied to the root element",
      default() {
        return ""
      },
    },
    label: String,
    value: {
      type: String,
      description: "Input value",
    },
    disabled: Boolean,
  },
  computed: {
    valid() {
      return isNullish(this.error) ? null : !!this.error
    },
  },
}
</script>
<style lang="scss" global>
.form-group.gfb-date-picker .b-form-datepicker {
  border: 2px solid rgba(0, 48, 73, 0.2);
  border-radius: 14px;
  display: flex;
  align-items: center;

  & .btn {
    padding: 0 0.5rem;
    &:hover {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  & label {
    border: none;
    margin-bottom: 0;
    padding: 0;
    line-height: 1.8;
    height: unset;
  }
}
.form-group.gfb-date-picker.disabled .b-form-datepicker {
  background-color: #eee;
}
</style>
